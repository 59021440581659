import { createSlice } from "@reduxjs/toolkit";
import {
  findAllRequests,
  editRequest,
  getRequestByID,
} from "./Requests.Actions";


const RequestsSlice = createSlice({
  name: "requests",
  initialState: {
    requests: null,
    responseData: null,
    error: null,
    success: false,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(findAllRequests.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(findAllRequests.fulfilled, (state, action) => {
        state.Loading = false;
        state.requests = action.payload;
        // socket.on("update_requests", (data) => {
        //   console.log("data", data);
        // });
      })
      .addCase(findAllRequests.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })

      .addCase(editRequest.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(editRequest.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(editRequest.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      })
      .addCase(getRequestByID.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(getRequestByID.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(getRequestByID.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      });
  },
});

export const RequestsAction = RequestsSlice.actions;
export default RequestsSlice;
