import React, { useState, useEffect } from "react";
import BackButton from "../../components/Buttons/backButton";
import PageTitle from "../../components/PageTitle";
import WeekdaySelector from "./WeekdaySelector";
import DateOverrides from "./DateOverrides";
import setTitle from "../../hooks/Title";
const Schedule = () => {
  useEffect(() => {
    setTitle("Schedule");
  }, []);
  return (
    <div id="main-content">
      <BackButton />
      <PageTitle Title="Schedule" />
      <div className="card">
        <div className="body">
          <div className="row " style={{ width: "100%", margin: "0 auto" }}>
            <div className="col-lg-8">
              <h5 className="mb-5">Set your weekly hours</h5>
              <WeekdaySelector />
            </div>
            <div className="col-lg-4  borderVisible">
              <h5 className="mb-5">Add date overrides</h5>

              <DateOverrides />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
