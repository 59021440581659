import { createSlice } from "@reduxjs/toolkit";
import {
  CreateComponent,
  findAllComponents,
  deleteComponentById,
  getComponentsById,
  editComponentsById,
} from "./ComponentsActions";
const ComponentsSlice = createSlice({
  name: "components",
  initialState: {
    components: null,
    responseData: null,
    error: null,
    success: false,
    Loading: false,
    singleLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateComponent.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(CreateComponent.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(CreateComponent.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      })
      .addCase(findAllComponents.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(findAllComponents.fulfilled, (state, action) => {
        state.Loading = false;
        state.components = action.payload;
      })
      .addCase(findAllComponents.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(deleteComponentById.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(deleteComponentById.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(deleteComponentById.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(getComponentsById.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(getComponentsById.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(getComponentsById.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(editComponentsById.pending, (state) => {
        // state.Loading = true;
        state.error = null;
      })
      .addCase(editComponentsById.fulfilled, (state, action) => {
        // state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(editComponentsById.rejected, (state, action) => {
        // state.Loading = false;
        state.error = action.payload;
      });
  },
});

export const ComponentsAction = ComponentsSlice.actions;
export default ComponentsSlice;
