import "./index.scss";
import Logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "../../../redux/Auth/AuthActions";

import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, Button } from "reactstrap";
import setTitle from "../../../hooks/Title";
import { authAction } from "../../../redux/Auth/AuthSlice";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const success = useSelector((state) => state.auth.success);
  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
      password: Yup.string()
        // .matches(
        //   /((?=.\d)(?=.[a-zA-Z])(?=.[!@#$%^&()])(?!.* ).{8,20})/,
        //   "Invalid Password Fromat"
        // )
        .required("Please Enter Password."),
    }),
    onSubmit: (values) => {
      dispatch(signInUser({ email: values.email, password: values.password }));
    },
  });
  setTitle("");
  useEffect(() => {
    if (success) {
      navigate("/");
    }
  }, [success]);
  return (
    <>
      <div className="theme-cyan">
        <div className="hide-border">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body">
                    <div className="form-auth-small" action="index.html">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="form-group">
                          <label className="control-label sr-only">Email</label>
                          <Input
                            label="Username"
                            id="signin-email"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            className="form-control"
                            autoComplete="current-email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <p style={{ color: "red" }}>
                              {validation.errors.email}
                            </p>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label className="control-label sr-only">
                            Password
                          </label>
                          <Input
                            id="signin-password"
                            label="Password"
                            type="password"
                            name="password"
                            className="form-control pe-5"
                            placeholder="Enter your password"
                            autoComplete="current-password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <p style={{ color: "red" }}>
                              {validation.errors.password}
                            </p>
                          ) : null}
                        </div>
                        <Button
                          color="primary"
                          className="w-100 primary-btn-style"
                          type="submit"
                        >
                          Log In
                        </Button>
                      </form>
                      <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <Link to={"/forgetPass"}>Forgot password?</Link>
                        </span>
                        <span>
                          Don't have an account?{" "}
                          <Link to="/register">Register</Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
