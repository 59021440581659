import React, { useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import BackButton from "../../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import { getComponentsById } from "../../../redux/Components/ComponentsActions";
import PagesLoading from "../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";
import TablePagination from "../../../components/Pagination";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PopUp from "../../../components/PopUp";
import ComponentsEdit from "../ComponentsEdits";

const ComponentDetails = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.components.responseData);
  const loading = useSelector((state) => state.components.singleLoading);

  const [currentItems, setCurrentItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  const [code, setCode] = useState("");
  const [productName, setProductName] = useState("");
  const [isEditVisible, setIsEditVisible] = useState(false);

  const [componentData, setComponentData] = useState({});

  const [state, setState] = useState({
    disabled: true,
    firstData: false,
  });

  const [t] = useTranslation();

  let id = useParams();

  useEffect(() => {
    if (responseData) {
      setState({ ...state, firstData: true });
    }
  }, [responseData]);
  useEffect(() => {
    if (state.firstData) {
      setComponentData([responseData?.component]);
    }
  }, [state.firstData]);

  useEffect(() => {
    if (code !== "" || productName !== "") {
      setState({ ...state, disabled: false });
    } else {
      setState({ ...state, disabled: true });
    }
  }, [code, productName]);

  const initFilters = () => {
    setFilters({
      code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      products: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      qty: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);

  const codeBody = (rowData) => {
    return (
      <div className="project-title">
        <h6>{rowData.product.code}</h6>
      </div>
    );
  };

  const productBody = (rowData) => {
    return (
      <div className="product">
        <h6>{rowData.product.name}</h6>
      </div>
    );
  };

  const codeFilterTemplate = () => {
    return (
      <>
        <div>
          <input
            className="form-control"
            placeholder="Code"
            type="text"
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      </>
    );
  };

  const productFilterTemplate = () => {
    return (
      <>
        <div>
          <input
            className="form-control"
            placeholder="Product Name "
            type="text"
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
      </>
    );
  };
  const visibleEditHandel = (componentId) => {
    setIsEditVisible(true);
  };
  const closeEdithandle = () => {
    setIsEditVisible(false);
  };
  const elementAction = (rowData) => {
    return (
      <div className="project-actions">
        <div
          className="btn btn-outline-secondary"
          onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            textAlign: "end",
            opacity: state.disabled ? "0.2" : "0.6",
          }}
          disabled={state.disabled}
          onClick={() => {
            dispatch(
              getComponentsById({
                id: id.id,
                token: token,
                currentPage,
                itemsPerPage,
              })
            );
            setCode("");
            setProductName("");

            setState({ ...state, disabled: true });
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();

  useEffect(() => {
    dispatch(
      getComponentsById({
        id: id.id,
        token: token,
        code,
        productName,
        currentPage,
        itemsPerPage,
      })
    );
  }, [token, code, productName, currentPage, itemsPerPage]);

  const navigate = useNavigate();
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const path = window.location.pathname;
  const end = path.substring(path.lastIndexOf("/") + 1);

  useEffect(() => {
    if (id.id !== end) {
      navigate("/*");
    }
  }, [end]);

  let content;

  if (responseData?.component?.productComponent?.length !== 0) {
    content = (
      <>
        <div className="table-list mb-3">
          <div>
            <DataTable
              value={componentData}
              loading={!state.firstData ? loading : false}
              loadingIcon={!state.firstData ? <PagesLoading /> : null}
              dataKey="id"
              filters={filters}
              emptyMessage={t("noDataFound")}
            >
              <Column
                header={t("code")}
                field="code"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("description")}
                field="name"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("packaging-type")}
                field="packageType"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("qty-per-packaging")}
                field="quantityPerPackage"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("actions")}
                field="code"
                style={{ minWidth: "12rem" }}
                body={elementAction}
              />
            </DataTable>
          </div>
        </div>

        <div className="table-list">
          <div className="table-responsive">
            <DataTable
              value={dataInfo}
              loading={loading}
              loadingIcon={<PagesLoading />}
              dataKey="id"
              filters={filters}
              header={headers}
              emptyMessage={t("noDataFound")}
            >
              <Column
                header={t("code")}
                field="code"
                filter
                filterElement={codeFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={codeBody}
              />
              <Column
                header={t("products")}
                field="products"
                filter
                filterElement={productFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
                body={productBody}
              />
              <Column
                header={t("qty-per-product")}
                field="componentQuantity"
                style={{ minWidth: "9rem" }}
              />
            </DataTable>
          </div>
        </div>

        <TablePagination
          data={responseData?.component?.productComponent}
          filterData={filteredData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          responseData={responseData}
        />
      </>
    );
  }

  return (
    <div id="main-content">
      <PopUp
        text={
          <ComponentsEdit
            closePopup={closeEdithandle}
            id={componentData.id}
            exist={true}
          />
        }
        isVisible={isEditVisible}
      />
      <BackButton />
      <div className="flex-header">
        <PageTitle Title={componentData[0]?.name} />
        <div className="btn-style"></div>
      </div>

      {content}
    </div>
  );
};

export default ComponentDetails;
