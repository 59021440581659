import React, { useState } from "react";
import { Oval } from "react-loader-spinner"; // Import a specific loader component
import s3 from "../../utils/aws/aws";

const UploadImageToS3 = ({ onImageUpload, handleOnChange, renderButton }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    setFile(selectedFile);
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: selectedFile.name,
      Body: selectedFile,
    };

    const upload = s3.upload(params);

    upload.on("httpUploadProgress", (event) => {
      const percentCompleted = Math.round((event.loaded / event.total) * 100);
      setProgress(percentCompleted);
    });

    try {
      const data = await upload.promise();
      console.log("Image uploaded successfully:", data.Key);
      setFile(null);
      setProgress(0);

      if (onImageUpload) {
        onImageUpload(data.Key, data.Location);
      }

      if (handleOnChange) {
        handleOnChange(data.Key, data.Location, selectedFile);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div>
      {file ? (
        <div>
          <Oval color="#00BFFF" height={30} width={30} />
          <p>Uploading...</p>
        </div>
      ) : (
        renderButton(handleFileChange)
      )}
    </div>
  );
};

export default UploadImageToS3;
