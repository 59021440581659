import React, { useState, useEffect } from "react";
import PageTitle from "../../../../components/PageTitle";
import { MultiSelect } from "primereact/multiselect";
import { useSelector, useDispatch } from "react-redux";
import { findAllLines } from "../../../../redux/ProductionLines/ProductionLinesActions";
import { findAllComponents } from "../../../../redux/Components/ComponentsActions";
import { CreateProduct } from "../../../../redux/Products/ProductsActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import PagesLoading from "../../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { Image } from "primereact/image";

const DefectImage = ({ closePopup, img }) => {
  return (
    <div className="image-popup-container" onClick={closePopup}>
      <div className="image-popup-content">
        <div className="popup-close">&times;</div>
        <div className="">
          <div className="flex justify-content-center">
            <Image src={img} alt="Image" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefectImage;
