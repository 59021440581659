import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PreparationPrivate = () => {
  const role = useSelector((state) => state.auth.data?.role);

  return (
    <>{role === "preparation_operator" ? <Outlet /> : <Navigate to="/" />}</>
  );
};

export default PreparationPrivate;
