import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../endPoints";
import axiosInstance from "../../services/axiosInstance";

export const signInUser = createAsyncThunk(
  "auth/signIn",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axiosInstance.post(
        Api.signIn,
        { email, password },
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ uuid, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axiosInstance.post(
        Api.verifyEmail,
        { uuid, password },
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const ChangePasswordSignIn = createAsyncThunk(
  "auth/changePasswordSignIn",
  async ({ oldPassword, newPassword, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.ChangePasswordSignIn,
        { oldPassword, newPassword },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({ displayName, profilePicture, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.patch(
        Api.updateUser,
        { displayName, profilePicture },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateUserphoto = createAsyncThunk(
  "auth/updateUser",
  async ({ profilePicture, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.patch(
        Api.updateUser,
        { profilePicture },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const AdminUpdateUser = createAsyncThunk(
  "auth/adminupdateUser",
  async ({ id, displayName, role, hrCode, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const requestBody = {};

      if (displayName !== undefined) {
        requestBody.displayName = displayName;
      }
      if (role !== undefined) {
        requestBody.role = role;
      }
      if (hrCode !== undefined) {
        requestBody.hrCode = hrCode;
      }
      const response = await axiosInstance.patch(
        `${Api.AdminUpdateUser}/${id}`,
        requestBody,
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const inviteUser = createAsyncThunk(
  "auth/inviteUser",
  async ({ hrCode, email, displayName, role, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.clientAdminCreateUser,
        { hrCode, email, displayName, role },
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findAllUsers = createAsyncThunk(
  "auth/findAllUsers",
  async (
    { page, limit, hrCode, displayName, role, email, verified, token },
    { rejectWithValue }
  ) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (displayName) params.displayName = displayName;
      if (hrCode) params.hrCode = hrCode;
      if (role) params.role = role;
      if (email) params.email = email;
      if (verified) params.verified = verified;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(Api.findAllUsers, config);

      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.get(
        `${Api.getUserById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/deleteUser",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.delete(
        `${Api.deleteUser}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
