import React, { useState } from "react";
import "./index.scss";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NutificationDropdown = () => {
  const [toggleNotification, SetToggleNotification] = useState(false);
  const [t] = useTranslation();

  let onPressNotification = () => {
    SetToggleNotification(!toggleNotification);
  };
  return (
    <>
      <div
        className="dropd"
        data-tooltip-id="my-tooltip"
        data-tooltip-content={t("notifications")}
      >
        <div className={toggleNotification ? "show dropdown" : "dropdown"}>
          <Link
            href="#!"
            className="dropdown-toggle icon-menu"
            data-toggle="dropdown"
            onClick={(e) => {
              e.preventDefault();
              onPressNotification();
            }}
          >
            <i className="icon-bell"></i>
            <span className="notification-dot"></span>
          </Link>
          <ul
            className={
              toggleNotification
                ? "dropdown-menu notifications show"
                : "dropdown-menu notifications"
            }
          >
            <li className="header">
              <strong>You have 4 new Notifications</strong>
            </li>
            <li>
              <Link>
                <div className="media">
                  <div className="media-left">
                    <i className="icon-info text-warning"></i>
                  </div>
                  <div className="media-body">
                    <p className="text">
                      Campaign <strong>Holiday Sale</strong> is nearly reach
                      budget limit.
                    </p>
                    <span className="timestamp">10:00 AM Today</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link>
                <div className="media">
                  <div className="media-left">
                    <i className="icon-like text-success"></i>
                  </div>
                  <div className="media-body">
                    <p className="text">
                      Your New Campaign <strong>Holiday Sale</strong> is
                      approved.
                    </p>
                    <span className="timestamp">11:30 AM Today</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link>
                <div className="media">
                  <div className="media-left">
                    <i className="icon-pie-chart text-info"></i>
                  </div>
                  <div className="media-body">
                    <p className="text">
                      Website visits from Twitter is 27% higher than last week.
                    </p>
                    <span className="timestamp">04:00 PM Today</span>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link>
                <div className="media">
                  <div className="media-left">
                    <i className="icon-info text-danger"></i>
                  </div>
                  <div className="media-body">
                    <p className="text">
                      Error on website analytics configurations
                    </p>
                    <span className="timestamp">Yesterday</span>
                  </div>
                </div>
              </Link>
            </li>
            <li className="footer">
              <Link className="more" to="/notification-list">
                See all notifications
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </>
  );
};

export default NutificationDropdown;
