import React from "react";
import { Link } from "react-router-dom";

const AccountItem = () => {
  const data = [
    { date: "6 Octuber", labels: ["Tms", "Qms", "MPM"] },
    { date: "Badr", labels: ["Tms", "Qms"] },
    { date: "Fifth sattlment ", labels: ["Tms"] },
  ];

  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          className="card d-flex flex-row justify-content-between align-items-center p-3 Payment-card-style"
        >
          <div style={{ minWidth: "200px" }}>
            <span>{item.date}</span>
          </div>
          <div>
            <ul
              className="d-flex align-items-centeer m-0 "
              style={{ listStyle: "none", gap: "10px" }}
            >
              {item.labels.map((label, labelIndex) => (
                <li
                  key={labelIndex}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#f5f2f2",
                    padding: "4px 20px",
                  }}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {" "}
            <div>
              {/* <Link
                to={`/superAdmin/clientDetails`}
                className="btn btn-outline-secondary mr-1"
              >
                <i className="icon-eye"></i>
              </Link> */}
              <div className="btn btn-outline-secondary">
                <i className="icon-pencil"></i>
              </div>
              <div className="btn btn-outline-danger danger ">
                <i className="icon-trash"></i>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AccountItem;
