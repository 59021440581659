import React from "react";
import PageTitle from "../../../components/PageTitle";
import SparkleCard from "../../Dashboard/Charts/SparkleCard";
import ZoomableBarChart from "../../Dashboard/Charts/ZoomableBarChart/ZoomableBarChart";

// 7- current Subscription amount per month
// 7- Accounts per modules (chart)

const AdminDashboard = () => {
  const config = {
    type: "line",
    data: {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "My First Dataset",
          data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: "x",
            speed: 100,
          },
          pan: {
            enabled: true,
            mode: "x",
            speed: 0.5,
          },
        },
      },
    },
  };

  const accountPerModule = {
    type: "bar",
    data: {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "TMS",
          data: [65, 59, 80, 81, 56, 55, 40, 33, 43, 77],
          fill: false,
          borderColor: "rgba(255, 216,100, 1)",
          backgroundColor: "rgba(255, 216,100, 1)",
          tension: 0.1,
          stack: "stack1",
        },
        {
          label: "QMS",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "rgba(66, 178,92, 1)",
          backgroundColor: "rgba(66, 178,92, 1)",
          tension: 0.1,
          stack: "stack1",
        },
        {
          label: "NPM",
          data: [65, 59, 80, 81, 56, 55, 40, 44, 55, 22, 4],
          fill: false,
          borderColor: "rgba(110, 196,210, 1)",
          backgroundColor: "rgba(110, 196,210, 1)",
          tension: 0.1,
          stack: "stack1",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: "x",
            speed: 100,
          },
          pan: {
            enabled: true,
            mode: "x",
            speed: 0.5,
          },
        },
      },
    },
  };

  // };

  const sparkleCardData = [
    {
      heading: "Number of Clients",
      money: "300",
      sparklineData: {
        type: "line",
        data: [1, 4, 1, 3, 7, 1],
        areaStyle: {
          color: "rgba(160, 160,160, 1)",
        },
        symbolSize: 1,
      },
    },
    {
      heading: "Number of Users",
      money: "2222",
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "rgba(95, 172,255, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Number of Accounts",
      money: "1333",
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 1, 5],
        areaStyle: {
          color: "rgba(110, 196,210, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Number of Subscription",
      money: "100",
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(255, 216,100, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Number of Suspended accounts",
      money: "12",
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(66, 178,92, 1)",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Total Amount ",
      money: "$ 1000000",
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "rgba(231, 114,125, 1)",
        },

        symbolSize: 1,
      },
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <PageTitle Title="Dashboard" />
      </div>
      <div className="row clearfix">
        {sparkleCardData.map((data, i) => (
          <SparkleCard
            index={i}
            key={data.heading}
            Heading={data.heading}
            Money={data.money}
            isRandomUpdate={true}
            mainData={data.sparklineData.data}
            chartColor={data.sparklineData.areaStyle.color}
            ContainerClass="col-lg-4 col-md-6 col-sm-6"
          />
        ))}
      </div>

      <div className="row">
        <div className="col-12 col-lg-6">
          <ZoomableBarChart chartConfig={accountPerModule} />
        </div>
        <div className="col-12 col-lg-6 ">
          <ZoomableBarChart chartConfig={config} />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
