import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findAllRequests } from "../../redux/Requests/Requests.Actions";
import Kanban from "./kanban";
import PagesLoading from "../../components/Loading/pagesLoading";
import socket from "../../utils/webSoket/webSocket";
const Data = () => {
  const dispatch = useDispatch();

  let token = useSelector((state) => state.auth.data?.accessToken);
  let role = useSelector((state) => state.auth.data?.role);
  let responseData = useSelector((state) => state?.requests?.requests);
  const Loading = useSelector((state) => state.requests?.Loading);
  const [tasks, setTasks] = useState([]);
  const [getRequest, setGetRequest] = useState(false);

  const getTasks = () => {
    setGetRequest(!getRequest);
  };

  socket.on("created_requests", (data) => {
    const updatedCurrentItems = [data, ...tasks];
    setTasks(updatedCurrentItems);
  });

  socket.on("update_requests", (data) => {
    const isValueInArray = tasks.some((task) => task.id === data.id);
    if (isValueInArray === false) {
      const updatedCurrentItems = [data, ...tasks];
      setTasks(updatedCurrentItems);
      console.log("test", tasks);
    } else {
      if (role === "preparation_operator") {
        if (
          data.status === "planned" ||
          data.status === "in_preparation" ||
          data.status === "ready"
        ) {
          setTasks(
            tasks.map((obj) => {
              if (obj.id === data.id && obj.status !== data.status) {
                return { ...obj, status: data.status };
              }
              return obj;
            })
          );
        } else {
          const filtered = tasks.filter((item) => item.id !== data.id);
          setTasks(filtered);
        }
      } else if (role === "driver") {
        if (
          data.status === "ready" ||
          data.status === "on_train" ||
          data.status === "delivered"
        ) {
          setTasks(
            tasks.map((obj) => {
              if (obj.id === data.id && obj.status !== data.status) {
                return { ...obj, status: data.status };
              }
              return obj;
            })
          );
        } else {
          const filtered = tasks.filter((item) => item.id !== data.id);
          setTasks(filtered);
        }
      } else {
        setTasks(
          tasks.map((obj) => {
            if (obj.id === data.id && obj.status !== data.status) {
              return { ...obj, status: data.status };
            }
            return obj;
          })
        );
      }
    }
  });

  useEffect(() => {
    dispatch(findAllRequests({ token: token }));
  }, [dispatch, token, getRequest]);

  useEffect(() => {
    if (responseData) {
      setTasks(responseData.requests);
    }
  }, [responseData]);
  return (
    <>
      {Loading ? (
        <PagesLoading />
      ) : (
        <Kanban tasksList={tasks} getTasks={getTasks} role={role} />
      )}
    </>
  );
};

export default Data;
