import "./index.scss";
import Logo from "../../../assets/images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../../redux/Auth/AuthActions";
const VerifyEmailRegistration = () => {
  const dispatch = useDispatch();

  const [passwords, setPasswords] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [confPassErr, setConfPassErr] = useState(false);
  const [formErr, setFormErr] = useState(false);

  const navigate = useNavigate();
  let uuid = useParams()?.uuid;
  console.log(uuid);
  let handleSubmit = (e) => {
    e.preventDefault();

    if (passwords === "" || confirmPass === "") {
      setFormErr(true);
    } else {
      setFormErr(false);
      navigate("/login");
      dispatch(verifyEmail({ uuid, password: confirmPass }));
    }
  };
  const passHandler = (e) => {
    let pass = e.target.value;
    if (pass === "" || pass.length < 8) {
      setPassErr(true);
    } else {
      setPassErr(false);

      setPasswords(pass);
    }
  };

  const confirmPassHandler = (e) => {
    let confirmPassword = e.target.value;
    if (
      confirmPassword === "" ||
      confirmPassword.length < 8 ||
      confirmPassword !== passwords
    ) {
      setConfPassErr(true);
    } else {
      setConfPassErr(false);
      setConfirmPass(confirmPassword);
    }
  };

  return (
    <>
      <div className="theme-cyan">
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Create a new password</p>
                  </div>
                  <div className="body">
                    <form
                      className="form-auth-small ng-untouched ng-pristine ng-valid"
                      onSubmit={handleSubmit}
                    >
                      {formErr && (
                        <p className="validateErr">please fill all fields </p>
                      )}

                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signup-password"
                          placeholder="Password"
                          type="password"
                          onBlur={passHandler}
                        />
                        {passErr && (
                          <p className="validateErr">
                            require password must be more than 8 character
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signup-password"
                          placeholder="confirm Password"
                          type="password"
                          onBlur={confirmPassHandler}
                        />
                        {confPassErr && (
                          <p className="validateErr">
                            require the confirm password and password must be
                            the same
                          </p>
                        )}
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        type="submit"
                      >
                        Confirm
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailRegistration;
