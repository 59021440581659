import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
function Page500() {
  return (
    <div className="theme-cyan">
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img
                  src={Logo}
                  alt="Lucid"
                  style={{ height: "40px", margin: "10px" }}
                />
              </div>
              <div className="card">
                <div className="header">
                  <h3>
                    <span className="clearfix title">
                      <span className="number left">500</span>
                      <span className="text">
                        <br />
                        Internal Server Error
                      </span>
                    </span>
                  </h3>
                </div>
                <div className="body">
                  <p>
                    Apparently we're experiencing an error. But don't worry, we
                    will solve it shortly. Please try after some time.
                  </p>
                  <div className="margin-top-30">
                    <Link className="btn btn-primary" to="/">
                      <i className="fa fa-home"></i>&nbsp;<span>Home</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page500;
