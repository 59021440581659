import { createSlice } from "@reduxjs/toolkit";
import {
  CreateLine,
  findAllLines,
  deleteLineById,
  getLineById,
  editLine,
} from "./ProductionLinesActions";
const ProductionLinesSlice = createSlice({
  name: "productionLines",
  initialState: {
    responseData: null,
    lines:null,
    error: null,
    success: false,
    Loading: false,
    singleLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateLine.pending, (state) => {
        // state.singleLoading = true;
        state.error = null;
      })
      .addCase(CreateLine.fulfilled, (state, action) => {
        // state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(CreateLine.rejected, (state, action) => {
        // state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(findAllLines.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(findAllLines.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.lines = action.payload;
      })
      .addCase(findAllLines.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(getLineById.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(getLineById.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(getLineById.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLineById.pending, (state) => {
        // state.singleLoading = true;
        state.error = null;
      })
      .addCase(deleteLineById.fulfilled, (state, action) => {
        // state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(deleteLineById.rejected, (state, action) => {
        // state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(editLine.pending, (state) => {
        // state.singleLoading = true;
        state.error = null;
      })
      .addCase(editLine.fulfilled, (state, action) => {
        // state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(editLine.rejected, (state, action) => {
        // state.singleLoading = false;
        state.error = action.payload;
      });
  },
});

export const ProductionLinesAction = ProductionLinesSlice.actions;
export default ProductionLinesSlice;
