import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: "20px",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Previews({ saveImg }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    disabled: files.length !== 0 ? true : false,
  });

  const deletePhoto = () => {
    setFiles([]);
  };
  const thumbs = files.map((file) => {
    saveImg(file);

    return (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
        <i
          className="icon-trash"
          style={{ color: "red" }}
          onClick={deletePhoto}
        ></i>
      </div>
    );
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container upload-photo p-0">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          borderWidth: 2, 
          borderRadius: 2,
          borderColor: "#49c5b6",
          borderStyle: "dashed",
          backgroundColor: "#fafafa",
          color: "#bdbdbd",
          outline: "none",
          height: "100%",
          transition: "border .24s ease-in-out",
          opacity: files.length !== 0 ? 1 : 1,
        }}
      >
        <input {...getInputProps()} />
        {/* {files.length !== 0 ?():""} */}
        <p
          className="d-flex align-items-center  m-0"
          style={{ height: "100%" }}
        >
          Drag & drop picture here, or click to select picture
        </p>
        <div style={thumbsContainer}>{thumbs}</div>
      </div>
    </section>
  );
}

export default Previews;
