import React, { useState, useEffect } from "react";
import { Paginator } from "primereact/paginator";
import "./index.scss";

const RegularTablePagination = ({ data, filterData }) => {
  const [first, setFirst] = useState(0);
  const [itemsPerPage] = useState(14);
  const [currentItems, setCurrentItems] = useState([]);
  //set data to state 1
  useEffect(() => {
    if (data) {
      const felteredData = data.slice(first, first + itemsPerPage);
      setCurrentItems(felteredData);
    }
  }, [first]);

  const onPageChange = (event) => {
    setFirst(event.first);
  };

  useEffect(() => {
    if (currentItems?.length !== 0) {
      filterData(currentItems);
    }
  }, [currentItems]);

  return (
    <>
      <Paginator
        className="d-flex flex-nowrap justify-content-center align-items-start  small-screen"
        first={first}
        rows={itemsPerPage}
        totalRecords={data?.length}
        onPageChange={onPageChange}
        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      />
    </>
  );
};

export default RegularTablePagination;
