import React from "react";

const DayCheckbox = ({ day, checked, onChange }) => {
  return (
    <label
      className="d-flex "
      style={{ width: "fit-content", cursor: "pointer" }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="mr-2"
        style={{ cursor: "pointer" }}
      />
      <strong style={{ fontSize: "12px" }}>{day}</strong>
    </label>
  );
};

export default DayCheckbox;
