import React from "react";
import useBackButton from "../../hooks/useBackButton";
import { useTranslation } from "react-i18next";

const BackButton = () => {
  const { goBack } = useBackButton();
  const [t] = useTranslation();

  return (
    <div
      className="d-flex align-items-center mb-2"
      style={{
        gap: "10px",
        fontSize: "12px",
        opacity: "0.8",
        cursor: "pointer",
        width: "fit-content",
      }}
      onClick={goBack}
    >
      {" "}
      <i className="fa fa-chevron-left"></i>{" "}
      <h6 style={{ fontSize: "12px" }} className="m-0">
        {t("goBack")}
      </h6>{" "}
    </div>
  );
};

export default BackButton;
