import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";

import AuthSlice from "./Auth/AuthSlice";
import ProductionLinesSlice from "./ProductionLines/ProductionLinesSlice";
import ComponentsSlice from "./Components/ComponentsSlice";
import ProductsSlice from "./Products/ProductsSlice";
import OrdersSlice from "./Orders/OrdersSlice";
import RequestsSlice from "./Requests/RequestsSlice";
import AccountSlice from "./Account/AccountSlice";
import DashboardSlice from "./Dashboard/DashboardSlice";
import LayoutSlice from "./Layout/layoutSlice";
axios.interceptors.request.use((request) => {
  return request;
});
const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    productionLines: ProductionLinesSlice.reducer,
    components: ComponentsSlice.reducer,
    products: ProductsSlice.reducer,
    orders: OrdersSlice.reducer,
    requests: RequestsSlice.reducer,
    account: AccountSlice.reducer,
    dashboard: DashboardSlice.reducer,
    layout: LayoutSlice.reducer,
  },
});
export default store;
