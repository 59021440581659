import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button } from "primereact/button";
Chart.register(zoomPlugin);

const randomInt = () => Math.floor(Math.random() * (10 - 1 + 1)) + 1;

const ZoomableBarChart = ({ chartConfig }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
      return () => {
        newChartInstance.destroy();
      };
    }
  }, [chartContainer]);

  const updateDataset = (datasetIndex, newData) => {
    chartInstance.data.datasets[datasetIndex].data = newData;
    chartInstance.update();
  };

  const resetZoom = () => {
    // Check if the chart instance exists and the zoom plugin is enabled
    if (chartInstance && chartInstance.options.plugins.zoom) {
      chartInstance.resetZoom();
    }
  };

  return (
    <div>
      <div className="card" style={{ height: "500px",width: "100%", padding: "15px" }}>
        <div
          style={{ display: "flex" }}
          className="justify-content-between py-3"
        >
          <Button
            label="Reset"
            severity="warning"
            size="small"
            onClick={resetZoom}
          />
          <span className="p-buttonset">
            <Button label="D" size="small" icon="pi pi-check" />
            <Button label="W" size="small" icon="pi pi-trash" />
            <Button label="M" size="small" icon="pi pi-times" />
            <Button label="Y" size="small" icon="pi pi-times" />
          </span>
        </div>
        <div style={{ height: "80%", width: "100%% !important" }}>
          <canvas ref={chartContainer} />
        </div>
      </div>
    </div>
  );
};
export default ZoomableBarChart;
