import "./index.scss";
import UserAccount from "./userAccount";
import { NavLink, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { layoutAction } from "../../redux/Layout/layoutSlice";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation()?.pathname;
  console.log(location);
  const [isClassActive, setIsClassActive] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");

  const [t] = useTranslation();

  const role = useSelector((state) => state.auth.data?.role);
  const accordionName = useSelector((state) => state.layout.accordion);
  console.log("accordionName", accordionName);
  useEffect(() => {
    if (
      location.includes("requestList") ||
      location.includes("production-plan")
    ) {
      dispatch(layoutAction.sideBarAccordion("tms"));
    } else if (
      location.includes("category") ||
      location.includes("defects") ||
      location.includes("forms")
    ) {
      dispatch(layoutAction.sideBarAccordion("qms"));
    } else if (
      location.includes("products") ||
      location.includes("components") ||
      location.includes("productionstations") ||
      location.includes("schedule") ||
      location.includes("calendar") ||
      location.includes("train-setup")
    ) {
      dispatch(layoutAction.sideBarAccordion("setup"));
    } else if (location.includes("users")) {
      dispatch(layoutAction.sideBarAccordion("account"));
    } else {
      dispatch(layoutAction.sideBarAccordion(false));
    }
  }, [location]);

  const myClass = isClassActive ? "showSidebar " : "";
  let toggleNav = () => {
    setIsClassActive(!isClassActive);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    dispatch(layoutAction.sideBarAccordion(panel));
  };

  useEffect(() => {
    setExpanded(accordionName);
  }, [accordionName]);

  const sidebarData = [
    {
      key: "tms",
      icon: "icon-directions",
      text: "TMS",
      routes: [
        { to: "/admin/", text: t("dashboard") },
        { to: "/admin/requestList", text: t("deliveries") },
        { to: "/admin/production-plan", text: t("productionPlan") },
      ],
    },
    {
      key: "qms",
      icon: "icon-check",
      text: "QMS",
      routes: [
        { to: "/admin/", text: t("dashboard") },
        { to: "/admin/qms/category", text: t("Category") },
        { to: "/admin/qms/defects", text: t("occurrences") },
        { to: "/admin/qms/forms", text: t("forms") },
      ],
    },
    {
      key: "mpm",
      icon: "fa fa-dropbox",
      text: "MPM",
      routes: [{ to: "/admin/MPM", text: t("dashboard") }],
    },
    {
      key: "setup",
      icon: "icon-settings",
      text: t("setup"),
      routes: [
        { to: "/admin/products", text: t("products") },
        { to: "/admin/components", text: t("components") },
        { to: "/admin/productionstations", text: t("productionStations") },
        { to: "/admin/defects", text: t("defects") },
        { to: "/admin/schedule", text: t("schedule") },
        { to: "/admin/calendar", text: t("calendar") },
        { to: "/admin/train-setup", text: t("tmsSetup") },
      ],
    },
    {
      key: "account",
      icon: "icon-user",
      text: t("account"),
      routes: [
        { to: "/admin/users", text: t("manage") },
        { to: "/admin/users", text: t("users") },
      ],
    },
    {
      key: "superAdmin",
      icon: "fa fa-refresh",
      text: t("Super Admin"),
      routes: [
        { to: "/superAdmin/clients", text: t("Clients") },
        { to: "/superAdmin/users", text: t("Users") },
      ],
    },
  ];

  return (
    <>
      <div className="container-fluid d-flex justify-content-between">
        <div className="navbar-btn toglleNav">
          <button className="btn-toggle-offcanvas" onClick={toggleNav}>
            <i className="lnr lnr-menu fa fa-bars"></i>
          </button>
        </div>
      </div>
      <div
        id="left-sidebar"
        className={`sidebar ${myClass}`}
        style={{ zIndex: 9 }}
      >
        <UserAccount />

        {role === "client_admin" || role === "super_admin" ? (
          <>
            {sidebarData.map((item) => (
              <React.Fragment key={item.key}>
                {item.key === "superAdmin" && role !== "super_admin" ? null : ( // Skip rendering "Super Admin" for roles other than "super_admin"
                  <Accordion
                    expanded={expanded === item.key}
                    onChange={handleChange(item.key)}
                    className="m-0"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${item.key}-content`}
                      id={`panel-${item.key}-header`}
                    >
                      <i className={item.icon}></i>
                      <Typography
                        style={{ fontSize: "12px", padding: "0 10px" }}
                      >
                        {item.text}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div">
                        <ul>
                          {item.routes.map((route, index) => (
                            <NavLink
                              key={index}
                              to={route.to}
                              onClick={() => setIsClassActive(false)}
                            >
                              <li className="list">{route.text}</li>
                            </NavLink>
                          ))}
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </React.Fragment>
            ))}
            {/* Additional logic remains unchanged */}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Sidebar;
