import React, { useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { useState } from "react";
import DetailsRequest from "./details";
import { useParams, useNavigate ,Link} from "react-router-dom";

const DriverDetails = () => {
  let id = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(false);
  const data = [
    {
      requestCode: "1122334",
      status: "Delivered",
      statusHistory: [
        { status: "planned", Date: "10/3/2024", Time: "12:15 PM" },
        { status: "Ready", Date: "14/3/2024", Time: "1:30 PM" },
      ],
      createdAt: "15/3/2024",
      Time: "11:30 AM",
      line: "5",
      team: ["ahmed", "john", "ali"],
      details: [
        {
          code: "500003715",
          description: "خارجيه بيريمير12بارد بلازما",
          packageType: "box",
          quantity: "12",
          warehouse: "Cairo",
          superMarcket: "alex",
          coush: "alex",
          Delivery: "tanta",
          Delivered:true,
        },
      ],
    },
    {
      requestCode: "11228884",
      status: "Planned",
      statusHistory: [
        { status: "planned", Date: "10/3/2024", Time: "12:15 PM" },
        { status: "Ready", Date: "14/3/2024", Time: "1:30 PM" },
      ],
      createdAt: "15/3/2024",
      line: "5",
      Time: "11:30 AM",
      team: ["ahmed", "john", "ali"],
      details: [
        {
          code: "500003715",
          description: "خارجيه بيريمير12بارد بلازما",
          packageType: "box",
          quantity: "12",
          warehouse: "Cairo",
          superMarcket: "alex",
          coush: "alex",
          Delivery: "tanta",
          Delivered:false,
        },
      ],
    },
  ];
  const header = [
    "#",
    "Request Code",
    "Description",
    "package Type",
    "quantity",
    "Stations",
    "Delivered",
  ];

 

  let specifecData = data.filter((item) => item.requestCode === id.id);

  useEffect(() => {
    if (specifecData.length === 0) {
      navigate("/*");
    }
  }, [specifecData]);
  let itemData = specifecData[0];

  if (specifecData.length !== 0) {
    return (
      <>
        <div id="main-content">
          <PageTitle Title="Driver Details" />

          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="header">
                  <h2>Single Request</h2>
                </div>
                <div className="body">
                  <h3>
                    Driver Details :{" "}
                    <strong className="text-primary">
                      {itemData.requestCode}
                    </strong>
                  </h3>
                  <ul className="nav nav-tabs-new2">
                    <li className="nav-item inlineblock">
                      <Link
                        className={`nav-link ${!tab && "active"}`}
                        fragment="details"
                        onClick={() => {
                          setTab(false);
                        }}
                      >
                        Details
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={`tab-pane ${!tab && "active"}`}>
                      <DetailsRequest
                        createdAt={itemData.createdAt}
                        header={header}
                        data={itemData.details}
                        status={itemData.status}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return false;
  }
};

export default DriverDetails;
