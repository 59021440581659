import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import setTitle from "../../hooks/Title";
import PageTitle from "../../components/PageTitle";
import "./index.scss";
import PopUp from "../../components/PopUp";
import Adduser from "./AddUser";
import BackButton from "../../components/Buttons/backButton";
import DeleteUser from "./deleteUser";
import { findAllUsers } from "../../redux/Auth/AuthActions";
import { useSelector, useDispatch } from "react-redux";
import TablePagination from "../../components/Pagination";
import PagesLoading from "../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";
import { badgeUsersStatus } from "../../hooks/generic";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const Users = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.auth?.users);
  const Loading = useSelector((state) => state.auth?.singleLoading);

  const [isVisible, setIsVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  // Filter State
  const [hrCode, setCode] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState("");
  const [role, setRole] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);

  

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      hrCode: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      displayName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      role: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      verified: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const elementAction = (rowData) => {
    return (
      <div className="project-actions">
        <Link
          to={`/admin/profile-users/${rowData.id}`}
          className="btn btn-outline-secondary mr-1"
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-danger danger"
          onClick={() => handleDeleteUser(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </div>
    );
  };

  const picture = (rowData) => {
    return (
      <ul className="list-unstyled team-info">
        <li>
          <img
            alt="Avatar"
            src="https://media.istockphoto.com/id/1143031708/photo/its-the-face-of-corporate-success.jpg?s=612x612&w=0&k=20&c=9jKMUVGpWBJpQYC0wevIKpC-dZ3zp3jtbebtfSZ5gwk="
          />
        </li>
      </ul>
    );
  };

  const hrCodeUsers = (rowData) => {
    return rowData.hrCode !== "" ? rowData.hrCode : "--";
  };

  const codeFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder=" HR Code "
          type="number"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </div>
    );
  };
  const nameFilterTemplate = () => {
    return (
      <div className="">
        <input
          className="form-control"
          placeholder="Display Name"
          type="text"
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
        />
      </div>
    );
  };

  const roleFilterTemplate = () => {
    return (
      <div>
        <select
          name="Role"
          className="form-control"
          onChange={(e) => setRole(e.target.value)}
        >
          {/* <option disabled value="Role">
            Role
          </option> */}
          <option value="client_admin">client_admin</option>
          <option value="preparation_operator">preparation_operator</option>
          <option value="delivery_station">delivery_station</option>
          <option value="driver">driver</option>
        </select>
      </div>
    );
  };

  const emailFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
    );
  };
  const StatusFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder="Status"
          type="text"
          onChange={(e) => {
            setVerified(e.target.value);
          }}
        />
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          // icon="pi pi-filter-slash"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            opacity: "0.6",
          }}
          onClick={() => {
            setRole("");
            dispatch(
              findAllUsers({
                token: token,
                page: currentPage,
                limit: itemsPerPage,
              })
            );
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();

  const visibleHandel = () => {
    setIsVisible(true);
  };

  const closehandle = () => {
    setIsVisible(false);
  };

  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
  };

  const handleDeleteUser = (userId) => {
    setUserId(userId);
    setIsDeleteVisible(true);
  };

  useEffect(() => {
    setTitle("Users List");
    dispatch(
      findAllUsers({
        token: token,
        page: currentPage,
        limit: itemsPerPage,
        hrCode,
        displayName,
        email,
        verified,
        role,
      })
    );
  }, [
    token,
    currentPage,
    itemsPerPage,
    hrCode,
    displayName,
    email,
    verified,
    role,
  ]);

  useEffect(() => {
    if (responseData) {
      setData(responseData.users);
      setCurrentItems(responseData.users);
    }
  }, [responseData]);

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const statusColumn = (rowData) => {
    return badgeUsersStatus({ verified: rowData.verified, t });
  };

  return (
    <>
      <PopUp
        text={<DeleteUser closePopup={closeDeletehandle} id={userId} />}
        isVisible={isDeleteVisible}
      />
      <div id="main-content">
        <BackButton />
        <div className="flex-header">
          <PageTitle Title={t("usersList")} />
          <div className="btn-style">
            <Link className="btn btn-outline-secondary" onClick={visibleHandel}>
              <i className="icon-plus plus"></i>
            </Link>
            <PopUp
              text={<Adduser closePopup={closehandle} />}
              isVisible={isVisible}
            />
          </div>
        </div>

        <>
          <div className="table-list">
            <div className="table-responsive">
              <DataTable
                value={dataInfo}
                loading={Loading}
                loadingoverlay={null}
                loadingIcon={<PagesLoading />}
                dataKey="id"
                filters={filters}
                header={headers}
                emptyMessage={t("noDataFound")}
              >
                <Column
                  header={t("hr-code")}
                  field="hrCode"
                  filter
                  filterElement={codeFilterTemplate}
                  style={{ minWidth: "9rem" }}
                  showAddButton={false}
                  showApplyButton={false}
                  showClearButton={false}
                  showFilterMenuOptions={false}
                  body={hrCodeUsers}
                />
                <Column
                  field="displayName"
                  header={t("name")}
                  filter
                  filterElement={nameFilterTemplate}
                  style={{ minWidth: "9rem" }}
                  showAddButton={false}
                  showApplyButton={false}
                  showClearButton={false}
                  showFilterMenuOptions={false}
                />
                <Column
                  field="role"
                  header={t("role")}
                  filter
                  filterPlaceholder="Search by name"
                  style={{ minWidth: "9rem" }}
                  filterElement={roleFilterTemplate}
                  showAddButton={false}
                  showApplyButton={false}
                  showClearButton={false}
                  showFilterMenuOptions={false}
                />
                <Column
                  field="role"
                  header={t("picture")}
                  style={{ minWidth: "9rem" }}
                  body={picture}
                />
                <Column
                  field="email"
                  header={t("email")}
                  filter
                  filterElement={emailFilterTemplate}
                  style={{ minWidth: "9rem" }}
                  showAddButton={false}
                  showApplyButton={false}
                  showClearButton={false}
                  showFilterMenuOptions={false}
                />
                <Column
                  field="verified"
                  header="Status"
                  filter
                  filterElement={StatusFilterTemplate}
                  style={{ minWidth: "9rem" }}
                  showAddButton={false}
                  showApplyButton={false}
                  showClearButton={false}
                  showFilterMenuOptions={false}
                  body={statusColumn}
                />
                <Column
                  field="role"
                  header={t("actions")}
                  style={{ minWidth: "9rem" }}
                  body={elementAction}
                />
              </DataTable>
            </div>
          </div>
          <TablePagination
            data={responseData?.users}
            filterData={filteredData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            responseData={responseData}
          />
        </>
      </div>
    </>
  );
};

export default Users;
