import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Page404 = () => {
 

  return (
    <>
      <div className="theme-cyan">
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <h3>
                      <span className="clearfix title">
                        <span className="number left">404</span>
                        <span className="text">
                          Oops! <br />
                          Page Not Found
                        </span>
                      </span>
                    </h3>
                  </div>
                  <div className="body">
                    <p>
                      The page you were looking for could not be found, please{" "}
                      <Link>contact us</Link> to report this issue.
                    </p>
                    <div className="margin-top-30">
                      <Link to="/">
                        <div className="btn btn-primary">
                          <i className="fa fa-home"></i>
                          <span> Go to Dashboard</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Page404;
