const Api = {
  signIn: `users/signin`,
  verifyEmail: `users/verify-email`,
  ChangePasswordSignIn: `users/change-password`,
  clientAdminCreateUser: `users/client-create-account`,
  findAllUsers: "users/account-users",
  deleteUser: "users/",
  getUserById: "users/",
  updateUser: "users/update-user",
  AdminUpdateUser: "users/update-user",

  createLine: "production-lines/",
  findAllLines: "production-lines/",
  getLineById: "production-lines/",
  deleteLineById: "production-lines/",
  editLine: "production-lines/",

  createComponent: "components",
  findAllComponents: "components",
  deleteComponentById: "components/",
  getComponentsById: "components",
  editComponentsById: "components/",

  createProduct: "products/",
  findAllProducts: "products",
  getProductById: "products",
  deleteProductById: "products/",
  editProduct: "products/",

  createOrder: "orders/",
  getAllOrders: "orders/",
  deleteOrder: "orders/",
  editOrder: "orders/",

  findAllRequests: "requests/",
  editRequest: "requests/",
  getRequestByID: "requests/",

  updateAccount: "accounts/",
  getDeliveryRates: "accounts/delivery-rates",

  dashboardStatus: "requests/dashboard",
};

export default Api;
