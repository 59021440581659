import React from "react";

const withAuth = (WrappedComponent) => {
  const isAdmin = true;

  return (props) => {
    if (!isAdmin) {
      return (
        <div id="main-content">
          <p>You do not have permission to view this page.</p>
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
