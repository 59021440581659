import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";

export const findAllRequests = createAsyncThunk(
  "requests/findAllRequests",
  async ({ token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.get(Api.findAllRequests, config);
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const getRequestByID = createAsyncThunk(
  "requests/getRequestByID",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.get(
        `${Api.getRequestByID}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);







export const editRequest = createAsyncThunk(
  "requests/editRequest",
  async ({ status, id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const filterEmptyFields = (obj) => {
        const filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== "" && obj[key] !== null) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      };

      const dataToUpdate = filterEmptyFields({
        status,
      });

      const response = await axiosInstance.patch(
        `${Api.editRequest}/${id}`,
        dataToUpdate,
        config
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
