import React,{ useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import SparkleCard from "../Dashboard/Charts/SparkleCard";
import Data from "../RequestList/Data";
const Station = () => {
  const [filterVisabilty, setFilterVisabilty] = useState(false);


  const sparkleCardData = [
    {
      heading: "On Train",
      money: "0",
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "#28a745",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "Deliverd",
      money: "0",
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "#95b3d7",
        },

        symbolSize: 1,
      },
    },
  ];

  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    setCardData([...sparkleCardData]);
  }, []);



  return (
    <>
      <div id="main-content">
        <div className="row clearfix">
          {cardData.map((data, i) => (
            <SparkleCard
              index={i}
              key={data.heading}
              Heading={data.heading}
              Money={data.money}
              isRandomUpdate={true}
              mainData={data.sparklineData.data}
              chartColor={data.sparklineData.areaStyle.color}
              ContainerClass="col-lg-6 col-md-6 col-sm-6"
            />
          ))}
        </div>
        <div className="flex-header">
          <PageTitle Title="Station" />

          <div className="btn-style">
            <div
              className="btn btn-outline-secondary"
              onClick={() => setFilterVisabilty(!filterVisabilty)}
            >
              <i className="icon-equalizer plus"></i>
            </div>
          </div>
        </div>
        {filterVisabilty && (
          <div className="card">
            <div className="inputs-search">
              <div className="">
                <input
                  className="form-control"
                  placeholder="Request code "
                  type="number"
                />
              </div>

              <div className="">
                <select
                  name="status"
                  defaultValue="status"
                  className="form-control"
                >
                  <option disabled value="status">
                    status
                  </option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
              <div className="">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-calendar"></i>
                    </span>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    data-date-autoclose="true"
                    data-provide="datepicker"
                    placeholder="date"
                  />
                </div>
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder="Line"
                  type="text"
                />
              </div>
            </div>
          </div>
        )}
        <Data />
      </div>
    </>
  );
};

export default Station;
