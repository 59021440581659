import ReqHeader from "./header";
import { useState } from "react";
import headerTable from "../../../hooks/generic";
import TablePagination from "../../../components/Pagination";
import Checkbox from "../../../components/CheckBox";

const DetailsRequest = ({ createdAt, data, header, status }) => {
  const [currentItems, setCurrentItems] = useState([]);
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const allData = currentItems?.map((item, i) => {
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{item.code}</td>
        <td className="hidden-sm-down">{item.description}ا</td>
        <td>{item.packageType}</td>
        <td>{item.quantity}</td>
        <td className="hidden-sm-down">{item.warehouse}</td>
        <td><Checkbox checked={item.Delivered} /></td>
      </tr>
    );
  });

  const headers = headerTable(header);
  return (
    <>
      <div className="row clearfix">
        <ReqHeader createdAt={createdAt} status={status} />
      </div>
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>{headers}</tr>
              </thead>
              <tbody>{allData}</tbody>
            </table>
          </div>
        </div>
      </div>
      <hr />
      <div className="row clearfix">
        <div className="col-md-12 text-right"></div>

        <div className="hidden-print col-md-12 text-right d-flex flex-wrap justify-content-center small-screen-style">
          <div className=" d-flex justify-content-start">
            <TablePagination data={data} filterData={filteredData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsRequest;
