import { createSlice } from "@reduxjs/toolkit";
import { createOrder, getAllOrders, deleteOrder,editOrder } from "./OrdersActions";
const OrdersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: null,
    responseData: null,
    error: null,
    success: false,
    Loading: false,
    singleLoading:false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(getAllOrders.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.Loading = false;
        state.orders = action.payload;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      }) .addCase(editOrder.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(editOrder.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(editOrder.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      });
  },
});

export const OrdersAction = OrdersSlice.actions;
export default OrdersSlice;
