import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../redux/Auth/AuthSlice";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/logo.png";

function Page403() {
  const dispatch = useDispatch();

  return (
    <div className="theme-cyan">
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="top">
                <img
                  src={Logo}
                  alt="Lucid"
                  style={{ height: "40px", margin: "10px" }}
                />
              </div>
              <div className="card">
                <div className="header">
                  <h3>
                    <span className="clearfix title">
                      <span className="number left">
                        Error403
                        <br />
                      </span>
                      <span className="text">Forbidden Error!</span>
                    </span>
                  </h3>
                </div>
                <div className="body">
                  <p>
                    You don't have permission to access / on this server, Please
                    Login.{" "}
                  </p>

                  <div className="margin-top-30">
                    {/* <a className="btn btn-default" onClick={() => { this.props.history.push("dashboard") }}><i className="fa fa-arrow-left"></i>&nbsp;<span >Go Back</span></a>&nbsp; */}
                    <Link to="/login">
                      <div
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#fdb813",
                          border: "0px",
                          boxShadow: "0px",
                        }}
                        onClick={() => {
                          dispatch(authAction.logOut());
                        }}
                      >
                        &nbsp;<span>Login</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page403;
