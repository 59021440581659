import React, { useState } from "react";
import './index.scss'

function Checkbox({ checked }) {
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <div>
      <input
        type="checkbox"
        className="check-box-style"
        checked={isChecked} 
        onChange={() => setIsChecked(!isChecked)} 
      />
    </div>
  );
}

export default Checkbox;
