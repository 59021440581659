import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (
    {
      productId,
      productQuantity,
      productionLineId,
      preperationOperatorId,
      driverId,
      deliveryStationId,
      token,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.createOrder,
        {
          productId,
          productQuantity,
          productionLineId,
          preperationOperatorId,
          driverId,
          deliveryStationId,
        },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
  async (
    { page, limit,productName, productionLineName, status, startDate, endDate, token },
    { rejectWithValue }
  ) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (productName) params.productName = productName;
      if (productionLineName) params.productionLineName = productionLineName;
      if (status) params.status = status;
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(Api.getAllOrders, config);
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.delete(
        `${Api.deleteOrder}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editOrder = createAsyncThunk(
  "orders/editOrder",
  async ({ id, status, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.patch(
        `${Api.editOrder}/${id}`,
        { status },
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
