import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const MainLayout = () => {
  return (
    <React.Fragment>
      <NavBar />
      <Sidebar />
      <Outlet />
    </React.Fragment>
  );
};

export default MainLayout;
