import convertTimestampToDateTime from "../../hooks/timeStampConverter";
import { badgeProductsStatus } from "../../hooks/generic";
import { useTranslation } from "react-i18next";

const ReqHeader = ({ createdAt, status, line }) => {
  const { dateVariable, timeVariable } = convertTimestampToDateTime(createdAt);
  const [t] = useTranslation();
  return (
    <>
      <div className="col-md-12 col-sm-12 text-left">
        <p className="m-b-0">
          <strong>Order Date:</strong> {dateVariable} <strong>at</strong>{" "}
          {timeVariable}
        </p>
        <p className="m-b-0">
          <strong>Order Status: </strong>
          {status && badgeProductsStatus(status, t)}
        </p>
        <p className="m-b-3">
          <strong>Line: </strong>
          {line}
        </p>
      </div>
    </>
  );
};

export default ReqHeader;
