import PageTitle from "../../../components/PageTitle";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getComponentsById,
  editComponentsById,
} from "../../../redux/Components/ComponentsActions";
import { findAllLines } from "../../../redux/ProductionLines/ProductionLinesActions";
import { Formik, Form, Field } from "formik";
import PagesLoading from "../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";
import "./index.scss";

const ComponentsEdit = ({ closePopup, id, exist }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const token = useSelector((state) => state.auth.data?.accessToken);
  const singleComponentData = useSelector(
    (state) => state.components?.responseData?.component
  );
  const Lines = useSelector(
    (state) => state.productionLines?.lines?.productionLines
  );

  const singleComponentLoading = useSelector(
    (state) => state.components?.singleLoading
  );
  const LinesLoading = useSelector(
    (state) => state.productionLines?.singleLoading
  );
  const [selectedLine, setSelectedLine] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [chosenStation, setChosenStation] = useState([]);
  const [availableStations, setAvailableStations] = useState([]);
  const [newLineStation, setNewLineStation] = useState([]);
  const [oldLineStation, setOldLineStation] = useState([]);
  const [deletedLineStation, setDeletedLineStation] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [singleComponent, setSingleComponent] = useState([]);
  const linesStationsSelected = useMemo(() => {
    if (singleComponent) {
      return singleComponent.componentLineStations?.map((originalObject) => ({
        lineId: originalObject.lineId,
        line: originalObject.productionLine.name,
        station: originalObject.deliveryStation.name,
        stationId: originalObject.deliveryStation.id,
      }));
    }
    return [];
  }, [singleComponent]);

  useEffect(() => {
    if (singleComponentData) {
      setSingleComponent(singleComponentData);
    }
  }, [singleComponentData]);
  const validationSchema = console.log("hola");

  const handleCategory = (e) => {
    setSelectedCategory(e.target.value);
  };

  const deletedStations = (index) => {
    const deletedLineStation = chosenStation[index];
    const deletedLineId = deletedLineStation.lineId;

    setChosenStation((prevLineStations) =>
      prevLineStations.filter((ls, i) => i !== index)
    );

    setNewLineStation((prevLineStations) =>
      prevLineStations.filter((ls) => ls.lineId !== deletedLineId)
    );

    const existingLineIndex = oldLineStation.findIndex(
      (line) => line.lineId === deletedLineId
    );

    if (existingLineIndex !== -1) {
      setDeletedLineStation((prevDeletedLineStations) => [
        ...prevDeletedLineStations,
        deletedLineStation,
      ]);
    }

    const existingLineIndexInAvailable = availableStations.findIndex(
      (line) => line.id === deletedLineId
    );

    if (existingLineIndexInAvailable === -1) {
      const deletedLine = Lines.find((line) => line.id === deletedLineId);
      if (deletedLine) {
        setAvailableStations((prevAvailableStations) => [
          ...prevAvailableStations,
          deletedLine,
        ]);
      }
    } else {
      setAvailableStations((prevAvailableStations) => {
        const updatedLines = [...prevAvailableStations];
        const existingLine = updatedLines[existingLineIndexInAvailable];
        existingLine.stations.push({
          id: deletedLineStation.stationId,
          name: deletedLineStation.station,
        });
        updatedLines[existingLineIndexInAvailable] = existingLine;
        return updatedLines;
      });
    }
  };

  const handleLineChange = (event) => {
    const selectedLineId = parseInt(event.target.value);
    setSelectedLine(selectedLineId);
    setSelectedStation("");
  };

  const handleStationChange = (event) => {
    const selectedStationId = parseInt(event.target.value);
    setSelectedStation(selectedStationId);
  };
  const filterOutOldStations = (newStations, oldStations) => {
    return newStations.filter(
      (newStation) =>
        !oldStations?.some(
          (oldStation) =>
            oldStation.lineId === newStation.lineId &&
            oldStation.stationId === newStation.stationId
        )
    );
  };

  const handleAddStationClick = () => {
    const selectedLineId = selectedLine;
    const selectedStationId = selectedStation;

    if (selectedLineId && selectedStationId) {
      const selectedLineIndex = availableStations.findIndex(
        (line) => line.id === selectedLineId
      );
      const selectedLineObj = availableStations[selectedLineIndex];
      const selectedStationObj = selectedLineObj?.stations.find(
        (station) => station.id === selectedStationId
      );

      if (selectedLineObj && selectedStationObj) {
        const lineStation = {
          lineId: selectedLineId,
          line: selectedLineObj.name,
          station: selectedStationObj.name,
          stationId: selectedStationId,
        };

        const updatedDeletedLineStation = deletedLineStation.filter(
          (deletedStation) =>
            deletedStation.lineId !== selectedLineId ||
            deletedStation.stationId !== selectedStationId
        );
        setDeletedLineStation(updatedDeletedLineStation);

        const updatedAvailableStations = [
          ...availableStations.slice(0, selectedLineIndex),
          ...availableStations.slice(selectedLineIndex + 1),
        ];
        setAvailableStations(updatedAvailableStations);

        setChosenStation((prevLineStations) => [
          ...prevLineStations,
          lineStation,
        ]);

        setNewLineStation((prevLineStations) =>
          filterOutOldStations(
            [...prevLineStations, lineStation],
            oldLineStation
          )
        );

        setSelectedLine("");
        setSelectedStation("");
      }
    }
  };

  useEffect(() => {
    if (!exist) {
      dispatch(getComponentsById({ id: id, token: token }));
    }
    dispatch(findAllLines({ token: token }));
  }, [id, token]);

  useEffect(() => {
    if (Lines) {
      const convertedData = Lines;
      console.log("convertedData", convertedData);
      setAvailableStations(convertedData);
    }
  }, [Lines]);

  useEffect(() => {
    setChosenStation(linesStationsSelected);
    setOldLineStation(linesStationsSelected);
  }, [linesStationsSelected]);

  useEffect(() => {
    if (Lines) {
      const convertedData = Lines;
      const initialAvailableStations = convertedData.filter(
        (line) =>
          !linesStationsSelected?.some(
            (chosenLine) => chosenLine.lineId === line.id
          )
      );
      setAvailableStations(initialAvailableStations);
    }
  }, [Lines, linesStationsSelected]);

  const handleSubmit = (values) => {
    const selectedLineStations = newLineStation?.map((station) => ({
      lineId: station.lineId,
      station: station.stationId,
    }));
    console.log();
    const deletedLineStations = deletedLineStation?.map(
      (station) => station.lineId
    );
    dispatch(
      editComponentsById({
        id: id,
        code: values.code,
        name: values.name,
        packageType: values.packageType,
        quantityPerPackage: values.quantityPerPackage,
        lineStations: selectedLineStations,
        token: token,
        deletedLineStations: deletedLineStations,
      })
    );
    closePopup();
  };

  return (
    <>
      {singleComponentLoading && LinesLoading ? (
        <PagesLoading />
      ) : (
        <>
          <div className="flex-header">
            <PageTitle Title={t("editComponent")} />
          </div>
          <Formik
            initialValues={{
              category: "",
              code: "",
              name: "",
              packageType: "",
              quantityPerPackage: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="">
                  <div className="body">
                    <div className="row clearfix mb-3">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            name="category"
                            value={selectedCategory}
                            onChange={handleCategory}
                            className="form-control"
                          >
                            <option disabled value="">
                              {t("selectCategory")}
                            </option>
                            {/* {availableStations?.map((line) => {
                          return (
                            <option key={line.id} value={line.id}>
                              {line.name}
                            </option>
                          );
                        })} */}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={singleComponent?.code}
                            name="code"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={singleComponent?.name}
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            as="select"
                            name="packageType"
                            placeholder={singleComponent?.packageType}
                            className="form-control"
                          >
                            <option value="" disabled>
                              {t("packaging-type")}
                            </option>
                            <option value="box">Box</option>
                            <option value="bag">Bag</option>
                          </Field>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={singleComponent?.quantityPerPackage}
                            name="quantityPerPackage"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            as="select"
                            name="line"
                            value={selectedLine}
                            onChange={handleLineChange}
                            className="form-control"
                          >
                            <option disabled value="">
                              {t("selectLine")}
                            </option>
                            {availableStations?.map((line) => (
                              <option key={line.id} value={line.id}>
                                {line.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            as="select"
                            name="station"
                            value={selectedStation}
                            onChange={handleStationChange}
                            className="form-control"
                            disabled={!selectedLine}
                          >
                            <option disabled value="">
                              {t("selectStation")}
                            </option>
                            {selectedLine &&
                              availableStations
                                .find((line) => line.id === selectedLine)
                                ?.stations?.map((station) => (
                                  <option key={station.id} value={station.id}>
                                    {station.name}
                                  </option>
                                ))}
                          </Field>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn coloring mt-2"
                            type="button"
                            onClick={handleAddStationClick}
                            disabled={!selectedLine || !selectedStation}
                          >
                            {t("addStation")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      {chosenStation?.length > 0 && (
                        <div>
                          {t("selectedLineStation")}
                          {chosenStation?.map((lineStation, index) => (
                            <div key={index}>
                              <div
                                key={index}
                                style={{
                                  paddingLeft: "15px",
                                  paddingTop: "10px",
                                  width: "300px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <strong>{lineStation.line}:</strong>
                                <span
                                  style={{ marginLeft: "5px", opacity: "0.8" }}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      width: "150px",
                                    }}
                                  >
                                    {lineStation.station?.trim()}
                                    <i
                                      onClick={() => deletedStations(index)}
                                      className="icon-trash ml-3"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-center btn-flex pr-3 my-3">
                      <button
                        className="btn  mr-5 style-btn color-btn"
                        type="button"
                        onClick={() => closePopup()}
                      >
                        {t("cancel")}
                      </button>
                      <button
                        className="btn  style-btn coloring"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t("edit")}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default ComponentsEdit;
