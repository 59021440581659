import React from "react";
import { Link } from "react-router-dom";
// import withAuth from "../../hooks/withAuth";

import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import { findAllProducts } from "../../../redux/Products/ProductsActions";
import { Calendar } from "primereact/calendar";
import TableList from "../../../components/TableList";
import TablePagination from "../../../components/Pagination";
import BackButton from "../../../components/Buttons/backButton";
import UploadFile from "../../../components/uploudFile";
import setTitle from "../../../hooks/Title";
import PageTitle from "../../../components/PageTitle";
import PopUp from "../../../components/PopUp";
// import ProductsAdd from "./ProductsAdd";
// import ProductEdit from "./ProuductsEdits";
// import DeleteProduct from "./deleteProduct";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import DeleteCategory from "./DeleteCategory";
import PagesLoading from "../../../components/Loading/pagesLoading";

import { useTranslation } from "react-i18next";

const Category = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.Loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [productId, setProductId] = useState("");

  // Filter State
  const [productionLine, setProductionLine] = useState("");
  const [product, setProduct] = useState("");
  const [Component, setComponent] = useState("");
  const [Defect, setDefect] = useState("");
  const [Category, setCategory] = useState("");
  const [User, setUser] = useState("");
  const [dateTime, setDateTime] = useState("");

  const [t] = useTranslation();

  // const visibleHandel = () => {
  //   setIsVisible(true);
  // };
  // const closehandle = () => {
  //   setIsVisible(false);
  //   setTimeout(() => {
  //     dispatch(
  //       findAllProducts({
  //         token: token,
  //         page: currentPage,
  //         limit: itemsPerPage,
  //         code,
  //         name,
  //       })
  //     );
  //   }, [3000]);
  // };

  const visibleEditHandel = () => {
    setIsEditVisible(true);
    // setProductId(productId);
  };
  const closeEdithandle = () => {
    setIsEditVisible(false);
  };

  // const visibleFileHandel = () => {
  //   setIsFileVisible(true);
  // };
  const closeAddHandle = () => {
    setIsVisible(false);
  };
  const closeFilehandle = () => {
    setIsFileVisible(false);
  };

  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
    // setTimeout(() => {
    //   dispatch(
    //     findAllProducts({
    //       token: token,
    //       page: currentPage,
    //       limit: itemsPerPage,
    //       code,
    //       name,
    //     })
    //   );
    // }, [1000]);
  };

  const handleDeleteCategory = () => {
    // setProductId(productId);
    setIsDeleteVisible(true);
  };

  // useEffect(() => {
  //   setTitle("Products");
  //   dispatch(
  //     findAllProducts({
  //       token: token,
  //       page: currentPage,
  //       limit: itemsPerPage,
  //       code,
  //       name,
  //     })
  //   );
  // }, [token, currentPage, itemsPerPage, code, name]);

  const data = [
    {
      categoryid: 3,
      category: "aaaa",
    },
    {
      categoryid: 2,
      category: "esedwef",
    },
    {
      categoryid: 1,
      category: "esfwefsdf",
    },
    {
      categoryid: 4,
      category: "qedw4er",
    },
    {
      categoryid: 7,
      category: "rdgr5g",
    },
  ];

  const header = [t("CategoryId"), t("Category"), t("actions")];
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  // const allData = currentItems?.map((item, index) => {
  const allData = data?.map((item, index) => {
    return (
      <tr key={index}>
        <td className="category-id">
          <h6>{item.categoryid}</h6>
        </td>

        <td className="category-name">
          <h6>{item.category}</h6>
        </td>

        <td className="project-actions">
          <Link
            // to={`/admin/products/${item.id}`}
            to={`/admin/qms/categoryDetails/${item.categoryid}`}
            className="btn btn-outline-secondary mr-1"
          >
            <i className="icon-eye"></i>
          </Link>
          <div
            className="btn btn-outline-secondary"
            // onClick={() => visibleEditHandel(item.id)}
            onClick={() => visibleEditHandel()}
          >
            <i className="icon-pencil"></i>
          </div>
          <div
            className="btn btn-outline-danger danger "
            onClick={() => handleDeleteCategory()}
          >
            <i className="icon-trash"></i>
          </div>
        </td>
      </tr>
    );
  });
  return (
    <>
      <PopUp
        text={<DeleteCategory closePopup={closeDeletehandle} id={productId} />}
        isVisible={isDeleteVisible}
      />

      <PopUp
        text={<AddCategory closePopup={closeAddHandle} />}
        isVisible={isVisible}
      />
      <div id="main-content">
        <BackButton />
        <PopUp
      text={<EditCategory closePopup={closeEdithandle} />}
      isVisible={isEditVisible}
    />
        <PopUp
          text={<UploadFile closePopup={closeFilehandle} />}
          isVisible={isFileVisible}
        />
        {/* <PopUp
      text={<ProductsAdd closePopup={closehandle} />}
      isVisible={isVisible}
    /> */}
        <div className="flex-header">
          <PageTitle Title={t("Category")} />
          <div className="btn-style" style={{ height: "35px" }}>
            <div
              className="btn btn-outline-secondary"
              onClick={() => setFilterVisabilty(!filterVisabilty)}
            >
              <i className="icon-equalizer plus"></i>
            </div>

            <div className="user-account m-0">
              <Link
                className="btn btn-outline-secondary"
                onClick={() => setIsVisible(true)}
              >
                <i className="icon-plus plus"></i>
              </Link>
            </div>
          </div>
        </div>
        {filterVisabilty && (
          <div className="card">
            <div className="inputs-search">
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("CategoryId")}
                  type="text"
                  onChange={(e) => {
                    setProductionLine(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  className="form-control"
                  placeholder={t("Category")}
                  type="text"
                  onChange={(e) => {
                    setProduct(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <PagesLoading />
        ) : (
          <>
            <TableList header={header} allData={allData} />
            <TablePagination
              data={responseData?.products}
              filterData={filteredData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              responseData={responseData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Category;
