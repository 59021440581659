import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PageTitle from "../../../components/PageTitle";
import PagesLoading from "../../../components/Loading/pagesLoading";
import TablePagination from "../../../components/Pagination";
const Clients = () => {
  const [t] = useTranslation();
  const loading = useSelector((state) => state.products.singleLoading); // change the selector to superAdmin redux

  const [state, setState] = useState({
    disabled: true,
    firstData: false,
    dataInfo: [],
    filters: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  const [data, setData] = useState([]);

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    console.log(today);
    const clients = [
      {
        id: "1",
        companyName: "ABC Corp",
        accountsCount: "10",
        usersCount: "50",
        endSubscription: "2023-11-15",
      },
      {
        id: "2",
        companyName: "XYZ Ltd",
        accountsCount: "5",
        usersCount: "20",
        endSubscription: "2023-11-06",
      },
      {
        id: "3",
        companyName: "123 Inc",
        accountsCount: "3",
        usersCount: "15",
        endSubscription: "2023-06-30",
      },
      {
        id: "4",
        companyName: "DEF Co",
        accountsCount: "8",
        usersCount: "40",
        endSubscription: "2022-09-25",
      },
      {
        id: "5",
        companyName: "456 Ltd",
        accountsCount: "2",
        usersCount: "10",
        endSubscription: "2021-12-10",
      },
      {
        id: "6",
        companyName: "GHI Corp",
        accountsCount: "12",
        usersCount: "60",
        endSubscription: "2023-02-28",
      },
      {
        id: "7",
        companyName: "789 Inc",
        accountsCount: "6",
        usersCount: "30",
        endSubscription: "2022-05-15",
      },
      {
        id: "8",
        companyName: "JKL Ltd",
        accountsCount: "4",
        usersCount: "20",
        endSubscription: "2023-08-31",
      },
      {
        id: "9",
        companyName: "MNO Co",
        accountsCount: "9",
        usersCount: "45",
        endSubscription: "2022-11-25",
      },
      {
        id: "10",
        companyName: "PQR Corp",
        accountsCount: "7",
        usersCount: "35",
        endSubscription: "2021-10-10",
      },
      {
        id: "11",
        companyName: "STU Inc",
        accountsCount: "11",
        usersCount: "55",
        endSubscription: "2023-01-31",
      },
      {
        id: "12",
        companyName: "VWX Ltd",
        accountsCount: "3",
        usersCount: "15",
        endSubscription: "2022-04-15",
      },
      {
        id: "13",
        companyName: "YZA Corp",
        accountsCount: "6",
        usersCount: "30",
        endSubscription: "2023-07-30",
      },
      {
        id: "14",
        companyName: "BCD Inc",
        accountsCount: "4",
        usersCount: "20",
        endSubscription: "2022-10-25",
      },
      {
        id: "15",
        companyName: "EFG Ltd",
        accountsCount: "8",
        usersCount: "40",
        endSubscription: "2021-09-10",
      },
    ].map((item) => {
      const endSubscriptionDate = new Date(item.endSubscription);
      endSubscriptionDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 to ignore time component

      if (endSubscriptionDate < today) {
        return { ...item, status: "expired", key: item.id };
      } else if (endSubscriptionDate.getTime() > today.getTime()) {
        return { ...item, status: "active", key: item.id };
      } else if (endSubscriptionDate.getTime() === today.getTime()) {
        return { ...item, status: "ends today", key: item.id };
      }
    });
    setData(clients);
  }, []);

  const initFilters = () => {
    setState({
      ...state,
      filters: {
        code: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        components: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        qty: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    });
  };

  const actionsProducts = (rowData) => {
    return (
      <>
        <Link
          to={`/superAdmin/clientDetails`}
          className="btn btn-outline-secondary mr-1"
          // onClick={() => {
          //   dispatch(getProductById({ id: productID, token: token }));
          // }}
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-secondary"
          //   onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
        <div
          className="btn btn-outline-danger danger "
          //   onClick={() => handleDeleteProduct(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            textAlign: "end",
            opacity: state.disabled ? "0.2" : "0.6",
          }}
          disabled={state.disabled}
          onClick={() => {
            // dispatch(
            //   getProductById({
            //     id: productID,
            //     token: token,
            //     page: currentPage,
            //     limit: itemsPerPage,
            //   })
            // );
            // setCode("");
            // setComponentName("");
            setState({ ...state, disabled: true });
          }}
        />
      </div>
    );
  };
  const headers = renderHeader();

  return (
    <div id="main-content">
      <PageTitle Title={t("Clients")} />
      <div className="table-list">
        <div className="table-responsive">
          <DataTable
            value={data}
            loading={loading}
            loadingIcon={<PagesLoading />}
            dataKey="id"
            filters={state.filters}
            header={headers}
            emptyMessage={t("noDataFound")}
          >
            <Column
              header={t("ID")}
              field="id"
              filter
              //   filterElement={codeFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("Company Name")}
              field="companyName"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("No. Accounts")}
              field="accountsCount"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("No. Users")}
              field="usersCount"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("End Of Subscription")}
              field="endSubscription"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />

            <Column
              header={t("Status")}
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
              body={(rowData) => rowData.status}
            />

            <Column
              header={t("actions")}
              style={{ minWidth: "12rem" }}
              body={actionsProducts}
            />
          </DataTable>
          <TablePagination
            data={data}
            filterData={filteredData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            // responseData={responseData}
          />
        </div>
      </div>
    </div>
  );
};

export default Clients;
