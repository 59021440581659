import React from "react";
import "./index.scss";
import TablePagination from "../../components/Pagination";

const NotificationList = () => {
  return (
    <div id="main-content">
      <div className="card d-flex flex-row  justify-content-between align-items-center p-3 card-style">
        <span>Campaign Holiday Sale is nearly reach budget limit.</span>
        <span> 10:00 AM Today</span>
      </div>
      <TablePagination />
    </div>
  );
};

export default NotificationList;
