import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { MultiSelect } from "primereact/multiselect";
import { useSelector, useDispatch } from "react-redux";
import { findAllLines } from "../../../redux/ProductionLines/ProductionLinesActions";
import { findAllComponents } from "../../../redux/Components/ComponentsActions";
import { CreateProduct } from "../../../redux/Products/ProductsActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import PagesLoading from "../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";

const ProductsAdd = ({ closePopup }) => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const lines = useSelector((state) => state.productionLines.lines);
  const components = useSelector((state) => state.components.components);

  const [t] = useTranslation();

  const linesLoading = useSelector(
    (state) => state.productionLines.singleLoading
  );
  const componentsLoading = useSelector((state) => state.components.Loading);

  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLines, setSelectedLines] = useState(null);

  const Lines = lines?.productionLines;

  useEffect(() => {
    dispatch(findAllLines({ token: token }));
    dispatch(findAllComponents({ token: token }));
  }, [token]);

  const handleCountChange = (e, option) => {
    const count = parseInt(e.target.value, 10);
    const updatedOptions = [...selectedOptions];
    const existingOptionIndex = updatedOptions.findIndex(
      (o) => o.id === option.id
    );

    if (count > 0) {
      const updatedOption = { id: option.id, quantity: count };

      if (existingOptionIndex !== -1) {
        updatedOptions[existingOptionIndex] = updatedOption;
      } else {
        updatedOptions.push(updatedOption);
      }
    } else if (existingOptionIndex !== -1) {
      updatedOptions.splice(existingOptionIndex, 1);
    }

    setSelectedOptions(updatedOptions);
  };

  const handleNumberInputClick = (event) => {
    event.stopPropagation();
  };

  const countries = components?.components;

  const countryTemplate = (option) => {
    const selectedOption = selectedOptions.find((o) => o.id === option.id);
    const count = selectedOption ? selectedOption.quantity : 0;

    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ gap: "10px" }}
      >
        <div>{option.name}</div>
        <input
          className="form-control"
          style={{ width: "60px" }}
          type="number"
          value={count}
          onChange={(e) => handleCountChange(e, option)}
          onClick={handleNumberInputClick}
        />
        {count > 0 && <span>&#10003;</span>}
      </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = selectedCountries ? selectedCountries.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length !== 1 ? "s" : ""} selected.
      </div>
    );
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t("codeRequired")),
    description: Yup.string().required(t("descriptionRequired")),
    productionRate: Yup.number().required(t("productionRateRequired")).min(0),
    selectedLines: Yup.array().required(t("lineRequired")),
    selectedCountries: Yup.array().required(t("ComponentRequired")),
  });

  const initialValues = {
    code: "",
    description: "",
    productionRate: "",
    selectedLines: "",
    selectedCountries: "",
  };

  const onSubmit = (values) => {
    dispatch(
      CreateProduct({
        code: values.code,
        name: values.description,
        productionRate: values.productionRate,
        productionLines: values.selectedLines,
        components: selectedOptions,
        token: token,
      })
    );

    closePopup();
  };

  const validation = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema,
    onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {linesLoading && componentsLoading ? (
        <PagesLoading />
      ) : (
        <>
          <div className="flex-header">
            <PageTitle Title={t("addProduct")}/>
          </div>
          <hr />
          <div className="">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <input
                      {...validation.getFieldProps("code")}
                      className="form-control"
                      placeholder={t("code")}
                      type="text"
                    />
                    {validation.touched.code && validation.errors.code && (
                      <div className="error">{validation.errors.code}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <input
                      {...validation.getFieldProps("description")}
                      className="form-control"
                      placeholder={t("description")}
                      type="text"
                    />
                    {validation.touched.description &&
                      validation.errors.description && (
                        <div className="error">
                          {validation.errors.description}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <input
                      {...validation.getFieldProps("productionRate")}
                      className="form-control"
                      placeholder={t("production-rate")}
                      type="number"
                      min="0"
                      onClick={handleNumberInputClick}
                    />
                    {validation.touched.productionRate &&
                      validation.errors.productionRate && (
                        <div className="error">
                          {validation.errors.productionRate}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <MultiSelect
                    value={selectedLines}
                    onChange={(e) => {
                      const selectedLineIds = e.value.map((line) => line.id);
                      validation.setFieldValue(
                        "selectedLines",
                        selectedLineIds
                      );
                      setSelectedLines(e.value);
                    }}
                    options={Lines}
                    optionLabel="name"
                    filter
                    placeholder={t("selectLines")}
                    maxSelectedLabels={3}
                    style={{ width: "100%" }}
                    className=""
                  />
                  {validation.touched.selectedLines &&
                    validation.errors.selectedLines && (
                      <div className="error">
                        {validation.errors.selectedLines}
                      </div>
                    )}
                </div>

                <div className="col-lg-6 col-md-12">
                  <MultiSelect
                    value={selectedCountries}
                    onChange={(e) => {
                      setSelectedCountries(e.value);
                      validation.setFieldValue("selectedCountries", e.value);
                    }}
                    options={countries}
                    optionLabel="name"
                    placeholder={t("selectComponents")}
                    itemTemplate={countryTemplate}
                    panelFooterTemplate={panelFooterTemplate}
                    maxSelectedLabels={3}
                    display="chip"
                    style={{ width: "100%" }}
                    filter
                    className=""
                  />
                  {validation.touched.selectedCountries &&
                    validation.errors.selectedCountries && (
                      <div className="error">
                        {validation.errors.selectedCountries}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center btn-flex pr-3 my-3">
              <button
                className="btn  mr-5 style-btn color-btn"
                type="button"
                onClick={() => closePopup()}
              >
                {t("cancel")}
              </button>
              <button className="btn  style-btn coloring" type="submit">
                {t("addProduct")}
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default ProductsAdd;
