import React from "react";

const convertTimestampToDateTime = (timestamp) => {
  if (!timestamp || typeof timestamp !== "string" || !timestamp.includes("T")) {
    // Handle invalid or missing timestamp
    return { dateVariable: "", timeVariable: "" };
  }

  const datetimeObj = new Date(timestamp);
  const hours = datetimeObj.getHours();
  const minutes = datetimeObj.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  const datePart = datetimeObj.toISOString().slice(0, 10);
  const timeVariable = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`;

  return { dateVariable: datePart, timeVariable };
};

export default convertTimestampToDateTime;
