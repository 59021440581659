import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { CreateComponent } from "../../../redux/Components/ComponentsActions";
import { findAllLines } from "../../../redux/ProductionLines/ProductionLinesActions";
import { useTranslation } from "react-i18next";

const ComponentsAdd = ({ closePopup }) => {
  let token = useSelector((state) => state.auth.data?.accessToken);
  const lines = useSelector((state) => state.productionLines?.lines);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [lineStations, setLineStations] = useState([]);
  const [selectedLine, setSelectedLine] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [availableStations, setAvailableStations] = useState([]);
  const [selectedLinesHistory, setSelectedLinesHistory] = useState([]);

  useEffect(() => {
    setAvailableStations(lines?.productionLines);
  }, [lines]);

  useEffect(() => {
    dispatch(findAllLines({ token: token }));
  }, [token]);

  const validationSchema = Yup.object().shape({
    // category: Yup.string().required("Category is required"),
    code: Yup.string().required("Code is required"),
    name: Yup.string().required("Name is required"),
    packageType: Yup.string().required("Packaging Type is required"),
    quantityPerPackage: Yup.number()
      .typeError("Quantity must be a number")
      .required("Quantity per package is required"),
  });

  const deletedStations = (index) => {
    const deletedLineId = lineStations[index]?.lineId;

    setLineStations((prevLineStations) =>
      prevLineStations.filter((ls, i) => i !== index)
    );

    setAvailableStations((prevAvailableStations) => {
      const deletedLine = lines?.productionLines.find(
        (line) => line.id === deletedLineId
      );

      if (deletedLine) {
        return [...prevAvailableStations, deletedLine];
      }

      return prevAvailableStations;
    });
  };

  const handleSubmit = (values) => {
    const selectedLineStations = lineStations?.map((lineStation) => ({
      lineId: lineStation.lineId,
      station: lineStation.stationId,
    }));

    dispatch(
      CreateComponent({
        code: values.code,
        name: values.name,
        packageType: values.packageType,
        quantityPerPackage: values.quantityPerPackage,
        lineStations: selectedLineStations,
        token: token,
      })
    );
    closePopup();
  };

  const handleCategory = (e) => {
    setSelectedCategory(e.target.value);
  };
  const handleLineChange = (event) => {
    const selectedLineId = parseInt(event.target.value);

    if (
      !lineStations.some((lineStation) => lineStation.lineId === selectedLineId)
    ) {
      setSelectedLine(selectedLineId);
      setSelectedStation("");
    } else {
      setSelectedLine("");
      setSelectedStation("");
    }
  };

  const handleStationChange = (event) => {
    const selectedStationId = parseInt(event.target.value);
    if (
      !lineStations.some(
        (lineStation) => lineStation.stationId === selectedStationId
      )
    ) {
      setSelectedStation(selectedStationId);
    } else {
      setAvailableStations((prevAvailableStations) => {
        const selectedLineId = lineStations.find(
          (lineStation) => lineStation.stationId === selectedStationId
        ).lineId;

        return prevAvailableStations?.map((line) => {
          if (line.id === selectedLineId) {
            return {
              ...line,
              stations: line.stations.filter(
                (station) => station.id !== selectedStationId
              ),
            };
          }
          return line;
        });
      });
      setSelectedStation("");
    }
  };

  const handleAddStationClick = () => {
    const selectedLineId = selectedLine;
    if (selectedLineId) {
      setSelectedLinesHistory((prevSelectedLines) => [
        ...prevSelectedLines,
        selectedLineId,
      ]);
    }
    if (selectedLine && selectedStation) {
      const lineStation = {
        lineId: selectedLine,
        lineName: availableStations.find((line) => line.id === selectedLine)
          ?.name,
        stationId: selectedStation,
        stationName: availableStations
          .find((line) => line.id === selectedLine)
          ?.stations?.find((station) => station.id === selectedStation)?.name,
      };
      setLineStations((prevLineStations) => [...prevLineStations, lineStation]);
      setAvailableStations((prevAvailableStations) =>
        prevAvailableStations?.filter((line) => line.id !== selectedLine)
      );

      setSelectedLine("");
      setSelectedStation("");
    }
  };

  return (
    <>
      <div className="flex-header">
        <PageTitle Title={t("addComponent")} />
      </div>

      <Formik
        initialValues={{
          category: "",
          code: "",
          name: "",
          packageType: "box",
          quantityPerPackage: 1,
          line: "",
          station: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="">
              <div className="body">
                <div className="row clearfix mb-3">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <select
                        name="category"
                        value={selectedCategory}
                        onChange={handleCategory}
                        className="form-control"
                      >
                        <option disabled value="">
                          {t("selectCategory")}
                        </option>
                        {/* {availableStations?.map((line) => {
                          return (
                            <option key={line.id} value={line.id}>
                              {line.name}
                            </option>
                          );
                        })} */}
                      </select>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="code"
                        placeholder={t("code")}
                        type="text"
                      />
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="name"
                        placeholder={t("description")}
                        type="text"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <select name="packageType" className="form-control">
                        <option disabled value="packagingType">
                          {t("packaging-type")}
                        </option>
                        <option value="box">{t("box")}</option>
                        <option value="bag">{t("bag")}</option>
                      </select>
                      <ErrorMessage
                        name="packageType"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        className="form-control"
                        name="quantityPerPackage"
                        placeholder="Qty per package"
                        type="number"
                      />
                      <ErrorMessage
                        name="quantityPerPackage"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <select
                        name="line"
                        value={selectedLine}
                        onChange={handleLineChange}
                        className="form-control"
                      >
                        <option disabled value="">
                          {t("selectLine")}
                        </option>
                        {availableStations?.map((line) => {
                          return (
                            <option key={line.id} value={line.id}>
                              {line.name}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        name="line"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <select
                        name="station"
                        value={selectedStation}
                        onChange={handleStationChange}
                        className="form-control"
                        disabled={!selectedLine}
                      >
                        <option disabled value="">
                          {t("selectStation")}
                        </option>
                        {selectedLine &&
                          availableStations
                            .find((line) => line.id === selectedLine)
                            ?.stations?.map((station) => (
                              <option key={station.id} value={station.id}>
                                {station.name}
                              </option>
                            ))}
                      </select>
                      <ErrorMessage
                        name="station"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn coloring mt-2"
                        type="button"
                        onClick={handleAddStationClick}
                        disabled={!selectedLine || !selectedStation}
                      >
                        {t("addStation")}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    {lineStations?.length > 0 && (
                      <div>
                        {lineStations?.map((lineStationGroup, groupIndex) => (
                          <div
                            key={groupIndex}
                            style={{
                              paddingLeft: "15px",
                              paddingTop: "10px",
                              width: "300px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <strong>{lineStationGroup.lineName}:</strong>
                            <span style={{ marginLeft: "5px", opacity: "0.8" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                  width: "150px",
                                }}
                              >
                                {lineStationGroup?.stationName?.trim()}
                                <i
                                  onClick={() => deletedStations(groupIndex)}
                                  className="icon-trash ml-3"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center btn-flex pr-3 my-3">
                  <button
                    className="btn  mr-5 style-btn color-btn"
                    type="button"
                    onClick={() => closePopup()}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="btn  style-btn coloring"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ComponentsAdd;
