import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { Dropdown } from "primereact/dropdown";
import Previews from "../../../components/UploudImage";
import BackButton from "../../../components/Buttons/backButton";
const Forms = () => {
  const [selectedLine, setSelectedLine] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState(null);
  const [selectedDefects, setSelectedDefects] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);

  const saveImg = (data) => {
    setSelectedImg(data);
  };

  console.log("Img", selectedImg);
  const Lines = [
    { name: "1", code: "NY" },
    { name: "2", code: "RM" },
    { name: "3", code: "LDN" },
    { name: "4", code: "IST" },
    { name: "5", code: "PRS" },
  ];

  const categories = [
    { name: "metal", code: "NY" },
    { name: "wood", code: "RM" },
    { name: "plastic", code: "LDN" },
  ];

  const Products = [
    { name: "car", code: "NY" },
    { name: "watch", code: "RM" },
    { name: "mobile", code: "LDN" },
  ];

  const components = [
    { name: "comp1", code: "NY" },
    { name: "comp2", code: "RM" },
    { name: "comp3", code: "LDN" },
    { name: "comp4", code: "IST" },
    { name: "5comp5", code: "PRS" },
  ];
  const defects = [
    { name: "defect1", code: "NY" },
    { name: "defect2", code: "RM" },
    { name: "defect3", code: "LDN" },
    { name: "defect4", code: "IST" },
    { name: "defect5", code: "PRS" },
  ];
  return (
    <>
      <div id="main-content">
        <BackButton />
        <PageTitle Title="Forms" />

        <div className="card" >
          <div className="body pb-5 ">
            <h2 className="text-center">Fresh</h2>
            <hr />

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3 ">
                  <div>
                    <label className="mr-2">Line:</label>
                  </div>

                  <Dropdown
                    value={selectedLine}
                    onChange={(e) => setSelectedLine(e.value)}
                    options={Lines}
                    optionLabel="name"
                    placeholder="Select Line"
                    filter
                    className="w-full "
                    style={{ width: "100%" }}
                  />
                </div>
                {selectedLine ? (
                  <div className="mb-3">
                    <div>
                      <label className="mr-2">Product:</label>
                    </div>

                    <Dropdown
                      value={selectedProducts}
                      onChange={(e) => setSelectedProducts(e.value)}
                      options={Products}
                      optionLabel="name"
                      placeholder="Select a Product"
                      filter
                      className="w-full "
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {selectedProducts ? (
                  <div className="mb-3">
                    <div>
                      <label className="mr-2">Component:</label>
                    </div>

                    <Dropdown
                      value={selectedComponents}
                      onChange={(e) => setSelectedComponents(e.value)}
                      options={components}
                      optionLabel="name"
                      placeholder="Select Component "
                      filter
                      className="w-full"
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {selectedComponents ? (
                  <div>
                    <div className="mb-3">
                      <div>
                        <label className="mr-2">Components quantity:</label>
                      </div>
                      <input
                        className="form-control"
                        type="number"
                        min={1}
                        defaultValue={1}
                      />
                    </div>
                    <div className="mb-3">
                      <div>
                        <label className="mr-2">Defect:</label>
                      </div>
                      <Dropdown
                        value={selectedDefects}
                        onChange={(e) => setSelectedDefects(e.value)}
                        options={defects}
                        optionLabel="name"
                        placeholder="Select Defect "
                        filter
                        className="w-full"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-sm-12">
                {" "}
                <div>
                  <label className="mr-2">Select picture:</label>
                </div>
                <Previews saveImg={saveImg} />
              </div>
            </div>
            {selectedDefects ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "5px",
                  margin: "40px 20px 0px 20px",
                }}
              >
                <button className="btn btn-danger" type="submit">
                  cancel
                </button>
                <button
                  className="btn btn-primary  primary-btn-style"
                  type="submit"
                >
                  submit
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Forms;
