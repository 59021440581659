import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PageTitle from "../../../components/PageTitle";
import PagesLoading from "../../../components/Loading/pagesLoading";
import TablePagination from "../../../components/Pagination";
const SuperAdminUsers = () => {
  const [t] = useTranslation();
  const loading = useSelector((state) => state.products.singleLoading); // change the selector to superAdmin redux

  const [state, setState] = useState({
    disabled: true,
    firstData: false,
    dataInfo: [],
    filters: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const Users = [
    {
      id: "1",
      name: "ABC Corp",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "2",
      name: "XYZ Ltd",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "3",
      name: "123 Inc",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "4",
      name: "DEF Co",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "5",
      name: "456 Ltd",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "6",
      name: "GHI Corp",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "7",
      name: "789 Inc",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "8",
      name: "JKL Ltd",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "9",
      name: "MNO Co",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "10",
      name: "PQR Corp",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "11",
      name: "STU Inc",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "12",
      name: "VWX Ltd",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "13",
      name: "YZA Corp",
      email: "test@gmail.com",
      access: "view",
    },
    {
      id: "14",
      name: "BCD Inc",
      email: "test@gmail.com",
      access: "full access",
    },
    {
      id: "15",
      name: "EFG Ltd",
      email: "test@gmail.com",
      access: "view",
    },
  ];

  const initFilters = () => {
    setState({
      ...state,
      filters: {
        code: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        components: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        qty: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    });
  };

  const actionsProducts = (rowData) => {
    return (
      <>
        <Link
          to={`/admin/products/${rowData.id}`}
          className="btn btn-outline-secondary mr-1"
          // onClick={() => {
          //   dispatch(getProductById({ id: productID, token: token }));
          // }}
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-secondary"
          //   onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
        <div
          className="btn btn-outline-danger danger "
          //   onClick={() => handleDeleteProduct(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            textAlign: "end",
            opacity: state.disabled ? "0.2" : "0.6",
          }}
          disabled={state.disabled}
          onClick={() => {
            // dispatch(
            //   getProductById({
            //     id: productID,
            //     token: token,
            //     page: currentPage,
            //     limit: itemsPerPage,
            //   })
            // );
            // setCode("");
            // setComponentName("");
            setState({ ...state, disabled: true });
          }}
        />
      </div>
    );
  };
  const headers = renderHeader();

  return (
    <div id="main-content">
      <PageTitle Title={t("users")} />
      <div className="table-list">
        <div className="table-responsive">
          <DataTable
            value={Users}
            loading={loading}
            loadingIcon={<PagesLoading />}
            dataKey="id"
            filters={state.filters}
            header={headers}
            emptyMessage={t("noDataFound")}
          >
            <Column
              header={t("ID")}
              field="id"
              filter
              //   filterElement={codeFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("Name")}
              field="name"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("Email")}
              field="email"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />
            <Column
              header={t("Access")}
              field="access"
              filter
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
            />

            <Column
              header={t("actions")}
              style={{ minWidth: "12rem" }}
              body={actionsProducts}
            />
          </DataTable>
          <TablePagination
            data={Users}
            filterData={filteredData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            // responseData={responseData}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminUsers;
