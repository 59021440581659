import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const DriverPrivate = () => {
    const role = useSelector((state) => state.auth.data?.role);

  return <>{role === "driver" ? <Outlet /> : <Navigate to="/" />}</>;
};

export default DriverPrivate;
