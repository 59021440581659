import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input } from "reactstrap";
import { useSelector ,useDispatch} from "react-redux";
import { inviteUser } from "../../../redux/Auth/AuthActions";

const Adduser = ({ closePopup }) => {
  let token = useSelector((state) => state.auth.data?.accessToken);
  const dispatch = useDispatch();

  const validation = useFormik({
    initialValues: {
      code: "",
      email: "",
      name: "",
      role: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
      name: Yup.string().required("Please Enter Name."),
      role: Yup.string().required("Please Select the Role."),
    }),
    onSubmit: (values) => {
      dispatch(
        inviteUser({
          hrCode: values.code,
          email: values.email,
          displayName: values.name,
          role: values.role,
          token: token,
        })
      );
      closePopup();
    },
  });

  return (
    <div>
      <h4 style={{ opacity: "0.7" }}>Add User</h4>
      <hr />
      <div className="body">
        <form
          className="row clearfix"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <Input
                label="code"
                id="signin-email"
                type="string"
                name="code"
                placeholder="Enter code"
                className="form-control"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
              />
              {validation.touched.code && validation.errors.code ? (
                <p style={{ color: "red" }}>{validation.errors.code}</p>
              ) : null}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <Input
                label="Username"
                id="signin-email"
                type="email"
                name="email"
                placeholder="Enter email"
                className="form-control"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
              />
              {validation.touched.email && validation.errors.email ? (
                <p style={{ color: "red" }}>{validation.errors.email}</p>
              ) : null}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <Input
                label="Username"
                id="signin-email"
                type="text"
                name="name"
                placeholder="Enter full Name"
                className="form-control"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
              />
              {validation.touched.name && validation.errors.name ? (
                <p style={{ color: "red" }}>{validation.errors.name}</p>
              ) : null}
            </div>
          </div>

          <div className="form-group dropdownLine col-lg-6 col-md-12">
            <select
              className="form-control"
              name="role"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.role || ""}
            >
              <option value="" disabled>
                -- Select Role --
              </option>
              <option value="driver">Driver</option>
              <option value="preparation_operator">
                Preparation Operator{" "}
              </option>
              <option value="delivery_station">Station</option>
            </select>
            {validation.touched.role && validation.errors.role ? (
              <p style={{ color: "red" }}>{validation.errors.role}</p>
            ) : null}
          </div>
          <div className="d-flex justify-content-center btn-flex pr-3 mb-3">
            <button
              className="btn  mr-5 style-btn color-btn"
              type="button"
              onClick={() => closePopup()}
            >
              Cancel
            </button>
            <button className="btn  style-btn coloring" type="submit">
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Adduser;
