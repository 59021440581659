import React from "react";

const DataManaged = () => {
  return (
    <div>
      <div className="card" style={{ height: "500px" }}>
        <div className="header">
          <h2>Data Managed</h2>
        </div>
        <div className="body">
          <div className="row">
            <div className="col-md-6">
              <h2>0</h2>
              <p>External Records</p>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="table-responsive">
            <table className="table table-hover m-b-0">
              <tbody>
                <tr>
                  <th>
                    <i className="fa fa-circle text-success"></i>
                  </th>
                  <td>Planned</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <i className="fa fa-circle text-info"></i>
                  </th>
                  <td>In Preparation</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <i className="fa fa-circle text-warning"></i>
                  </th>
                  <td>Ready</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <i className="fa fa-circle text-danger"></i>
                  </th>
                  <td>On Train</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <i className="fa fa-circle "></i>
                  </th>
                  <td>Delivered</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <i className="fa fa-circle "></i>
                  </th>
                  <td>Canceled</td>
                  <td>
                    <span>0 Records</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataManaged;
