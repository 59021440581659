import React from "react";
import PageTitle from "../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../redux/Auth/AuthActions";
import { useTranslation } from "react-i18next";

const DeleteUser = ({ closePopup, id }) => {
  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data?.accessToken);
  const dispatch = useDispatch();
  const deleteUserByID = () => {
    dispatch(
      deleteUser({
        id: id,
        token: token,
      })
    );
    closePopup();
  };
  return (
    <>
      <div
        className="flex-header"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <PageTitle Title={t("deleteUser")} />
      </div>

      <div>
        <div className="body">
          <div className="text-center mb-3">
            <p>{t("deleteUSerDescription")}</p>
          </div>

          <div className="d-flex justify-content-center btn-flex pr-3 mb-3">
            <button
              className="btn  mr-5 style-btn color-btn"
              type="button"
              onClick={() => closePopup()}
            >
              {t("cancel")}
            </button>
            <button
              className="btn  style-btn coloring"
              type="submit"
              onClick={deleteUserByID}
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteUser;
