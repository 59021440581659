import { useState } from "react";
import headerTable from "../../../hooks/generic";

import RegularTablePagination from "../../../components/Pagination/regularPagination";

const Components = ({ createdAt, data, historyHeader, status }) => {
  // const [selectedValue, setSelectedValue] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const allData = currentItems?.map((item, i) => {
    console.log("item====",item);
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{item.componentId}</td>
        <td>{item.component.name}</td>
      </tr>
    );
  });

  const headers = headerTable(historyHeader);
  console.log(status);
  return (
    <>
      <div className="row clearfix"></div>
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>{headers}</tr>
              </thead>
              <tbody>{allData}</tbody>
            </table>
          </div>
        </div>
      </div>
      <hr />
      <div className="row clearfix">
        <div className="col-md-12 text-right"></div>

        <div className="hidden-print col-md-12 text-right d-flex flex-wrap justify-content-between align-items-center small-screen-style">
          <div className=" d-flex justify-content-start">
            <RegularTablePagination data={data} filterData={filteredData} />
          </div>
          {/* <div className=" mt-3 mb-2">
            <select
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              className="selectColor"
            >
              <option value="">Select status</option>
              <option value="option1">Planned</option>
              <option value="option2">In Preparation</option>
              <option value="option3">Ready</option>
              <option value="option3">On Train</option>
              <option value="option3">Deliverd</option>
              <option value="option3">canceled</option>
            </select>
            <button className="btn btn-primary change">Change</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Components;
