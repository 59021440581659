import React from "react";

const PaymentDetails = () => {
  const paymentDetailsData = [
    { date: "2023-11-15", amount: 800 },
    { date: "2023-9-2", amount: 220 },
    { date: "2023-4-11", amount: 150 },
    { date: "2023-1-30", amount: 1000 },
  ];

  const paymentInfo = {
    lastPayment: {
      date: "2023-11-15",
      amount: 800,
    },
    details: {
      package: "Gold",
      expiredDate: "2024-11-15",
    },
  };
  
  return (
    <div className="row " style={{ width: "100%", margin: "0 auto" }}>
      <div className="col-lg-7">
        {paymentDetailsData.map((payment, index) => (
          <div
            key={index}
            className="card d-flex flex-row justify-content-between align-items-center p-3 Payment-card-style"
          >
            <div>
              <i className="fa fa-download mr-3 icon "></i>
              <span>{payment.date}</span>
            </div>
            <div>
              <span>$</span>
              <span> {payment.amount}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="col-lg-5  borderVisible p-4 card d-none d-lg-block ">
        <div>
          <h4 className="mb-3">Last Payment</h4>
          <div className="d-flex justify-content-between">
            <h6 className="">Date: </h6>
            <h6 className="">{paymentInfo.lastPayment.date}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <h6 className="mb-2">Amount:</h6>
          <div>
            <span>$</span>
            <span>{paymentInfo.lastPayment.amount}</span>
          </div>
        </div>
        <hr />
        <div>
          <h4 className="mb-3">Payment Details</h4>
          <div className="d-flex justify-content-between">
            <h6 className="">Package: </h6>
            <h6 className="">{paymentInfo.details.package}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className="">Expired Date: </h6>
            <h6 className="">{paymentInfo.details.expiredDate}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
