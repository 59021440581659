import React, { useState } from "react";
import DayCheckbox from "./DayCheckbox";


import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const WeekdaySelector = () => {
  const [days, setDays] = useState({
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  });

  const [times, setTimes] = useState({
    MON: [{ from: null, to: null }],
    TUE: [{ from: null, to: null }],
    WED: [{ from: null, to: null }],
    THU: [{ from: null, to: null }],
    FRI: [{ from: null, to: null }],
    SAT: [{ from: null, to: null }],
    SUN: [{ from: null, to: null }],
  });

  const handleCheckboxChange = (day) => {
    setDays((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };

  const handleTimeChange = (day, index, fieldName, value) => {
    const newTimes = { ...times };
    const timeRange = newTimes[day][index];
    console.log("value", value);

    timeRange[fieldName] = value;

    if (fieldName === "from" && timeRange.to && value >= timeRange.to) {
      timeRange.from = null; // Invalid input, reset the "from" time
    }

    if (fieldName === "to" && timeRange.from && value <= timeRange.from) {
      timeRange.to = null; // Invalid input, reset the "to" time
    }
    console.log("newTimes", newTimes);

    setTimes(newTimes);
  };
  console.log(times);
  const handleAddTime = (day) => {
    const lastTimeRange = times[day][times[day].length - 1];

    if (
      !lastTimeRange.from ||
      !lastTimeRange.to ||
      lastTimeRange.from >= lastTimeRange.to
    ) {
      // Don't add a new time range if the previous one is not fully filled or has an invalid time range
      return;
    }

    const newFrom = lastTimeRange.to; // Set the new "from" time as the previous "to" time

    setTimes((prevTimes) => ({
      ...prevTimes,
      [day]: [...prevTimes[day], { from: newFrom, to: null }],
    }));
  };

  const handleRemoveTime = (day, index) => {
    setTimes((prevTimes) => ({
      ...prevTimes,
      [day]: prevTimes[day].filter((item, i) => i !== index),
    }));
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {Object.keys(days).map((day) => (
          <div key={day}>
            <DayCheckbox
              day={day}
              checked={days[day]}
              onChange={() => handleCheckboxChange(day)}
              style={{ cursor: "pointer" }}
            />
            {!days[day] && <div style={{ opacity: "0.7" }}>Unavailable</div>}
            {days[day] && (
              <div>
                {times[day].map((time, index) => (
                  <div key={index}>
                    <div className="d-flex">
                      <label>
                        <TimePicker
                          value={time.from || ""}
                          onChange={(e) =>
                            handleTimeChange(day, index, "from", e)
                          }
                          className="mr-2"
                        />
                      </label>
                      <label>
                        <TimePicker
                          value={time.to || ""}
                          onChange={(e) =>
                            handleTimeChange(day, index, "to", e)
                          }
                        />
                      </label>

                      {times[day].length > 1 && (
                        <div style={{ display: "inline-block" }}>
                          <i
                            className="icon-trash"
                            onClick={() => handleRemoveTime(day, index)}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          ></i>
                        </div>
                      )}

                      {index === times[day].length - 1 && (
                        <i
                          className="icon-plus"
                          onClick={() => handleAddTime(day)}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        ></i>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <hr className=" " />
          </div>
        ))}
      </LocalizationProvider>
      <div>
        <button
          className="btn  style-btn coloring"
          type="submit"
          // onClick={() => {
          //   console.log("overrides data send to api", selectedDateRanges);
          // }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default WeekdaySelector;
