import React, { useEffect, useState } from "react";
import * as echarts from "echarts";

const SparkleCard = ({
  mainData,
  isRandomUpdate,
  Heading,
  Money,
  ContainerClass,
  index,
  chartColor,
}) => {
  const [updateData, setUpdateData] = useState(mainData);

  useEffect(() => {
    ploatData(mainData);

    setUpdateData({
      updateData: [...mainData],
    });
    chartPlace();
  }, []);

  let ploatData = (data) => {
    var reData = [];
    data.map(() => {
      reData.push(Math.floor(Math.random() * 10) + 1);
    });

    let timeoutID = setTimeout(() => {
      // ploatData(updateData);
      if (isRandomUpdate) {
        chartPlace();
      }
    }, 5000);

    setUpdateData([...reData]);
  };
  let chartPlace = () => {
    var chartDom = document.getElementById("main" + index);
    if (chartDom) {
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          formatter: function (value) {
            return value[0].data;
          },
        },
        grid: {
          left: 0,
          bottom: 0,
          top: 5,
          right: 0,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            animation: {
              duration: 100,
              easing: "cubicOut",
            },
            data: [0, 1, 2, 3, 4, 5],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            data: [0, 2, 4, 6, 8, 10],
          },
        ],
        series: [
          {
            type: "line",
            data: mainData ? updateData : [5, 6, 9, 2, 3, 6],
            areaStyle: {
              color: chartColor ? chartColor : "#f79647",
            },
            itemStyle: {
              color: chartColor ? chartColor : "#f79647",
            },
            symbolSize: 1,
          },
        ],
      };
      option && myChart.setOption(option);
    }
  };

  return (
    <div className={ContainerClass}>
      <div className="card overflowhidden number-chart">
        <div className="body">
          <div className="number">
            <h6>{Heading}</h6>
            <span>{Money}</span>
          </div>
        </div>
        <div
          id={"main" + index}
          className="sparkline"
          style={{ width: "100%", height: 55 }}
        ></div>
      </div>
    </div>
  );
};

export default SparkleCard;
