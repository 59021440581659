import "./index.scss";
import headerTable from "../../hooks/generic";

const TableList = ({ header, allData }) => {
  const tHeaderData = headerTable(header);

  return (
    <div className="table-list">
      <div className="table-responsive">
        <table className="table m-b-0 table-hover">
          <thead className="thead-light">
            <tr>{tHeaderData}</tr>
          </thead>
          <tbody>{allData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableList;
