import React, { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { findAllProducts } from "../../redux/Products/ProductsActions";
import { findAllUsers } from "../../redux/Auth/AuthActions";
import { createOrder } from "../../redux/Orders/OrdersActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import PagesLoading from "../../components/Loading/pagesLoading";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

const NewRequest = ({ closePopup }) => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  let users = useSelector((state) => state.auth.users?.users);
  let loading = useSelector((state) => state.auth.singleLoading);

  const products = useSelector((state) => state.products.products);

  const [currentPage] = useState(1);
  const [itemsPerPage] = useState();
  const [fetchedProducts, setFetchedProducts] = useState([]);

  const [reqQty, setReqQty] = useState(1);
  const [drivers, setDrivers] = useState([]);
  const [preparation, setPreparation] = useState([]);
  const [deliveryStation, setDeliveryStation] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [productLine, setProductLine] = useState([]);
  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .typeError("Quantity must be a number")
      .required("Quantity is required")
      .min(1, "Quantity must be greater than or equal to 1"),
  });

  const formik = useFormik({
    initialValues: {
      quantity: 1,
      selectedProduct: "",
      preparingOperator: "",
      driver: "",
      deliverystation: "",
      lines: "",
      priority: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        createOrder({
          productId: values.selectedProduct.code,
          productQuantity: values.quantity,
          productionLineId: values.lines.id,
          preperationOperatorId: values.preparingOperator.id,
          driverId: values.driver.id,
          deliveryStationId: values.deliverystation.id,
          token: token,
        })
      );
      closePopup();
    },
  });

  useEffect(() => {
    const fetchAllProducts = async () => {
      let currentPageNumber = currentPage;
      let allFetchedProducts = [...fetchedProducts];

      while (true) {
        const response = await dispatch(
          findAllProducts({
            token: token,
            page: currentPageNumber,
            limit: itemsPerPage,
          })
        );

        if (response?.products?.totalCount > 0) {
          allFetchedProducts = [...allFetchedProducts, ...response.products];
          currentPageNumber++;
          if (response.products.length < itemsPerPage) {
            break;
          }
        } else {
          break;
        }
      }

      setFetchedProducts(allFetchedProducts);
    };

    dispatch(
      findAllUsers({
        token: token,
      })
    );
    fetchAllProducts();
  }, [currentPage, token, itemsPerPage]);

  useEffect(() => {
    if (users) {
      setDrivers(users?.filter((user) => user.role === "driver"));
      setPreparation(
        users?.filter((user) => user.role === "preparation_operator")
      );
      setDeliveryStation(
        users?.filter((user) => user.role === "delivery_station")
      );
    }
  }, [users]);

  const productsSelects = products?.products?.map((originalObject) => ({
    name: originalObject.name,
    code: originalObject.id,
  }));

  useEffect(() => {
    if (selectedProduct) {
      let productData = products?.products?.filter(
        (item) => item.id == selectedProduct.code
      );
      let line = productData[0].productsProductionLines?.map((item) => {
        return item;
      });

      const lineSelects = line.map((item) => ({
        id: item.productionLine.id,
        name: item.productionLine.name,
        status: item.productionLine.status,
      }));
      setProductLine(lineSelects);
    }
  }, [selectedProduct]);

  let items = [];

  const productsList = productsSelects;
  function generateRandomKey() {
    return Math.floor(Math.random() * 1001);
  }

  for (let i = 0; i < reqQty; i++) {
    let itmkey = generateRandomKey();
    items.push(
      <div key={itmkey}>
        <div className="body" style={{ width: "100%" }}>
          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label">
                <Dropdown
                  inputId="products"
                  value={selectedProduct}
                  name="selectedProduct"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setSelectedProduct(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  options={productsList}
                  optionLabel="name"
                  style={{ width: "100%" }}
                />
                <label htmlFor="products">Select a product</label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label">
                <InputNumber
                  id="quantity"
                  value={formik.values.quantity}
                  onValueChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ width: "100%" }}
                />
                <label htmlFor="quantity">Choose quantity</label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label">
                <Dropdown
                  inputId="preparingOperator"
                  name="preparingOperator"
                  value={formik.values.preparingOperator}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  options={preparation}
                  optionLabel="displayName"
                  style={{ width: "100%" }}
                />
                <label htmlFor="preparingOperator">
                  Select Preparing operator
                </label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label ">
                <Dropdown
                  inputId="driver"
                  name="driver"
                  value={formik.values.driver}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={drivers}
                  optionLabel="displayName"
                  style={{ width: "100%" }}
                />
                <label htmlFor="driver">Select driver</label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label ">
                <Dropdown
                  inputId="deliverystation"
                  name="deliverystation"
                  value={formik.values.deliverystation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={deliveryStation}
                  optionLabel="displayName"
                  style={{ width: "100%" }}
                />
                <label htmlFor="deliverystation">
                  Select Delivery Station{" "}
                </label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label">
                <Dropdown
                  inputId="lines"
                  name="lines"
                  value={formik.values.lines}
                  onChange={formik.handleChange}
                  options={productLine}
                  optionLabel="name"
                  style={{ width: "100%" }}
                />
                <label htmlFor="lines">Select production line</label>
              </span>
            </div>
          </div>

          <div className="form-group">
            <div className="card flex justify-content-center mt-3 mb-0">
              <span className="p-float-label">
                <Dropdown
                  inputId="priority"
                  name="priority"
                  value={formik.values.priority}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={[
                    { priority: "High" },
                    { priority: "Normal" },
                    { priority: "Low" },
                  ]}
                  optionLabel="priority"
                  style={{ width: "100%" }}
                />
                <label htmlFor="lines">Select priority</label>
              </span>
            </div>
          </div>

          <div className="d-flex justify-content-center btn-flex pr-3 mb-3">
            <button
              className="btn  mr-5 style-btn color-btn"
              type="button"
              onClick={() => closePopup()}
            >
              Cancel
            </button>
            <button
              className="btn  style-btn coloring"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <PagesLoading />
      ) : (
        <>
          <PageTitle Title="Create Order" />
          <hr />

          <form onSubmit={formik.handleSubmit} style={{ zIndex: "1001" }}>
            {items}
          </form>
        </>
      )}
    </>
  );
};

export default NewRequest;
