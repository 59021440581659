import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getLineById ,editLine} from "../../../../redux/ProductionLines/ProductionLinesActions";
import PagesLoading from "../../../../components/Loading/pagesLoading";
import PageTitle from "../../../../components/PageTitle";
import { useTranslation } from "react-i18next";

const EditCategory = ({ closePopup, id }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  let token = useSelector((state) => state.auth?.data?.accessToken);
  const responseData = useSelector(
    (state) => state.productionLines?.responseData
  );

  const loading = useSelector((state) => state.productionLines?.Loading);

  const [lineName, setLineName] = useState("");
  const [stationName, setStationName] = useState("");
  const [stations, setStations] = useState([{}]);
  const [createStations, setCreateStations] = useState([]);
  const [deletedStations, setDeletedStations] = useState([]);

//   useEffect(() => {
//     dispatch(getLineById({ id: id, token: token }));
//   }, [dispatch, token]);

  useEffect(() => {
    if (responseData?.productionLine?.name) {
      setLineName(responseData?.productionLine?.name);
    }
  }, [responseData]);

  useEffect(() => {
    if (responseData?.productionLine?.stations) {
      setStations(responseData?.productionLine?.stations);
    }
  }, [responseData]);

  const addStation = () => {
    if (stationName?.trim() !== "") {
      const updatedStations = [...stations, { name: stationName?.trim() }];
      const newStation = { name: stationName.trim() };
      const updatedCreateStations = [...createStations, newStation];

      setCreateStations(updatedCreateStations);
      setStations(updatedStations);
      setStationName("");
    }
  };

  const deleteStation = (index) => {
    const deleted = stations[index];
    const updatedStations = [...stations];
    updatedStations.splice(index, 1);

    setStations(updatedStations);

    if (deleted?.id) {
      const updatedDeletedStations = [...deletedStations, deleted.id];
      setDeletedStations(updatedDeletedStations);
    }
    const updatedCreateStations = createStations.filter(
      (station) => station.name !== deleted.name
    );
    setCreateStations(updatedCreateStations);
  };
  const handleLineNameChange = (event) => {
    setLineName(event.target.value);
  };

  const handleStationNameChange = (event) => {
    setStationName(event.target.value);
  };

  const logLineAndStations = async () => {
    // await dispatch(
    //   editLine({
    //     name: lineName,
    //     deletedStations: deletedStations,
    //     createdStations: createStations,
    //     id: id,
    //     token: token,
    //   })
    // );

    resetState();
    closePopup();
  };
  const resetState = () => {
    setCreateStations([]);
    setDeletedStations([]);
  };
  return (
    <>
      {loading ? (
        <PagesLoading />
      ) : (
        <>
          <PageTitle Title={t("editCategory")} />
          <hr />
          <div>
            <div className="body">
              <div className="row clearfix mb-3">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder={t("category")}
                      type="text"
                      value={lineName}
                      onChange={handleLineNameChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                    
                    placeholder={t("defects")}
                      type="text"
                      value={stationName}
                      onChange={handleStationNameChange}
                    />
                  </div>
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn coloring mt-2"
                        type="button"
                        onClick={addStation}
                        disabled={!lineName || !stationName}
                      >
                        {t('addDefect')}
                      </button>
                    </div>
                  </div>
                </div>
                {lineName !== "" && (
                  <div className="px-3">
                    <strong>{lineName}:</strong>
                    {stations.length > 0 ? (
                      <ul
                        style={{
                          listStyle: "none",
                          opacity: "0.8",
                          width: "200px",
                        }}
                      >
                        {stations.map((station, index) => (
                          <li
                            key={index}
                            className="d-flex justify-content-between align-items-center "
                          >
                            {station?.name?.trim()}
                            <i
                              onClick={() => deleteStation(index)}
                              className="icon-trash "
                              style={{ color: "red" }}
                            ></i>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No category added yet.</p>
                    )}
                  </div>
                )}

                <div className="d-flex justify-content-center btn-flex pr-3 my-3">
                  <button
                    className="btn  mr-5 style-btn color-btn"
                    type="button"
                    onClick={() => {
                      resetState();
                      closePopup();
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="btn  style-btn coloring"
                    type="submit"
                    onClick={logLineAndStations}
                  >
                    {t("Edit")}
                  </button>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </>
      )}
    </>
  );
};

export default EditCategory;
