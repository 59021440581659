import React, { useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import BackButton from "../../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import Defects from "./Defects";
import Components from "./Components";
// import { getRequestByID } from "../../redux/Requests/Requests.Actions";
import PagesLoading from "../../../components/Loading/pagesLoading";
import setTitle from "../../../hooks/Title";

const CategoryDetails = () => {
    const dispatch = useDispatch();

    let id = useParams();
  
    let token = useSelector((state) => state.auth.data?.accessToken);
    // const data = useSelector((state) => state.requests?.responseData?.request);
    const Loading = useSelector((state) => state.requests?.Loading);
  
    const [tab, setTab] = useState(false);
  
    useEffect(() => {
      setTitle("Category Details");
    }, []);
  
    // useEffect(() => {
    //   if (data.length === 0) {
    //     navigate("/*");
    //   }
    // }, [data]);
  
    const data = {
        "message": "Fetched request.",
        "request": {
            "id": 7264,
            "orderId": 49,
            "currentUserId": 3,
            "secondaryUserId": null,
            "status": "planned",
            "accountId": 1,
            "valid": true,
            "scheduleId": 29,
            "createdAt": "2023-10-02T12:19:00.000Z",
            "requestsComponents": [
                {
                    "id": 20609,
                    "requestId": 7264,
                    "componentId": 85,
                    "packageType": "box",
                    "componentQuantity": 0,
                    "createdAt": "2023-10-02T11:29:35.000Z",
                    "component": {
                        "code": "100032028",
                        "name": "خرطوم الصرف",
                        "componentLineStations": [
                            {
                                "lineId": 9,
                                "station": 36,
                                "deliveryStation": {
                                    "name": "مرحلة  التغليف"
                                },
                                "productionLine": {
                                    "name": "خط خارجي 1"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 20610,
                    "requestId": 7264,
                    "componentId": 86,
                    "packageType": "box",
                    "componentQuantity": 0,
                    "createdAt": "2023-10-02T11:29:35.000Z",
                    "component": {
                        "code": "100009891",
                        "name": "مواسير توصيل1/2",
                        "componentLineStations": [
                            {
                                "lineId": 9,
                                "station": 36,
                                "deliveryStation": {
                                    "name": "مرحلة  التغليف"
                                },
                                "productionLine": {
                                    "name": "خط خارجي 1"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 20611,
                    "requestId": 7264,
                    "componentId": 87,
                    "packageType": "box",
                    "componentQuantity": 0,
                    "createdAt": "2023-10-02T11:29:35.000Z",
                    "component": {
                        "code": "100009889",
                        "name": "مواسير توصيل1/4",
                        "componentLineStations": [
                            {
                                "lineId": 9,
                                "station": 36,
                                "deliveryStation": {
                                    "name": "مرحلة  التغليف"
                                },
                                "productionLine": {
                                    "name": "خط خارجي 1"
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }   
  
    const header = ["#", "ID", "Description"];
  
    const historyHeader = ["#", "Code", "Description"];
  return (
    <>
    <div id="main-content">
      <BackButton />
      <PageTitle Title="Category Details" />

      {Loading ? (
        <PagesLoading />
      ) : (
        <>
          <div>
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="header">
                    <h2>Single Category</h2>
                  </div>
                  <div className="body">
                    <h3>
                      Category Details :{" "}
                      <strong className="text-primary">{data?.componentId}</strong>
                    </h3>
                    <ul className="nav nav-tabs-new2">
                      <li className="nav-item inlineblock">
                        <Link
                          className={`nav-link ${!tab && "active"}`}
                          fragment="Defects"
                          onClick={() => {
                            setTab(false);
                          }}
                        >
                          Defects
                        </Link>
                      </li>
                      <li className="nav-item inlineblock">
                    <Link
                      className={`nav-link`}
                      fragment="Components"
                      onClick={() => {
                        setTab(true);
                      }}
                    >
                      Components
                    </Link>
                  </li>
                    </ul>
                    <div className="tab-content">
                      <div className={`tab-pane ${!tab && "active"}`}>
                        <Defects
                          createdAt={data?.createdAt}
                          header={header}
                          data={data?.request?.requestsComponents}
                          status={data?.status}
                        />
                      </div>
                      <div className={`tab-pane ${tab && "active"}`}>
                        <Components
                        //   createdAt={data?.request?.requestsComponents?.createdAt}
                          data={data?.request?.requestsComponents}
                          historyHeader={historyHeader}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  </>
  )
}

export default CategoryDetails