import Logo from "../../assets/images/logo.png";
import "./index.scss";
import { Link } from "react-router-dom";
import NutificationDropdown from "./NutificationDropdown";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../redux/Auth/AuthSlice";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { setItem, getItem } from "../../utils/storage.js/storage";
import socket from "../../utils/webSoket/webSocket";

const NavBar = () => {
  const [t, i18n] = useTranslation();
  let token = useSelector((state) => state.auth.data?.accessToken);

  const storedLanguage = getItem("selectedLanguage");

  useEffect(() => {
    socket.emit("login", token);
  }, []);

  const defaultLanguage = storedLanguage
    ? storedLanguage
    : { name: "English", code: "en" };

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  useEffect(() => {
    setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (!selectedLanguage === defaultLanguage) {
      setItem("selectedLanguage", selectedLanguage);
    }
    i18n.changeLanguage(selectedLanguage.code);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.code);
  }, [selectedLanguage]);

  const languages = [
    { name: "English", code: "en" },
    { name: "عربي", code: "ar" },
  ];

  const dispatch = useDispatch();

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <>
      <nav className="navbar navbar-fixed-top px-3 ">
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <div className="space mr-3"></div>
            <div className="navbar-brand">
              <Link to="/" className="d-flex align-items-end">
                <img
                  src={Logo}
                  alt="Lucid Logo"
                  className="img-responsive logo"
                />

                <h6
                  className="my-0"
                  style={{ color: "black", marginLeft: "10px", opacity: "0.7" }}
                >
                  Beta
                </h6>
              </Link>
            </div>
          </div>
          <div className="d-flex between">
            <div className="d-flex align-items-center  gap-3">
              <i className="icon-globe"></i>
              <div className="langauge">
                <Dropdown
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e.value);
                    i18n.changeLanguage(e.value.code);
                  }}
                  options={languages}
                  optionLabel="name"
                  placeholder="Select a languages"
                  className="w-full md:w-14rem"
                />
              </div>
            </div>

            <div
              data-tooltip-id="my-tooltip"
              data-tooltip-content={t("fullscreen")}
              style={{ cursor: "pointer" }}
            >
              <Link
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="icon-size-fullscreen"></i>
              </Link>
            </div>

            <NutificationDropdown />

            <div
              data-tooltip-id="my-tooltip"
              data-tooltip-content={t("logout")}
              className="navbar-right text-right mr-2"
              onClick={() => {
                dispatch(authAction.logOut());
              }}
            >
              <Link to="/login" className="icon-menu">
                <i className="icon-login"></i>
              </Link>
            </div>
          </div>
          <Tooltip id="my-tooltip" />
        </div>
      </nav>
    </>
  );
};

export default NavBar;
