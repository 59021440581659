import React from "react";
import PageTitle from "../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { deleteComponentById } from "../../redux/Components/ComponentsActions";
import { useTranslation } from "react-i18next";

const DeleteComponent = ({ closePopup, id }) => {
  const [t] = useTranslation();

  let token = useSelector((state) => state.auth.data.accessToken);
  const dispatch = useDispatch();
  const deleteComponent = () => {
    dispatch(
      deleteComponentById({
        id: id,
        token: token,
      })
    );
    closePopup();
  };

  return (
    <>
      <div
        className="flex-header"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <PageTitle Title={t("deleteComponent")} />
      </div>

      <div>
        <div className="body">
          <div className="text-center mb-3">
            <p>{t("deleteComponentDescription")}</p>
          </div>

          <div className="d-flex justify-content-center btn-flex pr-3 mb-3">
            <button
              className="btn  mr-5 style-btn color-btn"
              type="button"
              onClick={() => closePopup()}
            >
              {t("cancel")}
            </button>
            <button
              className="btn  style-btn coloring"
              type="submit"
              onClick={deleteComponent}
            >
              {t("delete")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteComponent;
