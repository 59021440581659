import React, { useState } from "react";
import Task from "./Task";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

const Column = ({ title, tasks, onTaskMove, onTaskDelete }) => {
  const [t] = useTranslation();

  const convertStatus = (e) => {
    switch (e) {
      case "delivered":
      case "تم التوصيل":
        return "delivered";
      case "planned":
      case "مخطط":
        return "planned";
      case "on train":
      case "على القطار":
        return "on_train";
      case "in preparation":
      case "قيد التحضير":
        return "in_preparation";
      case "ready":
      case "مستعد":
        return "ready";
      default:
        return null;
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: "TASK",
    drop: (item) => {
      onTaskMove(item.id, convertStatus(title));
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [loadedTasksCount, setLoadedCardCount] = useState(5);

  const handleLoadMore = () => {
    setLoadedCardCount(loadedTasksCount + 5);
  };

  let tasksBlocks = tasks?.slice(0, loadedTasksCount);
  return (
    <div
      className={`column ${isOver ? "column-dragging-over" : ""}`}
      ref={drop}
    >
      <div className="column-header">
        <h3 className="column-title">{title}</h3>
      </div>
      <div className="column-tasks">
        {tasksBlocks?.map((task) => (
          <Task
            key={task.id}
            id={task.id}
            task={task}
            onTaskDelete={onTaskDelete}
            onTaskMove={onTaskMove}
          />
        ))}
      </div>
      {tasks?.length > loadedTasksCount ? (
        <h6
          onClick={handleLoadMore}
          className="load-more-button text-center"
          style={{ cursor: "pointer" }}
        >
          {t("loadMore")}
        </h6>
      ) : (
        ""
      )}
    </div>
  );
};

export default Column;
