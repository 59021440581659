import PageTitle from "../../components/PageTitle";
import { useState, useEffect } from "react";
import React from "react";
import SparkleCard from "../Dashboard/Charts/SparkleCard";
import Data from "../RequestList/Data";
import setTitle from "../../hooks/Title";

const PreparationRequests = ({ planned, inPreparation, ready }) => {
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [cardData, setCardData] = useState([]);

  const sparkleCardData = [
    {
      heading: "Planned",
      money: planned,
      sparklineData: {
        type: "line",
        data: [1, 4, 1, 3, 7, 1],
        areaStyle: {
          color: "#6c757d",
        },
        symbolSize: 1,
      },
    },
    {
      heading: "In Preparation",
      money: inPreparation,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "#007bff",
        },

        symbolSize: 1,
      },
    },
    {
      heading: "R eady",
      money: ready,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 1, 5],
        areaStyle: {
          color: "#17a2b8",
        },

        symbolSize: 1,
      },
    },
  ];

  useEffect(() => {
    setTitle("Preparation Operator");

    setCardData([...sparkleCardData]);
  }, []);

  return (
    <div id="main-content">
      <div className="row clearfix">
        {cardData.map((data, i) => (
          <SparkleCard
            index={i}
            key={data.heading}
            Heading={data.heading}
            Money={data.money}
            isRandomUpdate={true}
            mainData={data.sparklineData.data}
            chartColor={data.sparklineData.areaStyle.color}
            ContainerClass="col-lg-4 col-md-4 col-sm-4"
          />
        ))}
      </div>
      <div className="flex-header">
        <PageTitle Title="Preparation Requests" />
        <div className="btn-style">
          <div
            className="btn btn-outline-secondary"
            onClick={() => setFilterVisabilty(!filterVisabilty)}
          >
            <i className="icon-equalizer plus"></i>
          </div>
        </div>
      </div>
      {filterVisabilty && (
        <div className="card">
          <div className="inputs-search">
            <div className="">
              <input
                className="form-control"
                placeholder="Request code "
                type="number"
              />
            </div>

            <div className="">
              <select
                name="status"
                defaultValue="status"
                className="form-control"
              >
                <option disabled value="status">
                  status
                </option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            <div className="">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="icon-calendar"></i>
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control"
                  data-date-autoclose="true"
                  data-provide="datepicker"
                  placeholder="Birthdate"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Data />
    </div>
  );
};

export default PreparationRequests;
