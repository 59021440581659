import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";

export const CreateLine = createAsyncThunk(
  "productionLines/createLine",
  async ({ name, status, stations, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.createLine,
        { name, status, stations },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findAllLines = createAsyncThunk(
  "productionLines/findAllLines",
  async (
    { page, limit, name, deliveryStationName, token },
    { rejectWithValue }
  ) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (name) params.name = name;
      if (deliveryStationName) params.deliveryStationName = deliveryStationName;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(Api.findAllLines, config);
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteLineById = createAsyncThunk(
  "productionLines/deleteLineById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.delete(
        `${Api.deleteLineById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLineById = createAsyncThunk(
  "auth/getLineById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.get(
        `${Api.getLineById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editLine = createAsyncThunk(
  "productionLines/editLine",
  async (
    { name, status, deletedStations, createdStations, id, token },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const filterEmptyFields = (obj) => {
        const filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== "" && obj[key] !== null) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      };

      const dataToUpdate = filterEmptyFields({
        name,
        status,
        deletedStations,
        createdStations,
      });

      const response = await axiosInstance.patch(
        `${Api.editLine}/${id}`,
        dataToUpdate,
        config
      );

      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
