import { createSlice } from "@reduxjs/toolkit";
import {
  signInUser,
  ChangePasswordSignIn,
  findAllUsers,
  deleteUser,
  getUserById,
  updateUser,
  AdminUpdateUser,
  verifyEmail,
  updateUserphoto,
} from "./AuthActions";
import { setItem, getItem, clear } from "../../utils/storage.js/storage";
import { encryptData, decryptData } from "../../utils/security/securty";
import socket from "../../utils/webSoket/webSocket";

const localData = getItem("user");

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    data: localData
      ? decryptData(localData, process.env.REACT_APP_SECRET_KEY)
      : null,
    isLoading: false,
    users: null,
    responseData: null,
    error: null,
    success: false,
    singleLoading: false,
  },
  reducers: {
    logOut: (state) => {
      socket.emit("logout", state.data?.accessToken);
      clear();
      state.data = null;
      state.isLoading = false;
      state.responseData = null;
      state.users = null;
      state.error = null;
      state.success = false;
      state.singleLoading = false;
    },
    loadingOff: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.success = true;
        const encrepted = encryptData(
          action.payload,
          process.env.REACT_APP_SECRET_KEY
        );
        setItem("user", encrepted);
        setItem("isAuth", true);
        // state.isLoading = false;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.success = false;
      })

      .addCase(ChangePasswordSignIn.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(ChangePasswordSignIn.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.success = true;
      })
      .addCase(ChangePasswordSignIn.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.error.message;
        state.success = false;
      })
      .addCase(findAllUsers.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(findAllUsers.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.users = action.payload;
      })
      .addCase(findAllUsers.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.singleLoading = false;
        // state.responseData = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(getUserById.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })

      .addCase(AdminUpdateUser.pending, (state) => {
        state.singleLoading = true;
        state.error = null;
      })
      .addCase(AdminUpdateUser.fulfilled, (state, action) => {
        state.singleLoading = false;
        state.responseData = action.payload;
      })
      .addCase(AdminUpdateUser.rejected, (state, action) => {
        state.singleLoading = false;
        state.error = action.payload;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.responseData = action.payload;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    // .addCase(updateUserphoto.pending, (state) => {
    //   state.isLoading = true;
    //   state.error = null;
    // })
    // .addCase(updateUserphoto.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.responseData = action.payload;
    // })
    // .addCase(updateUserphoto.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // })
  },
});

export const authAction = AuthSlice.actions;
export default AuthSlice;
