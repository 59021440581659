import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";
import Column from "./Column";
import { useSelector, useDispatch } from "react-redux";
import { editRequest } from "../../redux/Requests/Requests.Actions";
import { useTranslation } from "react-i18next";

const Kanban = ({ tasksList, getTasks, role }) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const [tasks, setTasks] = useState([]);

  let token = useSelector((state) => state.auth.data?.accessToken);

  let error = useSelector((state) => state.requests?.error);

  useEffect(() => {
    if (tasksList) {
      setTasks(tasksList);
    }
  }, [tasksList]);

  const handleTaskMove = async (taskId, newStatus) => {
    console.log(newStatus);
    const taskToMove = tasks.find((task) => task.id === taskId);
    if (!taskToMove) return;

    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, status: newStatus };
      }
      return task;
    });
    setTasks(updatedTasks);

    await dispatch(
      editRequest({
        id: taskId,
        status: newStatus,
        token: token,
      })
    );
  };

  useEffect(() => {
    if (error) {
      getTasks();
      error = null;
    }
  }, [error]);

  const handleTaskCreate = (title, status) => {
    const newTask = { id: uuidv4(), title, status };
    setTasks([...tasks, newTask]);
  };

  const handleTaskDelete = (taskId) => {
    const updatedTasks = tasks?.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="kanban-board">
          <div className="columns-container" style={{ display: "flex" }}>
            {role === "client_admin" && (
              <>
                <Column
                  title={t("planned")}
                  tasks={tasks?.filter((task) => task.status === "planned")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("in_preparation")}
                  tasks={tasks?.filter(
                    (task) => task.status === "in_preparation"
                  )}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("ready")}
                  tasks={tasks?.filter((task) => task.status === "ready")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("on_train")}
                  tasks={tasks?.filter((task) => task.status === "on_train")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("delivered")}
                  tasks={tasks?.filter((task) => task.status === "delivered")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
              </>
            )}

            {role === "preparation_operator" && (
              <>
                <Column
                  title={t("planned")}
                  tasks={tasks?.filter((task) => task.status === "planned")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("in_preparation")}
                  tasks={tasks?.filter(
                    (task) => task.status === "in_preparation"
                  )}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("ready")}
                  tasks={tasks?.filter((task) => task.status === "ready")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
              </>
            )}

            {role === "driver" && (
              <>
                <Column
                  title={t("ready")}
                  tasks={tasks?.filter((task) => task.status === "ready")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("on_train")}
                  tasks={tasks?.filter((task) => task.status === "on_train")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("delivered")}
                  tasks={tasks?.filter((task) => task.status === "delivered")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
              </>
            )}

            {role === "delivery_station" && (
              <>
                <Column
                  title={t("on_train")}
                  tasks={tasks?.filter((task) => task.status === "on_train")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
                <Column
                  title={t("delivered")}
                  tasks={tasks?.filter((task) => task.status === "delivered")}
                  onTaskMove={handleTaskMove}
                  onTaskCreate={handleTaskCreate}
                  onTaskDelete={handleTaskDelete}
                  initialLoadCount={5}
                />
              </>
            )}
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default Kanban;
