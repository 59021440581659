import React, { useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import PageTitle from "./PageTitle";
import { useTranslation } from "react-i18next";

const UploadFile = ({ closePopup }) => {
  const [t] = useTranslation();

  const [filePaths, setFilePaths] = useState([]);
  const deleteFilePath = (filePath) => {
    setFilePaths(filePaths.filter((path) => path !== filePath));
  };
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#49c5b6",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#4f46e5",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop: (droppedFiles) => {
        const newFilePaths = droppedFiles.map((file) => file.path);
        setFilePaths([...filePaths, ...newFilePaths]);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = filePaths.map((filePath) => (
    <li
      className="font-size-13 d-flex align-items-center gap-2"
      key={filePath}
      style={{ listStyle: "none", width: "100%" }}
    >
      <i className="bx bx-file" style={{ color: "#4f46e5" }}></i>

      <div className="d-flex justify-content-between" style={{ width: "95%" }}>
        <p style={{ width: "80%" }} className="m-0">
          {filePath}
        </p>

        <i
          onClick={() => deleteFilePath(filePath)}
          className="bx bx-trash"
          style={{ color: "" }}
        ></i>
      </div>
      {/* Delete button */}
    </li>
  ));
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle Title={t("uploadFile")} />
        <div className="btn-style" style={{ height: "35px" }}>
          <div className="btn btn-outline-secondary">
            <i class="fa fa-download"></i>
          </div>
        </div>
      </div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="m-0 p-2">{t("dragDropContent")}</p>
      </div>
      <ul style={{ padding: "0", marginTop: "15px", opacity: "0.7" }}>
        {acceptedFileItems}
      </ul>
      <div className="d-flex justify-content-center btn-flex pr-3 mb-3">
        <button
          className="btn  mr-5 style-btn color-btn"
          type="button"
          onClick={() => closePopup()}
        >
          {t("cancel")}
        </button>
        <button className="btn  style-btn coloring" type="submit">
          {t("upload")}
        </button>
      </div>
    </div>
  );
};
export default UploadFile;
