import BackButton from "../../components/Buttons/backButton";
import PageTitle from "../../components/PageTitle";

import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

const MPM = () => {
  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [representatives] = useState([
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Anna Fali", image: "annafali.png" },
    { name: "Asiya Javayant", image: "asiyajavayant.png" },
    { name: "Bernardo Dominic", image: "bernardodominic.png" },
    { name: "Elwin Sharvill", image: "elwinsharvill.png" },
    { name: "Ioni Bowcher", image: "ionibowcher.png" },
    { name: "Ivan Magalhaes", image: "ivanmagalhaes.png" },
    { name: "Onyama Limba", image: "onyamalimba.png" },
    { name: "Stephen Shaw", image: "stephenshaw.png" },
    { name: "XuXue Feng", image: "xuxuefeng.png" },
  ]);

  let data = [
    {
      id: 1000,
      name: "James Butt",
      country: {
        name: "Algeria",
        code: "dz",
      },
      company: "Benton, John B Jr",
      date: "2015-09-13",
      status: "unqualified",
      verified: true,
      activity: 17,
      representative: {
        name: "Ioni Bowcher",
        image: "ionibowcher.png",
      },
      balance: 70663,
    },
    {
      id: 1001,
      name: "Josephine Darakjy",
      country: {
        name: "Egypt",
        code: "eg",
      },
      company: "Chanay, Jeffrey A Esq",
      date: "2019-02-09",
      status: "proposal",
      verified: true,
      activity: 0,
      representative: {
        name: "Amy Elsner",
        image: "amyelsner.png",
      },
      balance: 82429,
    },
    {
      id: 1002,
      name: "Art Venere",
      country: {
        name: "Panama",
        code: "pa",
      },
      company: "Chemel, James L Cpa",
      date: "2017-05-13",
      status: "qualified",
      verified: false,
      activity: 63,
      representative: {
        name: "Asiya Javayant",
        image: "asiyajavayant.png",
      },
      balance: 28334,
    },
    {
      id: 1003,
      name: "Lenna Paprocki",
      country: {
        name: "Slovenia",
        code: "si",
      },
      company: "Feltz Printing Service",
      date: "2020-09-15",
      status: "new",
      verified: false,
      activity: 37,
      representative: {
        name: "Xuxue Feng",
        image: "xuxuefeng.png",
      },
      balance: 88521,
    },
    {
      id: 1004,
      name: "Donette Foller",
      country: {
        name: "South Africa",
        code: "za",
      },
      company: "Printing Dimensions",
      date: "2016-05-20",
      status: "proposal",
      verified: true,
      activity: 33,
      representative: {
        name: "Asiya Javayant",
        image: "asiyajavayant.png",
      },
      balance: 93905,
    },
    {
      id: 1005,
      name: "Simona Morasca",
      country: {
        name: "Egypt",
        code: "eg",
      },
      company: "Chapman, Ross E Esq",
      date: "2018-02-16",
      status: "qualified",
      verified: false,
      activity: 68,
      representative: {
        name: "Ivan Magalhaes",
        image: "ivanmagalhaes.png",
      },
      balance: 50041,
    },
    {
      id: 1006,
      name: "Mitsue Tollner",
      country: {
        name: "Paraguay",
        code: "py",
      },
      company: "Morlong Associates",
      date: "2018-02-19",
      status: "renewal",
      verified: true,
      activity: 54,
      representative: {
        name: "Ivan Magalhaes",
        image: "ivanmagalhaes.png",
      },
      balance: 58706,
    },
    {
      id: 1007,
      name: "Leota Dilliard",
      country: {
        name: "Serbia",
        code: "rs",
      },
      company: "Commercial Press",
      date: "2019-08-13",
      status: "renewal",
      verified: true,
      activity: 69,
      representative: {
        name: "Onyama Limba",
        image: "onyamalimba.png",
      },
      balance: 26640,
    },
    {
      id: 1008,
      name: "Sage Wieser",
      country: {
        name: "Egypt",
        code: "eg",
      },
      company: "Truhlar And Truhlar Attys",
      date: "2018-11-21",
      status: "unqualified",
      verified: true,
      activity: 76,
      representative: {
        name: "Ivan Magalhaes",
        image: "ivanmagalhaes.png",
      },
      balance: 65369,
    },
    {
      id: 1009,
      name: "Kris Marrier",
      country: {
        name: "Mexico",
        code: "mx",
      },
      company: "King, Christopher A Esq",
      date: "2015-07-07",
      status: "proposal",
      verified: false,
      activity: 3,
      representative: {
        name: "Onyama Limba",
        image: "onyamalimba.png",
      },
      balance: 63451,
    },
    {
      id: 1010,
      name: "Minna Amigon",
      country: {
        name: "Romania",
        code: "ro",
      },
      company: "Dorl, James J Esq",
      date: "2018-11-07",
      status: "qualified",
      verified: false,
      activity: 38,
      representative: {
        name: "Anna Fali",
        image: "annafali.png",
      },
      balance: 71169,
    },
    {
      id: 1011,
      name: "Abel Maclead",
      country: {
        name: "Singapore",
        code: "sg",
      },
      company: "Rangoni Of Florence",
      date: "2017-03-11",
      status: "qualified",
      verified: true,
      activity: 87,
      representative: {
        name: "Bernardo Dominic",
        image: "bernardodominic.png",
      },
      balance: 96842,
    },
  ];

  console.log(data);

  useEffect(() => {
    setDataInfo(data);
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      representative: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          outlined
          onClick={clearFilter}
        />
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  const representativeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
      />
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`}
          width="32"
        />
        <span>{option.name}</span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div id="main-content">
      <BackButton />
      <PageTitle Title="MPM" />
      <div className="card">
        <DataTable
          value={dataInfo}
          showGridlines
          loading={loading}
          dataKey="id"
          filters={filters}
          header={header}
          emptyMessage="No data found."
        >
        
          <Column
            field="name"
            header="Name"
            filter
            filterPlaceholder="Search by name"
            style={{ minWidth: "12rem" }}
            showAddButton={false}
            showApplyButton={false}
            showClearButton={false}
            showFilterMenuOptions={false}
          />
          <Column
            header="Agent"
            field="representative"
            showFilterMatchModes={false}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "14rem" }}
            body={representativeBodyTemplate}
            filterPlaceholder="Search by name"
            filter
            // filterElement={representativeFilterTemplate}
            // showFilterMenuOptions={false}
            // showApplyButton={false}
            // showClearButton={false}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default MPM;
