import "./index.scss";
import Logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { setItem } from "../../../utils/storage.js/storage";
const ForgetPass = () => {
  const navigate = useNavigate();

  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [formErr, setFormErr] = useState(false);

  let handleSubmit = (e) => {
    e.preventDefault();

    if (email === "") {
      setFormErr(true);
    } else {
      setFormErr(false);
      navigate("/login");
      setItem("user", { userEmail: email });
    }
  };

  const emailHandler = (e) => {
    let userMail = e.target.value;
    let userMailvalid = emailRegex.test(userMail);
    if (userMail === "" || userMailvalid === false) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
      setEmail(userMail);
    }
  };

  return (
    <>
      <div className="theme-cyan">
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Recover my password</p>
                  </div>

                  <div className="body">
                    <p>
                      Please enter your email address below to receive
                      instructions for resetting password.
                    </p>
                    <form
                      className="form-auth-small ng-untouched ng-pristine ng-valid"
                      onSubmit={handleSubmit}
                    >
                      {formErr && (
                        <p className="validateErr">please fill all fields </p>
                      )}

                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>

                        <input
                          className="form-control"
                          id="signup-email"
                          placeholder="Your email"
                          type="text"
                          onBlur={emailHandler}
                        />

                        {emailErr && (
                          <p className="validateErr">
                            require format must be like this form user@gmail.com
                          </p>
                        )}
                      </div>

                      <button
                        className="btn btn-primary btn-lg btn-block primary-btn-style"
                        type="submit"
                      >
                        RESET PASSWORD
                      </button>
                      <div className="bottom">
                        <span className="helper-text">
                          Know your password? <Link to="/login">Login</Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPass;
