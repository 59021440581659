import React, { useState } from "react";
import { useDrag } from "react-dnd";
import UserImage from "../../assets/images/user.png";
import { Link } from "react-router-dom";
import { badgePriorty } from "../../hooks/generic";
import convertTimestampToDateTime from "../../hooks/timeStampConverter";
import { useTranslation } from "react-i18next";

const Task = ({ id, task, onTaskMove }) => {
  const [t] = useTranslation();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TASK", // Define the type property
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [isPrevConfirmed, setIsPrevConfirmed] = useState(false);
  const [isNextConfirmed, setIsNextConfirmed] = useState(false);

  const handleDelete = () => {
    onTaskMove(id, "Canceled");
  };
  const handleMoveToNextColumn = () => {
    const currentStatus = task.status.trim();

    const columnOrder = [
      "planned",
      "in_preparation",
      "ready",
      "on_train",
      "delivered",
    ];
    const currentIndex = columnOrder.indexOf(currentStatus);

    if (currentIndex < columnOrder.length - 1) {
      const newStatus = columnOrder[currentIndex + 1];
      onTaskMove(id, newStatus);
    }
  };

  const handleMoveToPreviousColumn = () => {
    const currentStatus = task.status;
    const columnOrder = [
      "planned",
      "in_preparation",
      "Ready",
      "On_Train",
      "delivered",
    ];
    const currentIndex = columnOrder.indexOf(currentStatus);

    if (currentIndex > 0) {
      const newStatus = columnOrder[currentIndex - 1];
      onTaskMove(id, newStatus);
    }
  };

  const { dateVariable, timeVariable } = convertTimestampToDateTime(
    task?.createdAt
  );
  return (
    <div className={`task ${isDragging ? "task-dragging" : ""}`} ref={drag}>
      <div className="task-content">
        <div className="d-flex  justify-content-between align-items-center">
          {" "}
          <h6 className="m-0">{id}</h6>
          <div>
            {/* {isPrevConfirmed ? (
              <button
                className="btn btn-outline-secondary mr-2"
                onClick={handleMoveToPreviousColumn}
                style={{ fontSize: "10px" }}
              >
                Confirm
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary mr-2"
                onClick={() => {
                  setIsPrevConfirmed(true);
                  setIsNextConfirmed(false);
                }}
                style={{ fontSize: "10px" }}
              >
                <i class="fa fa-chevron-left"></i>
              </button>
            )} */}

            {isNextConfirmed ? (
              <button
                className="btn btn-outline-secondary "
                onClick={handleMoveToNextColumn}
                style={{ fontSize: "10px" }}
              >
                {t("confirm")}
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary "
                onClick={() => {
                  setIsNextConfirmed(true);
                  setIsPrevConfirmed(false);
                }}
                style={{ fontSize: "10px" }}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
        <hr className="my-2" />
        <h6 className="m-0">{task?.order?.product?.name}</h6>
        <small>
          {t("Created")} {dateVariable}
        </small>

        <div>{badgePriorty(task?.priorty)}</div>

        <p className="m-0 d-flex">
          {t("timee")} <span>{timeVariable}</span>
        </p>
        <p className="m-0">
          {t("linee")} {task?.order?.productionLine?.name}
        </p>

        <div className="d-flex justify-content-between align-items-end">
          <div className="d-flex align-items-center ">
            <p className="m-0" style={{ fontSize: "10px" }}>
              {t("team")}
            </p>
            <ul className="list-unstyled team-info mb-0  ">
              <li>
                <Link
                  to={`/admin/profile-users/${task?.preperationOperatorId}`}
                >
                  <img alt="Avatar" src={UserImage} />
                </Link>
              </li>
              <li>
                <Link to={`/admin/profile-users/${task?.driverId}`}>
                  <img alt="Avatar" src={UserImage} />
                </Link>
              </li>
              <li>
                <Link to={`/admin/profile-users/${task?.driverId}`}>
                  <img alt="Avatar" src={UserImage} />
                </Link>
              </li>
            </ul>
          </div>

          <div className="project-actions ">
            <Link
              to={`/requestDetails/${task?.id}`}
              className="btn btn-outline-secondary "
            >
              <i className="icon-eye"></i>
            </Link>
            {/* <Link
              to={`requestDetails/${task?.id}`}
              className="btn btn-outline-secondary"
            >
              <i className="icon-pencil"></i>
            </Link> */}
            {/* {task?.status !== "Canceled" && ( // Check if the status is not "Canceled"
              <Link
                className="btn btn-outline-danger danger"
                onClick={handleDelete}
              >
                <i className="icon-trash"></i>
              </Link>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
