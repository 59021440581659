import { io } from "socket.io-client";

const socket = io(
  "https://api.tobby.co",
  {
    cors: {
      origin: "*",
    },
  },
  { transports: ["websocket"] }
);

export default socket;
