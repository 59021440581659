import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import TablePagination from "../../../../../components/Pagination";
const ClientUsers = () => {
  const [t] = useTranslation();
  const [state, setState] = useState({
    disabled: true,
    firstData: false,
    dataInfo: [],
    filters: null,
    Accounts: [
      { name: "All", code: "NY" },
      { name: "6 Octuber", code: "NY" },
      { name: "Badr", code: "RM" },
      { name: "Fifth Sattlment", code: "LDN" },
    ],
    selectedAccount: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  
  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  const initFilters = () => {
    setState({
      ...state,
      filters: {
        code: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        components: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        qty: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    });
  };

  const elementAction = (rowData) => {
    return (
      <div className="project-actions">
        <Link
          to={`/admin/profile-users/${rowData.id}`}
          className="btn btn-outline-secondary mr-1"
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-danger danger"
          // onClick={() => handleDeleteUser(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </div>
    );
  };

  const picture = (rowData) => {
    return (
      <ul className="list-unstyled team-info">
        <li>
          <img
            alt="Avatar"
            src="https://media.istockphoto.com/id/1143031708/photo/its-the-face-of-corporate-success.jpg?s=612x612&w=0&k=20&c=9jKMUVGpWBJpQYC0wevIKpC-dZ3zp3jtbebtfSZ5gwk="
          />
        </li>
      </ul>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between">
        <Dropdown
          value={state.selectedAccount}
          onChange={(e) =>
            setState({
              ...state,
              selectedAccount: e.value,
            })
          }
          options={state.Accounts}
          optionLabel="name"
          placeholder="Select an Account"
          style={{ minWidth: "100px" }}
        />
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            textAlign: "end",
            opacity: state.disabled ? "0.2" : "0.6",
          }}
          disabled={state.disabled}
          onClick={() => {
            // dispatch(
            //   getProductById({
            //     id: productID,
            //     token: token,
            //     page: currentPage,
            //     limit: itemsPerPage,
            //   })
            // );
            // setCode("");
            // setComponentName("");
            setState({ ...state, disabled: true });
          }}
        />
      </div>
    );
  };

  let data = [
    {
      id: 1,
      hrCode: 1234123,
      displayName: "mohamed",
      role: "preparation operator",
      email: "test@gmail.com",
      status: "Verified",
    },
  ];
  const headers = renderHeader();
  return (
    <div>
      <DataTable
        value={data}
        // loading={Loading}
        loadingoverlay={null}
        // loadingIcon={<PagesLoading />}
        dataKey="id"
        filters={state.filters}
        header={headers}
        emptyMessage={t("noDataFound")}
        className="p-0 m-0"
      >
        <Column
          header={t("hr-code")}
          field="hrCode"
          filter
          // filterElement={codeFilterTemplate}
          style={{ minWidth: "9rem" }}
          showAddButton={false}
          showApplyButton={false}
          showClearButton={false}
          showFilterMenuOptions={false}
          // body={hrCodeUsers}
        />
        <Column
          field="displayName"
          header={t("name")}
          filter
          // filterElement={nameFilterTemplate}
          style={{ minWidth: "9rem" }}
          showAddButton={false}
          showApplyButton={false}
          showClearButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          field="role"
          header={t("role")}
          filter
          filterPlaceholder="Search by name"
          style={{ minWidth: "9rem" }}
          // filterElement={roleFilterTemplate}
          showAddButton={false}
          showApplyButton={false}
          showClearButton={false}
          showFilterMenuOptions={false}
        />
        <Column
          header={t("picture")}
          style={{ minWidth: "9rem" }}
          body={picture}
        />
        <Column
          field="email"
          header={t("email")}
          filter
          // filterElement={emailFilterTemplate}
          style={{ minWidth: "9rem" }}
          showAddButton={false}
          showApplyButton={false}
          showClearButton={false}
          // showFilterMenuOptions={false}
        />
        <Column
          field="verified"
          header="Status"
          filter
          // filterElement={StatusFilterTemplate}
          style={{ minWidth: "9rem" }}
          showAddButton={false}
          showApplyButton={false}
          showClearButton={false}
          showFilterMenuOptions={false}
          // body={statusColumn}
        />
        <Column
          header={t("actions")}
          style={{ minWidth: "9rem" }}
          body={elementAction}
        />
      </DataTable>
      <TablePagination
        data={data}
        filterData={filteredData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        // responseData={responseData}
      />
    </div>
  );
};

export default ClientUsers;
