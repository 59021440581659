import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes";
import { React } from "react";
import Loading from "./components/Loading";
import { useSelector } from "react-redux";

function App() {
  let isLoading = useSelector((state) => state.auth.isLoading);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <RouterProvider className="App" router={router} />
      )}
    </>
  );
}

export default App;
