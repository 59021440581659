import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserById,
  AdminUpdateUser,
} from "../../../../../redux/Auth/AuthActions";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import PagesLoading from "../../../../../components/Loading/pagesLoading";
import setTitle from "../../../../../hooks/Title";
import { useTranslation } from "react-i18next";

const ClientProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [t] = useTranslation();

  let id = useParams();

  let token = useSelector((state) => state.auth.data?.accessToken);
  let role = useSelector((state) => state.auth.data?.role);
  const responseData = useSelector((state) => state.auth.responseData);
  const err = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.singleLoading);

  const [hide, setHide] = useState(true);

  // let personal = responseData?.user;
  let personal = {
    displayName: "Ahmed",
    role: "Admin",
    hrCode: "151574",
    email: "test@gmail.com",
  };

  const userRole = [
    { name: t("client_admin"), code: "client_admin" },
    { name: t("driver"), code: "driver" },
    { name: t("delivery_station"), code: "delivery_station" },
    { name: t("preparation_operator"), code: "preparation_operator" },
  ];

  const formik = useFormik({
    initialValues: {
      hrCode: "",
      username: "",
      selectedRole: null,
    },
    onSubmit: (values) => {
      const submissionData = { ...values };
      if (!submissionData.hrCode) {
        delete submissionData.hrCode;
      }
      if (!submissionData.username) {
        delete submissionData.username;
      }
      if (!submissionData.selectedRole) {
        delete submissionData.selectedRole;
      }
      setHide(!hide);
      dispatch(
        AdminUpdateUser({
          id: id.id,
          displayName: submissionData.username,
          role: submissionData.selectedRole.code,
          hrCode: submissionData.hrCode,
          token: token,
        })
      );
      setTimeout(() => {
        dispatch(getUserById({ id: id.id, token: token }));
      }, 2000);
    },
  });

  useEffect(() => {
    setTitle("User Profile");
    if (id) {
      dispatch(getUserById({ id: id.id, token: token }));
    }
  }, [token, id]);
  // useEffect(() => {
  //   if (err) {
  //     navigate("/*");
  //   }
  // }, [err]);

  if (personal) {
    return (
      <>
        {loading ? (
          <PagesLoading />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {role == "client_admin" || role == "super_admin" ? (
                  <div className="btn-style" style={{ height: "35px" }}>
                    <div
                      className="btn btn-outline-secondary"
                      onClick={() => setHide(!hide)}
                    >
                      <i className="fa fa-pencil"></i>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
              <div className="card profile-header">
                <div className="body">
                  <div className="profile-image mb-3 ">
                    <img
                      alt=""
                      className="rounded-circle user-img"
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!hide && (
                      <div
                        style={{
                          width: "250px",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("enterUserName")}
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    )}
                    <h4 className="m-b-0 ">
                      {hide && <strong>{personal.displayName}</strong>}
                    </h4>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="header ">
                  <h2>{t("info")}</h2>
                  {!hide && (
                    <div style={{ width: "250px" }}>
                      <Dropdown
                        value={formik.values.selectedRole}
                        onChange={(e) => {
                          formik.setFieldValue("selectedRole", e.value); // Set the selected value
                        }}
                        options={userRole}
                        optionLabel="name"
                        placeholder={t("selectRole")}
                        className="w-full md:w-14rem"
                      />
                    </div>
                  )}
                  {hide && (
                    <h2>
                      {t("role")}: {t(personal.role)}
                    </h2>
                  )}
                </div>
                <div className="body">
                  <small className="text-muted">{t("hr-code")}</small>
                  {!hide && (
                    <div style={{ width: "250px" }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("enterHrCode")}
                        name="hrCode"
                        value={formik.values.hrCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  )}
                  {hide && <p>{personal.hrCode}</p>}
                  <div></div>
                  <hr />
                  <small className="text-muted">{t("emailAddress")} </small>
                  <p>{personal.email}</p>
                  {/* <hr />   */}
                  {/* <small className="text-muted">Mobile: </small> */}
                  {/* <p>{personal.phone}</p> */}
                  {/* <p>01010102030</p> */}
                  {/* <hr /> */}
                  {/* <small className="text-muted">Birth Date: </small> */}
                  {/* <p className="m-b-0">{personal.bod}</p> */}
                </div>
                {!hide && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "5px",
                      margin: "20px",
                    }}
                  >
                    <button
                      className="btn btn-danger"
                      onClick={() => setHide(!hide)}
                      type="submit"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      className="btn btn-primary  primary-btn-style"
                      type="submit"
                    >
                      {t("save")}
                    </button>
                  </div>
                )}
              </div>
              {/* <div className="card">
            {personal.status === "Pending" ? (
              <div className="accept-user">
                <h6>Accept User : </h6>
                <div className="buttons">
                  <button className="btn btn-danger ">Reject</button>
                  <button className="btn btn-primary ">Accept</button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div> */}
            </form>
          </>
        )}
      </>
    );
  }
};

export default ClientProfile;
