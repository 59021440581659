import { createSlice } from "@reduxjs/toolkit";
import { dashboardStatus } from "./DashboardActions";
const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    responseData: null,
    error: null,
    success: false,
    Loading: false,
    singleLoading:false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(dashboardStatus.pending, (state) => {
        state.Loading = true;
        state.error = null;
      })
      .addCase(dashboardStatus.fulfilled, (state, action) => {
        state.Loading = false;
        state.responseData = action.payload;
      })
      .addCase(dashboardStatus.rejected, (state, action) => {
        state.Loading = false;
        state.error = action.payload;
      })
    
  },
});

export const DashboardAction = DashboardSlice.actions;
export default DashboardSlice;
