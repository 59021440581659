import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageTitle from "../../../../components/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { CreateLine } from "../../../../redux/ProductionLines/ProductionLinesActions";
import { useTranslation } from "react-i18next";


const AddCategory = ({ closePopup }) => {
  let token = useSelector((state) => state.auth.data?.accessToken);
  const dispatch = useDispatch();
  const [t] = useTranslation();


  const validationSchema = Yup.object().shape({
    lineName: Yup.string().required("Line Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      lineName: "",
      stations: [],
      stationName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
    //   dispatch(
    //     CreateLine({
    //       name: values.lineName,
    //       status: 1,
    //       stations: values.stations,
    //       token: token,
    //     })
    //   );
      closePopup();
    },
  });

  const addStation = () => {
    if (formik.values.stationName.trim() !== "") {
      formik.setValues({
        ...formik.values,
        stations: [
          ...formik.values.stations,
          { name: formik.values.stationName.trim() },
        ],
        stationName: "",
      });
    }
  };

  const deleteStation = (index) => {
    const updatedStations = [...formik.values.stations];
    updatedStations.splice(index, 1);
    formik.setValues({
      ...formik.values,
      stations: updatedStations,
    });
  };

  return (
    <>
      <PageTitle Title={t("addCategory")} />
      <hr />
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="body">
            <div className="row clearfix mb-3">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder={t("categoryName")}
                    type="text"
                    name="lineName"
                    value={formik.values.lineName}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.lineName && formik.errors.lineName && (
                    <div style={{ color: "red" }}>{formik.errors.lineName}</div>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-md-12" style={{ width: "100%" }}>
                <div className="form-group mb-0">
                  <input
                    className="form-control"
                    placeholder={t("defects")}
                    type="text"
                    name="stationName"
                    value={formik.values.stationName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div
                className=" w-full text-right mr-3 mt-3"
                style={{ width: "100%" }}
              >
                <button
                  className="btn coloring "
                  type="button"
                  onClick={addStation}
                  disabled={!formik.values.stationName}
                >
                  {t("addDefect")}
                </button>
              </div>
              {formik.values.lineName !== "" && (
                <div style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                  <strong>{formik.values.lineName}:</strong>
                  {formik.values.stations.length > 0 ? (
                    <ul style={{ listStyle: "none", opacity: "0.8" }}>
                      {formik.values.stations.map((station, index) => (
                        <li key={index}>
                          {station.name.trim()}
                          <i
                            onClick={() => deleteStation(index)}
                            className="icon-trash ml-3"
                            style={{ color: "red", cursor: "pointer" }}
                          ></i>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No categories added yet.</p>
                  )}
                </div>
              )}

              <div className="d-flex justify-content-center btn-flex pt-3 pr-3 my-3">
                <button
                  className="btn mr-5 style-btn color-btn"
                  type="button"
                  onClick={() => closePopup()}
                >
                  {t("cancel")}
                </button>
                <button
                  className="btn style-btn coloring"
                  type="submit"
                  disabled={formik.values.stations.length === 0}
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </form>
    </>
  );
};

export default AddCategory;
