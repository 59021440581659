const ReqHeader = ({ createdAt, status }) => {
    const badgeProductsStatus = (status) => {
      switch (status) {
        case "Delivered":
          return <span className="badge badge-success m-b-0">{status}</span>;
        case "Planned":
          return <span className="badge badge-default m-b-0">{status}</span>;
        case "On Train":
          return <span className="badge badge-warning m-b-0">{status}</span>;
        case "In Preparation":
          return <span className="badge badge-primary m-b-0">{status}</span>;
        case "Ready":
          return <span className="badge badge-info m-b-0">{status}</span>;
        case "Canceled":
          return <span className="badge badge-danger m-b-0">{status}</span>;
        default:
          return null;
      }
    };
  
    return (
      <>
        <div className="col-md-12 col-sm-12 text-right">
          <p className="m-b-0">
            <strong>Order Date: {createdAt}</strong>
          </p>
          <p className="m-b-3">
            <strong>Order Status: </strong>
            {badgeProductsStatus(status)}
          </p>
        </div>
      </>
    );
  };
  
  export default ReqHeader;
  