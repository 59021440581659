import PageTitle from "../../components/PageTitle";

import React, { useState, useEffect } from "react";

import BackButton from "../../components/Buttons/backButton";

import Data from "./Data";
import { Calendar } from "primereact/calendar";

import setTitle from "../../hooks/Title";
import { useTranslation } from "react-i18next";

const RequestList = () => {
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [dates, setDates] = useState(null);

  const [t] = useTranslation();

  useEffect(() => {
    setTitle("Deliveries");
  }, []);
  return (
    <>
      <div id="main-content">
        <BackButton />
        <div className="flex-header">
          <PageTitle Title={t("deliveries")} />
          <div className="btn-style">
            <div
              className="btn btn-outline-secondary"
              onClick={() => setFilterVisabilty(!filterVisabilty)}
            >
              <i className="icon-equalizer plus"></i>
            </div>
          </div>
        </div>
        {filterVisabilty && (
          <div className="card">
            <div className="inputs-search">
              <div className="">
                <input
                  className="form-control"
                  placeholder="Request code "
                  type="number"
                />
              </div>

              <div className="">
                <select
                  name="priorty"
                  defaultValue="priorty"
                  className="form-control"
                >
                  <option disabled value="priorty">
                    priorty
                  </option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>

              <div className="">
                <div className="input-group">
                  <div className="input-group">
                    <Calendar
                      value={dates}
                      onChange={(e) => setDates(e.value)}
                      selectionMode="range"
                      readOnlyInput
                      placeholder="Date "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Data />
      </div>
    </>
  );
};

export default RequestList;
