import React from "react";
import PageTitle from "../../../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";
import PaymentDetails from "./paymentDetails";
import Accounts from "./Accounts";
import ClientProfile from "./ClientProfile";
import ClientUsers from "./ClientUsers";
const ClientDetails = () => {
  const [t] = useTranslation();

  const tabData = [
    {
      header: "Profile",
      content: <ClientProfile />,
    },
    {
      header: "Accounts",
      content: <Accounts />,
    },
    {
      header: "Users",
      content: <ClientUsers />,
    },
    {
      header: "Payments",
      content: <PaymentDetails />,
    },
  ];

  return (
    <div>
      <div id="main-content">
        <PageTitle Title={t("Clients Details")} />
        <div className="card">
          <div className="body">
            <TabView>
              {tabData.map((tab, index) => (
                <TabPanel key={index} header={tab.header}>
                  <p className="m-0">{tab.content}</p>
                </TabPanel>
              ))}
            </TabView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
