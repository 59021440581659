import { Link } from "react-router-dom";
import withAuth from "../../hooks/withAuth";

import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { findAllProducts } from "../../redux/Products/ProductsActions";

import TablePagination from "../../components/Pagination";
import BackButton from "../../components/Buttons/backButton";
import UploadFile from "../../components/uploudFile";
import setTitle from "../../hooks/Title";
import PageTitle from "../../components/PageTitle";
import PopUp from "../../components/PopUp";
import ProductsAdd from "./ProductsAdd";
import ProductEdit from "./ProuductsEdits";
import DeleteProduct from "./deleteProduct";
import PagesLoading from "../../components/Loading/pagesLoading";
import socket from "../../utils/webSoket/webSocket";
import { useTranslation } from "react-i18next";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const Products = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.products.products);
  const loading = useSelector((state) => state.products.Loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);
  const [currentItems, setCurrentItems] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isFileVisible, setIsFileVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [productId, setProductId] = useState(null);

  // Filter State
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [t] = useTranslation();

  const [dataInfo, setDataInfo] = useState(null);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    setDataInfo(currentItems);
    initFilters();
  }, [currentItems]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            opacity: "0.6",
          }}
          onClick={() => {
            dispatch(
              findAllProducts({
                token: token,
                page: currentPage,
                limit: itemsPerPage,
              })
            );
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();

  const actionsProducts = (rowData) => {
    console.log(rowData.id);
    return (
      <>
        <Link
          to={`/admin/products/${rowData.id}`}
          className="btn btn-outline-secondary mr-1"
          // onClick={() => {
          //   dispatch(getProductById({ id: productID, token: token }));
          // }}
        >
          <i className="icon-eye"></i>
        </Link>
        <div
          className="btn btn-outline-secondary"
          onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
        <div
          className="btn btn-outline-danger danger "
          onClick={() => handleDeleteProduct(rowData.id)}
        >
          <i className="icon-trash"></i>
        </div>
      </>
    );
  };

  const codeFilterTemplate = () => {
    return (
      <div className="">
        <input
          className="form-control"
          placeholder={t("code")}
          type="number"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </div>
    );
  };

  const descriptionFilterTemplate = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder={t("name")}
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
    );
  };

  socket.on("created_products", (info) => {
    const updatedCurrentItems = [info, ...currentItems];
    setCurrentItems(updatedCurrentItems);
  });

  socket.on("updated_products", (info) => {
    setCurrentItems(
      currentItems.map((obj) => {
        if (obj.id === info.id) {
          return { ...info };
        }
        return obj;
      })
    );
  });

  socket.on("deleted_products", (info) => {
    const filtered = currentItems.filter((item) => item.id !== info.id);
    setCurrentItems(filtered);
  });

  const visibleHandel = () => {
    setIsVisible(true);
  };
  const closehandle = () => {
    setIsVisible(false);
  };

  const visibleEditHandel = (productId) => {
    setIsEditVisible(true);
    setProductId(productId);
  };
  const closeEdithandle = () => {
    setIsEditVisible(false);
  };

  const visibleFileHandel = () => {
    setIsFileVisible(true);
  };
  const closeFilehandle = () => {
    setIsFileVisible(false);
  };

  const closeDeletehandle = () => {
    setIsDeleteVisible(false);
  };

  const handleDeleteProduct = (productId) => {
    setProductId(productId);
    setIsDeleteVisible(true);
  };

  useEffect(() => {
    setTitle("Products");
    dispatch(
      findAllProducts({
        token: token,
        page: currentPage,
        limit: itemsPerPage,
        code,
        name,
      })
    );
  }, [token, currentPage, itemsPerPage, code, name]);

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  return (
    <>
      <PopUp
        text={<DeleteProduct closePopup={closeDeletehandle} id={productId} />}
        isVisible={isDeleteVisible}
      />
      <div id="main-content">
        <PopUp
          text={
            <ProductEdit
              closePopup={closeEdithandle}
              id={productId}
              isVisible={isEditVisible}
            />
          }
          isVisible={isEditVisible}
        />
        <PopUp
          text={<UploadFile closePopup={closeFilehandle} />}
          isVisible={isFileVisible}
        />
        <PopUp
          text={<ProductsAdd closePopup={closehandle} />}
          isVisible={isVisible}
        />
        <div className="flex-header">
          <PageTitle Title={t("products")} />
          <div className="btn-style" style={{ height: "35px" }}>
            <div className="user-account m-0">
              <Dropdown className="m-0">
                <Dropdown.Toggle variant="none" as="a" id="dropdown-basic">
                  <div className="btn btn-outline-secondary">
                    <i className="icon-plus plus"></i>
                  </div>
                </Dropdown.Toggle>
                <div className="dropdown">
                  <Dropdown.Menu className="dropdown-menu-right account">
                    <Dropdown.Item onClick={visibleFileHandel}>
                      <i className="fa fa-file-o"></i> {t("uploadeFile")}
                    </Dropdown.Item>

                    <li className="divider"></li>
                    <Dropdown.Item onClick={visibleHandel}>
                      <i className="fa fa-database"></i>
                      {t("addProduct")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="table-list">
          <div className="table-responsive">
            <DataTable
              value={dataInfo}
              loading={loading}
              loadingIcon={<PagesLoading />}
              dataKey="id"
              filters={filters}
              header={headers}
              emptyMessage={t("noDataFound")}
            >
              <Column
                header={t("code")}
                field="code"
                filter
                filterElement={codeFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
              />
              <Column
                header={t("description")}
                field="name"
                filter
                filterElement={descriptionFilterTemplate}
                style={{ minWidth: "9rem" }}
                showAddButton={false}
                showApplyButton={false}
                showClearButton={false}
                showFilterMenuOptions={false}
              />
              <Column
                header={t("production-rate")}
                field="productionRate"
                style={{ minWidth: "9rem" }}
              />
              <Column
                header={t("actions")}
                style={{ minWidth: "12rem" }}
                body={actionsProducts}
              />
            </DataTable>
          </div>
        </div>
        <TablePagination
          data={responseData?.products}
          filterData={filteredData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          responseData={responseData}
        />
      </div>
    </>
  );
};
export default withAuth(Products);
