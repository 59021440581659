import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Registeration";
import ForgetPass from "../pages/Auth/ForgetPass";
import ResetPass from "../pages/Auth/ResetPass";
import VerifyEmail from "../pages/Auth/VerifyEmail";
import CheckEmailPass from "../pages/Auth/CheckEmailPass";
import PrivateRoutes from "./PrivateRoutes";
import Page404 from "../pages/Page404";
import MainLayout from "../Layout";
import Dashboard from "../pages/Dashboard";
import AdminDashboard from "./AdminDashboard";
import NewRequest from "../pages/NewRequest";
import RequestList from "../pages/RequestList";
import RequestDetails from "../pages/RequestDetails";
import Users from "../pages/Users";
import Profile from "../pages/Profile";
import ProfileUsers from "../pages/Profile-users";
import ProductionStations from "../pages/ProductionStations";
import Products from "../pages/Products";
import ProductDetails from "../pages/Products/ProductsDetails";
import Components from "../pages/Components";
import ComponentDetails from "../pages/Components/ComponentDetails";
import PreparationRequests from "../pages/PreparationRequests";
import PreparationRequestDetails from "../pages/PreparationRequests/PreparationDetails";
import DriverDetails from "../pages/Driver/DriverDetails";
import NotificationList from "../pages/NotificationList";
import DriverPrivate from "./DriverPrivate";
import PreparationPrivate from "./PreparationPrivate";
import StationPrivate from "./StationPrivate";
import TrainSetUP from "../pages/TrainSetUp";
import SuperAdminPrivate from "./SuperAdminPrivate";

import AddUser from "../pages/Users/AddUser";
import VerifyEmailRegistration from "../pages/Auth/VerifyEmailRegistration";

import ProductionPlan from "../pages/ProductionPlan";
import Schedule from "../pages/Schedule";
import CalenderSchedule from "../pages/Calender";

import MPM from "../pages/MPM";
import Forms from "../pages/QMS/Forms";
import Defects from "../pages/QMS/Defects";
import Category from "../pages/QMS/Category";
import CategoryDetails from "../pages/QMS/CategoryDetails";

import Clients from "../pages/SuperAdmin/Clients";
import SuperAdminUsers from "../pages/SuperAdmin/SuperAdminUsers";
import ClientDetails from "../pages/SuperAdmin/Clients/clientDetails";

import Page403 from "../pages/ErrorPages/Page403";
import Page500 from "../pages/ErrorPages/Page500";
import Page503 from "../pages/ErrorPages/Page503";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes />,
    errorElement: <Page404 />,
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          { index: true, element: <Dashboard /> },
          {
            path: "Notification-List",
            element: <NotificationList />,
          },
          {
            path: "requestDetails/:id",
            element: <RequestDetails />,
          },
          {
            path: "preparation",
            element: <PreparationPrivate />,
            children: [
              { index: true, element: <Dashboard /> },

              {
                index: "preparation",
                element: <PreparationRequests />,
              },
              {
                path: "preparation/PreparationRequests",
                element: <PreparationRequests />,
              },
              {
                path: "preparation/PreparationRequestsDetails/:id",
                element: <PreparationRequestDetails />,
              },
            ],
          },

          {
            path: "driver",
            element: <DriverPrivate />,
            children: [
              { index: true, element: <Dashboard /> },
              {
                path: "/driver/driver-details/:id",
                element: <DriverDetails />,
              },
            ],
          },
          {
            path: "station",
            element: <StationPrivate />,
            children: [
              { index: true, element: <Dashboard /> },
              {
                path: "/station/station-details/:id",
                element: <DriverDetails />,
              },
            ],
          },
          {
            path: "superAdmin",
            element: <SuperAdminPrivate />,
            children: [
              { index: true, element: <Dashboard /> },
              {
                path: "clients",
                element: <Clients />,
              },
              {
                path: "users",
                element: <SuperAdminUsers />,
              },
              {
                path: "clientDetails",
                element: <ClientDetails />,
              },
            ],
          },
          {
            path: "admin",
            element: <AdminDashboard />,
            children: [
              { index: true, element: <Dashboard /> },
              {
                path: "newrequest",
                element: <NewRequest />,
              },
              {
                path: "requestList",
                element: <RequestList />,
              },

              {
                path: "qms/forms",
                element: <Forms />,
                children: [],
              },
              {
                path: "qms/defects",
                element: <Defects />,
                children: [],
              },
              {
                path: "qms/category",
                element: <Category />,
                children: [],
              },
              {
                path: "qms/categoryDetails/:id",
                element: <CategoryDetails />,
                children: [],
              },
              {
                path: "MPM",
                element: <MPM />,
                children: [],
              },

              {
                path: "users",
                element: <Users />,
                children: [],
              },
              {
                path: "addusers",
                element: <AddUser />,
                children: [],
              },

              {
                path: "profile-users/:id",
                element: <ProfileUsers />,
              },
              {
                path: "productionstations",
                element: <ProductionStations />,
              },
              {
                path: "products",
                element: <Products />,
              },

              {
                path: "products/:id",
                element: <ProductDetails />,
              },

              {
                path: "components",
                element: <Components />,
              },
              {
                path: "components/:id",
                element: <ComponentDetails />,
              },
              {
                path: "production-plan",
                element: <ProductionPlan />,
              },
              {
                path: "schedule",
                element: <Schedule />,
              },
              {
                path: "calendar",
                element: <CalenderSchedule />,
              },
              {
                path: "train-setup",
                element: <TrainSetUP />,
              },
            ],
          },
          {
            path: "profile",
            element: <Profile />,
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/verify-email-registration/:uuid",
    element: <VerifyEmailRegistration />,
  },

  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgetPass",
    element: <ForgetPass />,
  },
  {
    path: "/resetPass",
    element: <ResetPass />,
  },
  {
    path: "/verifyEmail",
    element: <VerifyEmail />,
  },
  {
    path: "/checkEmailPass",
    element: <CheckEmailPass />,
  },
  {
    path: "/403",
    element: <Page403 />,
  },
  {
    path: "/500",
    element: <Page500 />,
  },
  {
    path: "/503",
    element: <Page503 />,
  },
]);
