export default function headerTable(headerData) {
  return headerData?.map((item, i) => {
    return <th key={i}>{item}</th>;
  });
}

export const badgeProductsStatus = (status, t) => {
  switch (status) {
    case "delivered":
      return (
        <td>
          <span className="badge badge-success">{t(status)}</span>
        </td>
      );
    case "planned":
      return (
        <td>
          {" "}
          <span className="badge badge-default">{t(status)}</span>{" "}
        </td>
      );
    case "on_train":
      return (
        <td>
          {" "}
          <span className="badge badge-warning">{t(status)}</span>{" "}
        </td>
      );
    case "in_preparation":
      return (
        <td>
          {" "}
          <span className="badge badge-primary">{t(status)}</span>{" "}
        </td>
      );
    case "ready":
      return (
        <td>
          <span className="badge badge-info">{t(status)}</span>
        </td>
      );
    case "canceled":
      return (
        <td>
          <span className="badge badge-danger">{t(status)}</span>
        </td>
      );
    default:
      return null;
  }
};
export const badgeOrderStatus = (status, t) => {
  switch (status) {
    case "delivered":
      return (
        <td>
          <span className="badge badge-success">{t(status)}</span>
        </td>
      );
    case "planned":
      return (
        <td>
          {" "}
          <span className="badge badge-default">{t(status)}</span>{" "}
        </td>
      );

    case "in_progress":
      return (
        <td>
          {" "}
          <span className="badge badge-primary">{t(status)}</span>{" "}
        </td>
      );

    case "canceled":
      return (
        <td>
          <span className="badge badge-danger">{t(status)}</span>
        </td>
      );
    default:
      return null;
  }
};



export const badgeUsersStatus = ({ verified, t }) => {
  let statusBadge;

  switch (String(verified).toLowerCase()) {
    case "true":
      statusBadge = <span className="badge badge-success">{t("verified")}</span>;
      break;
    case "false":
      statusBadge = <span className="badge badge-default">{t("pending")}</span>;
      break;
    default:
      // Handle any other cases if needed
      statusBadge = null;
  }

  return <td>{statusBadge}</td>;
};
export const badgePriorty = (status) => {
  switch (status) {
    case "High":
      return (
        <td>
          <span className="btn btn-danger size-button">{status}</span>
        </td>
      );

    case "Mediam":
      return (
        <td>
          <span className="btn btn-info  size-button">{status}</span>
        </td>
      );
    case "Low":
      return (
        <td>
          <span className="btn btn-secondary   size-button">{status}</span>
        </td>
      );

    default:
      return null;
  }
};
