import React from "react";
import { MutatingDots } from "react-loader-spinner";
import "./index.scss";
const Loading = () => {
  return (
    <>
      <div className="parent">
        <MutatingDots
          height="100"
          width="100"
          color="#fdb813"
          secondaryColor="#fdb813"
          ariaLabel="mutating-dots-loading"
          visible={true}
        />
      </div>
    </>
  );
};

export default Loading;
