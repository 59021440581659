import "./index.scss";
import Logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { setItem } from "../../../utils/storage.js/storage";
const Register = () => {
  const navigate = useNavigate();

  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const [firstName] = useState("");
  const [lastName] = useState("");
  const [email, setEmail] = useState("");
  const [passwords, setPasswords] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [confPassErr, setConfPassErr] = useState(false);
  const [formErr, setFormErr] = useState(false);

  let handleSubmit = (e) => {
    e.preventDefault();

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      passwords === "" ||
      confirmPass === ""
    ) {
      setFormErr(true);
    } else {
      setFormErr(false);
      navigate("/login");
      setItem("user", { userEmail: email });
    }
  };

  const firstNameHandler = (e) => {
    let firstname = e.target.value;
    if (firstname === "") {
      setFirstNameErr(true);
    } else {
      setFirstNameErr(false);
   
    }
  };

  const lastNameHandler = (e) => {
    let lastname = e.target.value;
    if (lastname === "") {
      setLastNameErr(true);
    } else {
      setLastNameErr(false);
   
    }
  };
  const emailHandler = (e) => {
    let userMail = e.target.value;
    let userMailvalid = emailRegex.test(userMail);
    if (userMail === "" || userMailvalid === false) {
      setEmailErr(true);
    } else {
      setEmailErr(false);
      setEmail(userMail);
    }
  };

  const passHandler = (e) => {
    let pass = e.target.value;
    if (pass === "" || pass.length < 8) {
      setPassErr(true);
    } else {
      setPassErr(false);

      setPasswords(pass);
    }
  };

  const confirmPassHandler = (e) => {
    let confirmPassword = e.target.value;
    if (
      confirmPassword === "" ||
      confirmPassword.length < 8 ||
      confirmPassword !== passwords
    ) {
      setConfPassErr(true);
    } else {
      setConfPassErr(false);
      setConfirmPass(confirmPassword);
    }
  };

  return (
    <>
      <div className="theme-cyan">
        <div>
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main">
              <div className="auth-box">
                <div className="top">
                  <img
                    src={Logo}
                    alt="Lucid"
                    style={{ height: "40px", margin: "10px" }}
                  />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Create an account</p>
                  </div>
                  <div className="body">
                    <form
                      className="form-auth-small ng-untouched ng-pristine ng-valid"
                      onSubmit={handleSubmit}
                    >
                      {formErr && (
                        <p className="validateErr">please fill all fields </p>
                      )}

                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>

                        <input
                          className="form-control"
                          id="signup-email"
                          placeholder="First name"
                          type="text"
                          onBlur={firstNameHandler}
                        />

                        {firstNameErr && (
                          <p className="validateErr">required</p>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>

                        <input
                          className="form-control"
                          id="signup-email"
                          placeholder="Last name"
                          type="text"
                          onBlur={lastNameHandler}
                        />

                        {lastNameErr && <p className="validateErr">required</p>}
                      </div>

                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>

                        <input
                          className="form-control"
                          id="signup-email"
                          placeholder="Your email"
                          type="text"
                          onBlur={emailHandler}
                        />

                        {emailErr && (
                          <p className="validateErr">
                            require format must be like this form user@gmail.com
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signup-password"
                          placeholder="Password"
                          type="password"
                          onBlur={passHandler}
                        />
                        {passErr && (
                          <p className="validateErr">
                            require password must be more than 8 character
                          </p>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signup-password"
                          placeholder="confirm Password"
                          type="password"
                          onBlur={confirmPassHandler}
                        />
                        {confPassErr && (
                          <p className="validateErr">
                            require the confirm password and password must be
                            the same
                          </p>
                        )}
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-block primary-btn-style"
                        type="submit"
                      >
                        REGISTER
                      </button>
                      <div className="bottom">
                        <span className="helper-text">
                          Already have an account?{" "}
                          <Link to="/login">Login</Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
