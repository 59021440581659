import React, { useEffect } from "react";
import "./index.scss";

const PopUp = ({ text, isVisible }) => {
  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div className="overlay" style={{ zIndex: "1000" }}>
          <div className="popup">
            <header style={{ width: "100%" }}>
              <span className="text-wrap">{text}</span>
            </header>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUp;
