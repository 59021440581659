import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";

export const CreateProduct = createAsyncThunk(
  "products/createProduct",
  async (
    { code, name, productionRate, components, productionLines, token },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.createProduct,
        { code, name, productionRate, components, productionLines },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findAllProducts = createAsyncThunk(
  "products/findAllProducts",
  async ({ page, limit, code, name, token }, { rejectWithValue }) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (name) params.name = name;
      if (code) params.code = code;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(Api.findAllProducts, config);
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (
    { id, page, limit, token, code, componentName },
    { rejectWithValue }
  ) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (code) params.code = code;
      if (componentName) params.componentName = componentName;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(
        `${Api.getProductById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getProductPopup = createAsyncThunk(
  "products/getProductPopup",
  async (
    { id, page, limit, token, code, componentName },
    { rejectWithValue }
  ) => {
    try {
      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (code) params.code = code;
      if (componentName) params.componentName = componentName;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(
        `${Api.getProductById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteProductById = createAsyncThunk(
  "products/deleteProductById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.delete(
        `${Api.deleteProductById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editProduct = createAsyncThunk(
  "products/editProduct",
  async (
    {
      id,
      code,
      name,
      productionRate,
      createdComponents,
      deletedComponents,
      createdProductionLines,
      deletedProductionLines,
      token,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const filterEmptyFields = (obj) => {
        const filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== "" && obj[key] !== null && obj[key].length !== 0) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      };

      const dataToUpdate = filterEmptyFields({
        code,
        name,
        productionRate,
        createdComponents,
        deletedComponents,
        createdProductionLines,
        deletedProductionLines,
      });

      const response = await axiosInstance.patch(
        `${Api.editProduct}/${id}`,
        dataToUpdate,
        config
      );

      return response.data;
    } catch (error) {
      console.log("error Id", id);
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
