import React, { useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
const Switch = ({ ischecked, handleSwitch, id }) => {
  const [checked, setChecked] = useState(ischecked);

  return (
    <div className="">
      <InputSwitch
        checked={checked}
        onChange={(e) => {
          setChecked(e.value);
          handleSwitch(e.value, id);
        }}
      />
    </div>
  );
};

export default Switch;
