import ReqHeader from "./reqHeader";
import { Timeline } from "primereact/timeline";

const History = ({ createdAt, data, historyHeader }) => {
  const events = [
    {
      status: "planned",
      date: "15/10/2020 10:30",
    },
    {
      status: "in preparation",
      date: "15/10/2020 14:00",
    },
    {
      status: "ready",
      date: "15/10/2020 16:15",
    },
    {
      status: "on train",
      date: "16/10/2020 10:00",
    },
    {
      status: "delivered",
      date: "16/10/2020 10:00",
    },
  ];

  return (
    <>
      <Timeline
        value={events}
        content={(item) => (
          <div>
            <div>{item.status}</div>
            <div>{item.date}</div>
          </div>
        )}
        style={{}}
      />
    </>
  );
};

export default History;
