import React, { useState, useEffect } from "react";
import { Paginator } from "primereact/paginator";
import "./index.scss";

const TablePagination = ({
  data,
  filterData,
  currentPage,
  setCurrentPage,
  responseData,
}) => {
  const [first] = useState(0);
  const [itemsPerPage] = useState(14);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredData = data.slice(first, first + itemsPerPage);
      setCurrentItems(filteredData);
    }
  }, [data, first]);

  useEffect(() => {
    if (currentItems?.length !== 0) {
      filterData(currentItems);
    }
  }, [currentItems]);
  return (
    <>
      <Paginator
        className="d-flex flex-nowrap justify-content-center align-items-start  small-screen"
        first={(currentPage - 1) * itemsPerPage}
        rows={itemsPerPage}
        totalRecords={
          responseData?.totalCount ||
          responseData?.productsTotalCount ||
          responseData?.componentsTotalCount
        } // Use totalCount from the response
        onPageChange={(e) => setCurrentPage(e.page + 1)}
        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      />
    </>
  );
};

export default TablePagination;
