import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/axiosInstance";
import Api from "../endPoints";

export const CreateComponent = createAsyncThunk(
  "components/createComponent",
  async (
    { code, name, packageType, quantityPerPackage, lineStations, token },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.post(
        Api.createComponent,
        { code, name, packageType, quantityPerPackage, lineStations },
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.status);

      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const findAllComponents = createAsyncThunk(
  "components/findAllComponents",
  async (
    { token, page, limit, name, packageType, code },
    { rejectWithValue }
  ) => {
    try {

      const params = {};
      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (name) params.name = name;
      if (packageType) params.packageType = packageType;
      if (code) params.code = code;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(Api.findAllComponents, config);
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getComponentsById = createAsyncThunk(
  "components/getComponentsById",
  async (
    { id, token, page, limit, productName, code },
    { rejectWithValue }
  ) => {
    try {
      const params = {};

      if (page) params.page = page;
      if (limit) params.limit = limit;
      if (productName) params.productName = productName;
      if (code) params.code = code;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params,
      };
      const response = await axiosInstance.get(
        `${Api.getComponentsById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editComponentsById = createAsyncThunk(
  "components/editComponentsById",
  async (
    {
      id,
      token,
      name,
      packageType,
      code,
      quantityPerPackage,
      warehouseAddress,
      lineStations,
      deletedLineStations,
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const filterEmptyFields = (obj) => {
        const filteredObj = {};
        for (const key in obj) {
          if (obj[key] !== "" && obj[key] !== null && obj[key]?.length !== 0) {
            filteredObj[key] = obj[key];
          }
        }
        return filteredObj;
      };

      const dataToUpdate = filterEmptyFields({
        name,
        packageType,
        code,
        quantityPerPackage,
        warehouseAddress,
        lineStations,
        deletedLineStations,
      });

      const response = await axiosInstance.patch(
        `${Api.editComponentsById}/${id}`,
        dataToUpdate,
        config
      );

      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteComponentById = createAsyncThunk(
  "components/deleteComponentById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axiosInstance.delete(
        `${Api.deleteComponentById}/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
