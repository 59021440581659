import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";
import DetailsRequest from "./details";
import "./index.scss";
import { useParams, Link } from "react-router-dom";
import BackButton from "../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import { getRequestByID } from "../../redux/Requests/Requests.Actions";
import PagesLoading from "../../components/Loading/pagesLoading";
import setTitle from "../../hooks/Title";
import History from "./history";
const RequestDetails = () => {
  const dispatch = useDispatch();

  let id = useParams();

  let token = useSelector((state) => state.auth.data?.accessToken);
  const data = useSelector((state) => state.requests?.responseData?.request);
  const Loading = useSelector((state) => state.requests?.Loading);

  const [tab, setTab] = useState(false);

  useEffect(() => {
    setTitle("Request Details");
    dispatch(getRequestByID({ id: id.id, token: token }));
  }, [token]);

  // useEffect(() => {
  //   if (data.length === 0) {
  //     navigate("/*");
  //   }
  // }, [data]);

  // const data = [
  //   {
  //     id: 100,
  //     status: "Delivered",
  //     statusHistory: [
  //       { status: "Planned", Date: "10/3/2024", Time: "12:15 PM" },
  //       { status: "Ready", Date: "14/3/2024", Time: "1:30 PM" },
  //     ],
  //     createdAt: "2023-08-16T13:35:00.000Z",
  //     requestsComponents: [
  //       {
  //         componentId: 12,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 12,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //       {
  //         componentId: 11,
  //         packageType: "box",
  //         componentQuantity: 1,
  //         component: {
  //           code: "2",
  //           name: "comp2",
  //         },
  //       },
  //     ],
  //   },
  // ];

  const header = [
    "#",
    "ID",
    "Description",
    "package Type",
    "quantity",
    "Station",
  ];

  const historyHeader = ["#", "Date", "time", "Status"];

  // let specifecData = data.filter((item) => item.id === 100);

  // useEffect(() => {
  //   if (specifecData.length === 0) {
  //     navigate("/*");
  //   }
  // }, [specifecData]);
  // let itemData = specifecData[0];

  // if (specifecData.length !== 0) {

  // } else {
  //   return false;
  // }
  return (
    <>
      <div id="main-content">
        <BackButton />
        <PageTitle Title="Request Details" />

        {Loading ? (
          <PagesLoading />
        ) : (
          <>
            <div>
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="header">
                      <h2>Single Request</h2>
                    </div>
                    <div className="body">
                      <h3>
                        Request Details :{" "}
                        <strong className="text-primary">{data?.id}</strong>
                      </h3>
                      <ul className="nav nav-tabs-new2">
                        <li className="nav-item inlineblock">
                          <Link
                            className={`nav-link ${!tab && "active"}`}
                            fragment="details"
                            onClick={() => {
                              setTab(false);
                            }}
                          >
                            Details
                          </Link>
                        </li>
                        <li className="nav-item inlineblock">
                      <Link
                        className={`nav-link`}
                        fragment="history"
                        onClick={() => {
                          setTab(true);
                        }}
                      >
                        History
                      </Link>
                    </li>
                      </ul>
                      <div className="tab-content">
                        <div className={`tab-pane ${!tab && "active"}`}>
                          <DetailsRequest
                            createdAt={data?.createdAt}
                            header={header}
                            data={data?.requestsComponents}
                            status={data?.status}
                          />
                        </div>
                        <div className={`tab-pane ${tab && "active"}`}>
                          <History />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RequestDetails;
