import { createSlice } from "@reduxjs/toolkit";
import { setItem, getItem } from "../../utils/storage.js/storage";
let accordionName = getItem("sidebar");
const LayoutSlice = createSlice({
  name: "layout",
  initialState: {
    accordion: accordionName ? accordionName : null,
  },
  reducers: {
    sideBarAccordion: (state, action) => {
      state.accordion = action.payload;
      setItem("sidebar", action.payload);
    },
  },
});

export const layoutAction = LayoutSlice.actions;
export default LayoutSlice;
