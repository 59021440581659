import React, { useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BackButton from "../../../components/Buttons/backButton";
import { useSelector, useDispatch } from "react-redux";
import { getProductById } from "../../../redux/Products/ProductsActions";
import TablePagination from "../../../components/Pagination";
import PagesLoading from "../../../components/Loading/pagesLoading";
import { useTranslation } from "react-i18next";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import PopUp from "../../../components/PopUp";
import ProductEdit from "../ProuductsEdits";

const ProductDetails = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.data?.accessToken);
  const responseData = useSelector((state) => state.products.responseData);
  const loading = useSelector((state) => state.products.singleLoading);
  const [currentItems, setCurrentItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);

  const [dataInfo, setDataInfo] = useState([]);
  const [filters, setFilters] = useState(null);

  const [code, setCode] = useState("");
  const [componentName, setComponentName] = useState("");

  const [productData, setProductData] = useState({});
  const [isEditVisible, setIsEditVisible] = useState(false);

  const [state, setState] = useState({
    disabled: true,
    firstData: false,
  });

  const [t] = useTranslation();

  let productID = useParams().id;

  useEffect(() => {
    if (responseData) {
      setState({ ...state, firstData: true });
    }
  }, [responseData]);
  useEffect(() => {
    if (state.firstData) {
      setProductData([responseData?.product]);
    }
  }, [state.firstData]);

  useEffect(() => {
    if (code !== "" || componentName !== "") {
      setState({ ...state, disabled: false });
    } else {
      setState({ ...state, disabled: true });
    }
  }, [code, componentName]);

  const elementAction = (rowData) => {
    return (
      <div className="project-actions">
        <div
          className="btn btn-outline-secondary"
          onClick={() => visibleEditHandel(rowData.id)}
        >
          <i className="icon-pencil"></i>
        </div>
      </div>
    );
  };

  const visibleEditHandel = (componentId) => {
    setIsEditVisible(true);
  };
  const closeEdithandle = () => {
    setIsEditVisible(false);
  };
  const data = responseData?.product?.productsComponents;

  useEffect(() => {
    dispatch(
      getProductById({
        id: productID,
        token: token,
        page: currentPage,
        limit: itemsPerPage,
        code,
        componentName,
      })
    );
  }, [productID, currentPage, itemsPerPage, code, componentName]);

  const initFilters = () => {
    setFilters({
      code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      components: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      qty: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
  };
  useEffect(() => {
    if (currentItems) {
      setDataInfo(currentItems);
    }

    initFilters();
  }, [currentItems]);
  const codeBody = (rowData) => {
    return (
      <div className="project-title">
        <h6>{rowData.component.code}</h6>
      </div>
    );
  };
  const componentBody = (rowData) => {
    return (
      <div className="product">
        <h6>{rowData.component.name}</h6>
      </div>
    );
  };

  const codeFilterTemplate = () => {
    return (
      <>
        <div>
          <input
            className="form-control"
            placeholder="Code"
            onChange={(e) => setCode(e.target.value)}
            type="text"
          />
        </div>
      </>
    );
  };

  const productFilterTemplate = () => {
    return (
      <>
        <div>
          <input
            className="form-control"
            placeholder="Component Name"
            type="text"
            onChange={(e) => setComponentName(e.target.value)}
          />
        </div>
      </>
    );
  };
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Clear"
          outlined
          style={{
            padding: "4px 8px",
            color: "black",
            textAlign: "end",
            opacity: state.disabled ? "0.2" : "0.6",
          }}
          disabled={state.disabled}
          onClick={() => {
            dispatch(
              getProductById({
                id: productID,
                token: token,
                page: currentPage,
                limit: itemsPerPage,
              })
            );
            setCode("");
            setComponentName("");
            setState({ ...state, disabled: true });
          }}
        />
      </div>
    );
  };

  const headers = renderHeader();

  let filteredData = (dataT) => {
    setCurrentItems(dataT);
  };

  return (
    <div id="main-content">
      <PopUp
        text={
          <ProductEdit
            closePopup={closeEdithandle}
            id={productData.id}
            exist={true}
            isVisible={isEditVisible}
          />
        }
        isVisible={isEditVisible}
      />
      <BackButton />
      <div className="flex-header">
        <PageTitle Title={productData[0]?.name} />
      </div>

      <div className="table-list mb-3">
        <div>
          <DataTable
            value={productData}
            loading={!state.firstData ? loading : false}
            loadingIcon={!state.firstData ? <PagesLoading /> : null}
            dataKey="id"
            emptyMessage={t("noDataFound")}
          >
            <Column
              header={t("code")}
              field="id"
              style={{ minWidth: "9rem" }}
            />
            <Column
              header={t("description")}
              field="name"
              style={{ minWidth: "9rem" }}
            />
            <Column
              header={t("production-rate")}
              field="productionRate"
              style={{ minWidth: "9rem" }}
            />
            <Column
              header={t("actions")}
              style={{ minWidth: "12rem" }}
              body={elementAction}
            />
          </DataTable>
        </div>
      </div>

      <div className="table-list">
        <div className="table-responsive">
          <DataTable
            value={dataInfo}
            loading={loading}
            loadingIcon={<PagesLoading />}
            dataKey="componentId"
            filters={filters}
            header={headers}
            emptyMessage={t("noDataFound")}
          >
            <Column
              header={t("code")}
              field="code"
              filter
              filterElement={codeFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
              body={codeBody}
            />
            <Column
              header={t("components")}
              field="components"
              filter
              filterElement={productFilterTemplate}
              style={{ minWidth: "9rem" }}
              showAddButton={false}
              showApplyButton={false}
              showClearButton={false}
              showFilterMenuOptions={false}
              body={componentBody}
            />
            <Column
              header={t("qty-per-product")}
              field="componentQuantity"
              style={{ minWidth: "9rem" }}
            />
          </DataTable>
        </div>
      </div>

      <TablePagination
        data={data}
        filterData={filteredData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        responseData={responseData}
      />
    </div>
  );
};

export default ProductDetails;
