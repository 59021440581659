import React, { Fragment, useEffect } from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import axiosInstance from "../services/axiosInstance";
import { useDispatch } from "react-redux";
import { authAction } from "../redux/Auth/AuthSlice";
import { getItem } from "../utils/storage.js/storage";

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let user = getItem("isAuth");

  console.log("user", user);
  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          dispatch(authAction.logOut());
          navigate("/login");
          console.log("401 Unauthorized error occurred");
        } else if (error.response.status === 403) {
          navigate("/403");
          console.log("error status 403 ");
        } else if (error.response.status === 500) {
          navigate("/500");
          console.log("error status 500 ");
        } else if (error.response.status === 503) {
          navigate("/503");
          console.log("error status 503 ");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [dispatch, navigate]);

  return (
    <Fragment>{user === true ? <Outlet /> : <Navigate to="/login" />}</Fragment>
  );
};

export default PrivateRoutes;
